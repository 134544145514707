var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog hoy",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("videomost.conf-edit-conf")
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "conference-info" }, [
          _c(
            "div",
            { staticClass: "subject conf-item" },
            [
              _c("custom-input-text", {
                key: "0",
                attrs: {
                  default_text: _vm.conf.topic,
                  input_text: _vm.conf.topic,
                  label: _vm.$t("videomost.conf-subject"),
                  warning: _vm.subjectMissed
                },
                on: {
                  "update:input_text": function($event) {
                    return _vm.$set(_vm.conf, "topic", $event)
                  }
                },
                nativeOn: {
                  click: function($event) {
                    _vm.subjectMissed = false
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "schedule conf-item" },
            [
              _c("date-range-picker", {
                attrs: {
                  default_from: _vm.getStartTime(_vm.conf),
                  from: _vm.conf.startUTime,
                  default_to: _vm.getFinishTime(_vm.conf),
                  to: _vm.conf.finishUTime,
                  required_from: _vm.fromMissed,
                  required_to: _vm.toMissed,
                  minute_step: 5,
                  clearable: false
                },
                on: {
                  "update:from": function($event) {
                    return _vm.$set(_vm.conf, "startUTime", $event)
                  },
                  "update:to": function($event) {
                    return _vm.$set(_vm.conf, "finishUTime", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "conf-auth conf-item" },
            [
              _c("custom-input-text", {
                key: "1",
                staticStyle: { "margin-right": "4px" },
                attrs: {
                  default_text: _vm.conf.confid,
                  input_text: _vm.conf.confid,
                  label: _vm.$t("videomost.conf-id")
                },
                on: {
                  "update:input_text": function($event) {
                    return _vm.$set(_vm.conf, "confid", $event)
                  }
                }
              }),
              _c("custom-input-text", {
                key: "2",
                staticStyle: { "margin-left": "22px" },
                attrs: {
                  default_text: _vm.conf.password,
                  input_text: _vm.conf.password,
                  label: _vm.$t("videomost.conf-password"),
                  warning: _vm.confPasswordMissed
                },
                on: {
                  "update:input_text": function($event) {
                    return _vm.$set(_vm.conf, "password", $event)
                  }
                },
                nativeOn: {
                  click: function($event) {
                    _vm.confPasswordMissed = false
                  }
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "conf-settings conf-item" }, [
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.openExtendedSettings(_vm.conf)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.confSettingsText))]
            )
          ])
        ])
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.cancel") },
            on: { click: _vm.modalClose }
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.save") },
            on: {
              click: function($event) {
                return _vm.confirmConfChange(_vm.conf)
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }