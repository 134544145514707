var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("videomost.new-conference")
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c("div", { staticClass: "vmconference" }, [
            _c(
              "div",
              { staticClass: "subject conf-item" },
              [
                _c("custom-input-text", {
                  ref: "vmConfSubject",
                  attrs: {
                    input_text: _vm.subjectName,
                    label: _vm.$t("videomost.conf-subject"),
                    warning: _vm.subjectMissed
                  },
                  on: {
                    "update:input_text": function($event) {
                      _vm.subjectName = $event
                    }
                  },
                  nativeOn: {
                    click: function($event) {
                      _vm.subjectMissed = false
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "schedule conf-item" },
              [
                _c("date-range-picker", {
                  key: "1",
                  attrs: {
                    from: _vm.from,
                    default_from: _vm.startTimeLocal,
                    required_from: _vm.fromMissed,
                    to: _vm.to,
                    default_to: _vm.finishTimeLocal,
                    required_to: _vm.toMissed,
                    minute_step: 5,
                    clearable: false
                  },
                  on: {
                    "update:from": function($event) {
                      _vm.from = $event
                    },
                    "update:to": function($event) {
                      _vm.to = $event
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "conf-auth conf-item" },
              [
                _c("custom-input-text", {
                  key: "1",
                  staticStyle: { "margin-right": "13px" },
                  attrs: {
                    input_text: _vm.confID,
                    default_text: _vm.randomString(),
                    label: _vm.$t("videomost.conf-id"),
                    warning: _vm.confIdMissed
                  },
                  on: {
                    "update:input_text": function($event) {
                      _vm.confID = $event
                    }
                  },
                  nativeOn: {
                    click: function($event) {
                      _vm.confIdMissed = false
                    }
                  }
                }),
                _c("custom-input-text", {
                  key: "2",
                  staticStyle: { "margin-left": "13px" },
                  attrs: {
                    input_text: _vm.confPassword,
                    default_text: _vm.randomString(4),
                    label: _vm.$t("videomost.conf-password"),
                    warning: _vm.confPasswordMissed
                  },
                  on: {
                    "update:input_text": function($event) {
                      _vm.confPassword = $event
                    }
                  },
                  nativeOn: {
                    click: function($event) {
                      _vm.confPasswordMissed = false
                    }
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "conf-settings conf-item" }, [
              _c("a", { on: { click: _vm.openConfSettings } }, [
                _vm._v(_vm._s(_vm.confSettingsText))
              ])
            ])
          ])
        ])
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.cancel") },
            on: {
              click: function($event) {
                return _vm.modalClose()
              }
            }
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.save") },
            on: { click: _vm.createNewConf }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }