//
//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue';
import ProgressCircle from '../ProgressCircle.vue';
import ContentLoader from "../main/body/MainContentLoader.vue"
import PublicationMediaViewer from '../mediaViewer/PublicationMediaViewer.vue';
import PublicationFilesMixin from "./PublicationFilesMixin"

export default {
    name: "PublicationImageItem",
    components: { 
        "progress-circle": ProgressCircle, 
        IconImage,
        ContentLoader,
    },
    mixins: [PublicationFilesMixin],
    props: {
        data: {
            type: Object,
            required: false
        },
        images: {
            type: Number,
            required: false
        },
        publication: {
            type: Object,
            required: false
        },
        index: {
            type: Number,
            required: false
        },
        file: {
            type: Object,
            required: false
        },
    },
    asyncComputed: {
        src() {
            if (this.link) return this.link
            else if (this.index > -1) return this.getPreview(this.file)
            else return this.getPreview(this.data)
        },
    },
    methods: {
        doShowViewer(index) {
            if (!this.publication) return;
            if (typeof index !== "number") return

            this.$modal.show(PublicationMediaViewer, {
                'chId': this.publication.chId,
                'pubId': this.publication.pubId,
                'index': index,
            }, {
                name: 'viewer',
                delay: 200,
                width: '100%',
                height: '100%',
                classes: ['v--modal', 'v--modal-box', 'no-gap-on-top']
            });
        },
        openViewer(index = this.data.index) {
            this.doShowViewer(index)
        },
    },
}
