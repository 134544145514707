import proto from '../../protocol'

// import {CHANNELS, CHATS, CONTENT_MANAGER, USERDATA} from '../modulesNames'
// import { MAIN_TYPES } from './content-manager'

import {
    ACT_SCHEDULE_CREDENTIALS_CHECK,
    ACT_SCHEDULE_DELETE_CREDENTIALS,
    ACT_SCHEDULE_UPDATE_CREDENTIALS,
    ACT_SCHEDULE_SYNC_APPOINTMENTS,
    ACT_SCHEDULE_ADD_APPOINTMENT,
    ACT_SCHEDULE_EDIT_APPOINTMENT,
    ACT_SCHEDULE_DELETE_APPOINTMENT,
    ACT_SCHEDULE_GET_APPOINTMENT,
    ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS,
    ACT_SCHEDULE_RESPONSE_TO_INVITATION,
} from '../actionsTypes'
// import { GET_USER_PARAMS} from '../gettersTypes'

const state = {
}

const getters = {
}


const actions = {
    async [ACT_SCHEDULE_CREDENTIALS_CHECK](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleCredentialsCheck(payload)
        } catch(error) {
            return false
        }
        let { error } = reply
        return error ? false : true
    },
    async [ACT_SCHEDULE_DELETE_CREDENTIALS](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleDeleteCredentials(payload)
        } catch(error) {
            return { error: true }
        }
        let { error } = reply
        return error ? false : true
    },
    async [ACT_SCHEDULE_UPDATE_CREDENTIALS](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleUpdateCredentials(payload)
        } catch(error) {
            return { error: true }
        }
        let { error } = reply
        return error ? false : true
    },

    async [ACT_SCHEDULE_SYNC_APPOINTMENTS](obj, payload = { type: "exchange"}) {
        let reply
        try {
            reply = await proto.scheduleSyncAppointments(payload)
        } catch(error) {
            return { error }
        }
        return reply
    },

    async [ACT_SCHEDULE_ADD_APPOINTMENT](obj, payload) {
        // console.log("🚀 ~ file: integrations.js: ACT_SCHEDULE_ADD_APPOINTMENT ~ payload:", payload)
        let reply
        try {
            reply = await proto.scheduleAddAppointment(payload)
        } catch(error) {
            // console.log("🚀 ~ file: integrations.js:65 ~ error:", error)
            return { error }
        }
        return reply
    },

    async [ACT_SCHEDULE_EDIT_APPOINTMENT](obj, payload) {
        // console.log("🚀 ~ file: integrations.js: ACT_SCHEDULE_EDIT_APPOINTMENT ~ payload:", payload)
        let reply
        try {
            reply = await proto.scheduleEditAppointment(payload)
        } catch(error) {
            // console.log("🚀 ~ file: integrations.js:76 ~ error:", error)
            return { error }
        }
        return reply
    },
    
    async [ACT_SCHEDULE_DELETE_APPOINTMENT](obj, payload) {
        // console.log("🚀 ~ file: integrations.js: ACT_SCHEDULE_DELETE_APPOINTMENT ~ payload:", payload)
        let reply
        try {
            reply = await proto.scheduleDeleteAppointment(payload)
        } catch(error) {
            // console.log("🚀 ~ file: integrations.js:87 ~ error:", error)
            return { error }
        }
        return reply
    },    
    async [ACT_SCHEDULE_GET_APPOINTMENT](obj, payload) {
        // console.log("🚀 ~ file: integrations.js: ACT_SCHEDULE_GET_APPOINTMENT ~ payload:", payload)
        let reply
        try {
            reply = await proto.scheduleGetAppointment(payload)
        } catch(error) {
            // console.log("🚀 ~ file: integrations.js:102 ~ error:", error)
            return { error }
        }
        return reply
    },    
    async [ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS](obj, payload) {
        // // console.log("🚀 ~ file: integrations.js: ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS ~ payload:", payload)
        let reply
        try {
            reply = await proto.scheduleGetCalendarAppointments(payload)
            // // console.log("🚀 ~ file: integrations.js:106 ~ reply:", reply)
        } catch(error) {
            // console.log("🚀 ~ file: integrations.js:113 ~ error:", error)
            return { error }
        }
        return reply
    },
    
    async [ACT_SCHEDULE_RESPONSE_TO_INVITATION](obj, payload) {
        // console.log("🚀 ~ file: integrations.js:128 ~ payload:", payload)
        let reply
        try {
            reply = await proto.scheduleResponseToInvitation(payload)
        } catch(error) {
            // console.log("🚀 ~ file: integrations.js:124 ~ error:", error)
            return { error }
        }
        return reply
    },    
}

const mutations = {
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};