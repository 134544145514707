//
//
//
//
//
//
//
//
//
//
//
//

import Toolbar from './Toolbar.vue';
import Dialer from '../phone/Dialer.vue';
import SideContent from "./SideContent.vue";
import ChangeFavourites from '../favourites/ChangeFavourites.vue';
import InfoNotification from '../infoNotification/InfoNotification.vue';

import {CONTENT_MANAGER, PHONE_CNTL, LOGIN, ASISTANTS, CONTACTS } from '../../store/modulesNames';
import {
    GET_INFO_NOTIFICATION,
    GET_OPEN_DIALER,
    GET_IS_LOGINED,
    GET_ASSISTANTS,
    GET_CONTACTS_MERGED
} from '../../store/gettersTypes';
import { ACT_SELECT_ASSISTANT } from '../../store/actionsTypes';

export default {
    name: "Side",
    components: {
        SideContent,
        'toolbar': Toolbar,
        ChangeFavourites, //@todo не используется
        InfoNotification,
        Dialer
    },
    data() {
        return {
            selectedAssistant: false,
        }
    },
    watch: {
        isLogined () {   
            this.callSelectAssistant()                
        },
        assistants () {
            this.callSelectAssistant()
        },
        contacts () {
            this.callSelectAssistant()                
        },
        // showDialer(val) {
        //     if (val) this.calcDialerPosition()
        // }
    },
    computed: {
        infoNotificationShow() {
            return this.$store.getters[`${CONTENT_MANAGER}/${GET_INFO_NOTIFICATION}`]                
        },
        showDialer() {
            return this.$store.getters[`${PHONE_CNTL}/${GET_OPEN_DIALER}`]
        },
        isLogined () {
            return this.$store.getters[`${LOGIN}/${GET_IS_LOGINED}`]
        },
        assistants () {
            return this.$store.getters[`${ASISTANTS}/${GET_ASSISTANTS}`]
        },
        contacts () {
            return this.$store.getters[`${CONTACTS}/${GET_CONTACTS_MERGED}`]
        },
    },
    methods: {
        callSelectAssistant () {
            if (this.isLogined && this.assistants.length > 1 && this.contacts.length && !this.selectedAssistant) this.selectAssistant()
        },
        selectAssistant () {
            let savedAssistant = localStorage.getItem('assistant') || ''
            let savedAssistantInAssistantsList = this.assistants.some(item => item.type === savedAssistant)

            if (savedAssistantInAssistantsList)  this.$store.dispatch(`${ASISTANTS}/${ACT_SELECT_ASSISTANT}`, savedAssistant)
            else {
                if (this.assistants.length > 2) this.modalOpen({ name: 'select-assistant' })
                else if(this.assistants.length === 2) this.$store.dispatch(`${ASISTANTS}/${ACT_SELECT_ASSISTANT}`, this.assistants[1].type)
            }
            this.selectedAssistant = true
        },
    }
}
