var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("msg-sending")
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "load-files" }, [
          _c(
            "div",
            { staticClass: "body" },
            [
              _c(
                "div",
                {
                  staticClass: "preview",
                  on: {
                    dragover: _vm.dragover,
                    dragenter: _vm.dragenter,
                    dragleave: _vm.dragleave,
                    drop: _vm.drop,
                    onresize: _vm.doLoad
                  }
                },
                [
                  _vm.dropFile
                    ? _c("div", { staticClass: "add-files" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("add")))])
                      ])
                    : _vm._e(),
                  !_vm.files.length
                    ? _c(
                        "div",
                        {
                          staticClass: "preview-empty",
                          class: { "preview-empty-danger": _vm.filesEmpty }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("empty-list")))])]
                      )
                    : _vm.onlyImages
                    ? _c(
                        "div",
                        {
                          ref: "previewImages",
                          staticClass: "preview-images",
                          class: { "preview-images-scroll": _vm.imagesScroll }
                        },
                        _vm._l(_vm.previewFiles, function(item, index) {
                          return _c("div", { staticClass: "preview-image" }, [
                            _c("img", {
                              attrs: { src: item.src },
                              on: { load: _vm.doLoad }
                            }),
                            _c("div", { staticClass: "preview-image-hover" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "delete-btn-img",
                                  attrs: { title: _vm.$t("chat.delete") },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeItem(index)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fal fa-times-circle"
                                  })
                                ]
                              )
                            ])
                          ])
                        }),
                        0
                      )
                    : _c(
                        "div",
                        { staticClass: "preview-files" },
                        _vm._l(_vm.previewFiles, function(item, index) {
                          return _c("div", { staticClass: "preview-file" }, [
                            item.type === "image"
                              ? _c("div", { staticClass: "icon-image" }, [
                                  _c("img", { attrs: { src: item.img.src } })
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "preview-file-icon" },
                                  [
                                    item.type === "audio"
                                      ? _c("i", { staticClass: "fas fa-music" })
                                      : item.type === "video"
                                      ? _c("i", { staticClass: "fas fa-video" })
                                      : item.type !== "image"
                                      ? _c("i", { staticClass: "far fa-file" })
                                      : _vm._e()
                                  ]
                                ),
                            _c("div", { staticClass: "preview-file-info" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "preview-file-name",
                                  attrs: { title: item.name }
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c("div", { staticClass: "preview-file-size" }, [
                                _vm._v(_vm._s(item.size))
                              ])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "delete-btn",
                                attrs: { title: _vm.$t("chat.delete") },
                                on: {
                                  click: function($event) {
                                    return _vm.removeItem(index)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fal fa-times-circle" })]
                            )
                          ])
                        }),
                        0
                      )
                ]
              ),
              _c("custom-textarea", {
                key: "inputElemModal",
                ref: "inputElem",
                staticClass: "custom-textarea input-title",
                class: { focus: _vm.focus },
                attrs: {
                  custom_placeholder: _vm.$t("add-caption"),
                  text: _vm.text,
                  focus: _vm.focus
                },
                on: {
                  "update:text": function($event) {
                    _vm.text = $event
                  },
                  "update:focus": function($event) {
                    _vm.focus = $event
                  }
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "btn", on: { click: _vm.close } }, [
              _vm._v(_vm._s(_vm.$t("cancel")))
            ]),
            _c("div", { staticClass: "btn", on: { click: _vm.send } }, [
              _vm._v(_vm._s(_vm.$t("channel-comp.send")))
            ])
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }