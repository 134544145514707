import { render, staticRenderFns } from "./MainHeaderChannel.vue?vue&type=template&id=23b7f8bb&scoped=true&lang=pug&"
import script from "./MainHeaderChannel.vue?vue&type=script&lang=js&"
export * from "./MainHeaderChannel.vue?vue&type=script&lang=js&"
import style0 from "./MainHeaderChannel.vue?vue&type=style&index=0&id=23b7f8bb&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b7f8bb",
  null
  
)

export default component.exports