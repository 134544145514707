//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import { mapActions } from 'vuex'
import CustomTextarea from './custom/CustomTextarea.vue';
import { i18n } from '../../ext/i18n'
import modal_mixin from './modal/modal-mixin'
import {CHATS, USERDATA} from '../store/modulesNames'
import { GET_SEND_BY_ENTER } from '../store/gettersTypes'
import {ACT_SEND_FILE_MESSAGE} from '../store/actionsTypes'

export default {
    name: 'loader-files',
    props: [
        'cid',
        'cidType',
        'files',
        'input'
    ],
    components: {
        CustomTextarea
    },
    mixins: [modal_mixin],
    data() {
        return {
            imagesScroll: false,
            text: '',
            focus: true,
            dropFile: false,
            dropFileTimer: null,
            filesEmpty: false,
            compModalProps: {
              name: 'loader-files',
              delay: 200,
              pivotY: 0.45,
              width: 400,
              height: 'auto'
            }
        }
    },
    created() {
        window.addEventListener('resize', this.doLoad);
        window.addEventListener('keydown', this.processKeyDown)
    },
    destroyed() {
        window.removeEventListener('resize', this.doLoad)
        window.removeEventListener('keydown', this.processKeyDown)
    },
    watch: {
        files() {
            this.doLoad();
            if(this.filesEmpty && this.pureFilesList.length) {
                this.filesEmpty = false
            }
        }
    },
    computed: {
        pureFilesList() {
            return this.files.filter((file) => Boolean(file))
        },
        isSendByEnter() {
            return this.$store.getters[`${USERDATA}/${GET_SEND_BY_ENTER}`]
        },
        getText() {
            return this.text.trim()
        },
    },
    asyncComputed: {
        onlyImages() {
            for(let i = 0; i < this.pureFilesList.length; i++) {
                if(!(this.pureFilesList[i].type.includes('image'))) {
                    return false;
                    break;
                }
            }
            return true;
        },
        async previewFiles() {
            let result = [];

            if(this.onlyImages) {
                for(let i = 0; i < this.pureFilesList.length; i++) {
                    let img = await getImage(this.pureFilesList[i]);
                    result.push(img);
                }
            } else {
                for(let i = 0; i < this.pureFilesList.length; i++) {
                    let obj = {};
                    let type = this.pureFilesList[i].type.split('/');
                    let size = formatSize(this.pureFilesList[i].size);

                    if(type[0] === 'image') obj.img = await getImage(this.pureFilesList[i]);

                    obj.name = this.pureFilesList[i].name;
                    obj.type = type[0];
                    obj.size = size;
                    result.push(obj);
                }
            }

            return result;

            function formatSize(byte){
                let locale = i18n.messages[i18n.locale]
                let i = 0, type = [locale.b, locale.kb, locale.mb, locale.gb, locale.tb, locale.pb];
                while((byte / 1000 | 0) && i < type.length - 1) {
                    byte /= 1024;
                    i++;
                }
                return byte.toFixed(2) + ' ' + type[i];
            }

            function getImage(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = function() {
                        let img = document.createElement('img');
                        img.src = reader.result;
                        resolve(img);
                    }
                });

            }
        }
    },
    methods: {
        close() {
            this.$modal.hide('loader-files');
        },
        processKeyDown(e) {
            const isMac = window.navigator.userAgent.indexOf("Mac") !== -1
            const key = e.key 
            let isCtrl = e.ctrlKey
            if (isMac) isCtrl = isCtrl || e.metaKey
            switch (key) {
                case 'Escape':
                    this.close()
                    break
                case 'Enter':
                    if (this.isSendByEnter || (!this.isSendByEnter && isCtrl)) this.send()
                    break                        
                default:
                    break
            }
        },
        send() {
            if(!this.pureFilesList.length) {
                this.filesEmpty = true;
                return;
            }
            const chat = this.$store.getters['chats/selected']
            let cid = chat && chat.cid
            let cidType = chat && chat.cidType
            this.pureFilesList.forEach((file, i) => {
                if ((this.pureFilesList.length - 1) === i) this[ACT_SEND_FILE_MESSAGE]({cid, cidType, file, text: this.getText})
                else this[ACT_SEND_FILE_MESSAGE]({cid, cidType, file})
            });
            this.close();
        },
        removeItem(index) {
            this.pureFilesList.splice(index, 1);
        },
        doLoad() {
            if(this.$refs.previewImages.clientHeight !== this.$refs.previewImages.scrollHeight) this.imagesScroll = true;
            else this.imagesScroll = false;
            this.focusInput()
        },
        focusInput() {
            const inputDiv = this.$refs.inputElem
            const cta = inputDiv && inputDiv.$refs.custom_input_textarea
            if (cta) cta.focus()
        },
        dragenter(e) {
            e.preventDefault()
            e.stopPropagation()
            if (e.dataTransfer.types) {
                for (let i = 0; i < e.dataTransfer.types.length; i++) {
                    if (e.dataTransfer.types[i] == "Files") {
                        this.dropFile = true
                    }
                }
            }
        },
        dragover(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        dragleave(e) {
            e.preventDefault()
            this.dropFile = false
        },
        drop(e) {
            this.dropFile = false
            e.preventDefault();
            e.stopPropagation();
            for(let i = 0; i < e.dataTransfer.files.length; i++) {
                this.pureFilesList.push(e.dataTransfer.files[i]);
            }
        },
        ...mapActions(CHATS, [ACT_SEND_FILE_MESSAGE])
    }
}
