//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
import {mapActions, mapGetters} from 'vuex'
import {CONTENT_MANAGER, CONTACTS, VM_CONF} from "../../store/modulesNames"
import { MUT_SET_SIDE_BAR_NOTIFICATION } from '../../store/mutationsTypes'
import {MAIN_TYPES, SIDE_TYPES} from "../../store/modules/content-manager"

import IconImage from '../IconImage.vue';
import CallDetails from '../calls/CallDetails.vue';
import CreateConf from '../modal/CreateConf.vue';
// import VideoMostConfMenu from '../videomost/mainContentVideoConfs.vue'
import EnterConf from "../videomost/enterConference.vue"

import SideNotificationBar from './SideNotificationBar.vue';

import modal_mixin from '../modal/modal-mixin'

import {
    // GET_ROOM_ID,
    GET_OPEN_DIALER,
    GET_ASSISTANTS,
    GET_ACTIVE_MICROPHONE,
    GET_ACTIVE_WEBCAMERA,
    GET_VM_CONF_CONNECTION_PARAMS,
    GET_MERGED_CONTACT_BY_ID,
    GET_SIDE_BAR_NOTIFICATION,
    GET_MY_CONTACT,
    GET_USER_PARAMS,
} from "../../store/gettersTypes";

import {ACT_REPLACE_MAIN_TYPE, CALLS_CONTROLLER} from '../../store/actionsTypes'
// import { i18n } from '../../../ext/i18n'
import {PHONE_CNTL, ASISTANTS, USERDATA} from "../../store/modulesNames"
import ContactProfileEdition from "../ContactProfoleEdition.vue"
import SelectThenEditContact from '../modal/SelectThenEditContact.vue'
import ContinueWithoutCamera from "../modal/ContinueWithoutCamera.vue"

export default {
    name: 'calls-list',
    prop: [],
    data() {
        return {
            search: ''
        };
    },
    components: {
        'icon-image': IconImage,
        CallDetails,
        SideNotificationBar,
    },
    mixins: [modal_mixin],
    computed: {
        calls() {
            const inout = (call) => {
                if (call.type === 'out') return this.$t('out-call')
                else if(call.type === 'in' && call.duration) return this.$t('in-call')
                else if(call.type === 'in' && !call.duration) return this.$t('missed')
            }

            const time = (value) => {
                if(value < 60 && value !== 0) return value + this.$t('sec')
                else if(value < 60 && value === 0) return this.$t('1sec')
                else if(value >= 60) {
                    let min = Math.floor(value/60)
                    let sec = value%60
                    if(sec !== 0 ) return min + this.$t('min') + sec + this.$t('sec')
                    if(sec === 0 ) return min + this.$t('min')
                }
            }

            let arr = app.store.getters['calls/getCalls'] || [];

            function sortArr(a, b) {
                if (a.time > b.time) return 1;
                if (a.time < b.time) return -1;
            }
            arr.sort(sortArr);

            // grouping
            let arr1 = [];
            for(let i = 0; i < arr.length; i++) {
                let obj = arr[i];
                obj.inout = inout(obj);
                if(obj.duration) obj.timeFormat = time(obj.duration);

                if((!('duration' in obj) || obj.duration === 0) && obj.type === 'in') obj.callType = 'unaccepted';
                else obj.callType = 'accepted';
                let n = arr1.length - 1;
                if(arr1.length === 0) {
                    obj.count = 1;
                    obj.calls = [];
                    obj.calls.push(obj);
                    if(obj.status !== 'watched' && obj.type === 'in') {
                        if(!('unwatchedcids' in obj)) obj.unwatchedcids = [];
                        obj.unwatchedcids.push(obj.id);
                    }
                    arr1.push(obj);
                } else if(arr1[n].cid === obj.cid && arr1[n].class === obj.class) {
                    // (arr1[n].number && obj.number && arr1[n].number === obj.number)
                    if(arr1[n].callType === obj.callType && arr1[n].number === obj.number) {
                        arr1[n].count ++;
                        arr1[n].calls.push(obj);
                        if(obj.status !== 'watched' && obj.type === 'in') {
                            if(!('unwatchedcids' in arr1[n])) arr1[n].unwatchedcids = [];
                            arr1[n].unwatchedcids.push(obj.id);
                        }
                    } else {
                        obj.count = 1;
                        obj.calls = [];
                        obj.calls.push(obj);
                        if(obj.status !== 'watched' && obj.type === 'in') {
                            if(!('unwatchedcids' in obj)) obj.unwatchedcids = [];
                            obj.unwatchedcids.push(obj.id);
                        }
                        arr1.push(obj);
                    }
                } else {
                    obj.count = 1;
                    obj.calls = [];
                    obj.calls.push(obj);
                    if(obj.status !== 'watched' && obj.type === 'in') {
                        if(!('unwatchedcids' in obj)) obj.unwatchedcids = [];
                        obj.unwatchedcids.push(obj.id);
                    }
                    arr1.push(obj);
                }
            }
            
            arr1 = arr1.map(item => {
                if (item.cid)
                    item.photo = this[GET_MERGED_CONTACT_BY_ID](item.cid).photo;
                return item    
            })

            return arr1;
        },
        fillColor() {
            return (!this.isDisabledDirectCall && this.isCallAllowed) ? '--fill-color: var(--toolbtn-color)' : '--fill-color: var(--icon-disabled-color)'
        },
        isElectron() {
            return this.$store.getters['clientdata/getIsElectron']
        },
        showDialerBtn () {
            return !!this.$store.getters[`${ASISTANTS}/${GET_ASSISTANTS}`].length
        },
        isMicrophonePresent() {
            return !!this.$store.getters[`${USERDATA}/${GET_ACTIVE_MICROPHONE}`]
        },
        isWebcameraPresent() {
            return !!this.$store.getters[`${USERDATA}/${GET_ACTIVE_WEBCAMERA}`]
        },
        conferenceBtnShow() {
            return Boolean(this[GET_VM_CONF_CONNECTION_PARAMS].server)
        },
        incommingCallMissedClass() {
            const isDarkTheme = this.$store.getters['clientdata/getTheme'] === 'dark'
            return isDarkTheme ? 'phone-img-missed-dark' : 'phone-img'
        },
        side_notification() {
            return this[GET_SIDE_BAR_NOTIFICATION]() || false
        },
        myContactActions() {
            return this[GET_MY_CONTACT].actions || []
        },
        isDisabledDirectCall() {
            const userParams = this[GET_USER_PARAMS]
            let isDisabled = userParams.hasOwnProperty("disableDirectCall") && userParams.disableDirectCall
            if (localStorage.getItem('assistant') === 'none') isDisabled = true
            if (isDisabled) {
                const currentNotification = this.side_notification || ''
                const warningMsg = this.$t('calls-prohibited-long')
                const isExist = currentNotification.indexOf(warningMsg) > -1
                if (!isExist) {
                    const payload = { type: SIDE_TYPES.CALLS_LIST, msg: warningMsg }
                    this.$store.commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload)
                }
            }
            return isDisabled
        },            
        isCallAllowed() {
            const actions = this.myContactActions
            const isProperty = actions.hasOwnProperty("make-call")
            if (isProperty) return actions["make-call"]
            else return true
        },
        ...mapGetters(VM_CONF, [GET_VM_CONF_CONNECTION_PARAMS]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID, GET_MY_CONTACT]),
        ...mapGetters(CONTENT_MANAGER, [GET_SIDE_BAR_NOTIFICATION]),
        ...mapGetters(USERDATA, [GET_USER_PARAMS]),
    },

    methods: {
        ...mapActions(CONTENT_MANAGER, [ACT_REPLACE_MAIN_TYPE]),
        fio(cid, fio) {
            let user = this[GET_MERGED_CONTACT_BY_ID](cid);
            if(!user.cid) return fio;
            else return user.fio;
        },
        photo(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).photo;
        },
        watchCalls: function(e, cids, user) {
            if(user.type === 'out') user.group = 'out_in_answered';
            else if(user.type === 'in' && user.duration)  user.group = 'out_in_answered';
            else if(user.type === 'in' && !user.duration)  user.group = 'in_unanswered';
            this.showInfoMsg(user);
            if (cids) {
                cids.forEach(function (cid) {
                    app.store.dispatch('calls/updateStatus', {id: cid, status: 'watched'});
                });
            }

        },
        showInfoMsg (user) {
            this.modalOpen({
                component: CallDetails,
                props: { user: user, fio: this.fio(user.cid), calls: user.calls }
            })
        },
        doOpenDialer(e) {
            let openDialer = this.$store.getters[`${PHONE_CNTL}/${GET_OPEN_DIALER}`]
            if (!openDialer) window.addEventListener('click', checkClick.bind(this))
            this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'dialer'})                

            function checkClick (e) {
                let dialer = document.getElementById('dialer')
                let button = document.getElementById('dialer-link')

                if (button && button.contains(e.target)) window.removeEventListener('click', checkClick)
                else if (dialer && !dialer.contains(e.target)) {
                    this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'dialer'})
                    window.removeEventListener('click', checkClick)
                }
            }
        },
        doContextMenu(event, cid, calls, position="bottom-right") {
            let handlers = []
            handlers.push({
                item_name: this.$t('chat.delete'),
                handler: () => { this.removeCalls(calls) }
            })
            if (!cid) {
                let newFields = []
                let newNumber = calls[0].number
                newFields.push({ type: 'phone', value: newNumber })
                handlers.push({
                    item_name: this.$t('mainPage.create-new-contact'),
                    handler: () => {
                        this.modalOpen({
                            component: ContactProfileEdition,
                            props: { type: 'create', cid, newFields }
                        })
                    }
                })
                handlers.push({
                    item_name: this.$t('mainPage.add-to-contact'),
                    handler: () => {
                        this.modalOpen({
                            component: SelectThenEditContact,
                            props: { newFields }
                        })
                    }
                })
            }
            this.cmOpen(event, handlers)
        },
        removeCalls(calls) {
            calls.forEach(item => {
                this.$store.dispatch('calls/updateStatus', {id: item.id, status: 'deleted'});
            })
        },
        createVideoConf(video) {
            if (this.isMicrophonePresent && this.isWebcameraPresent) {
                this.createConf(video)
            } else if (!this.isMicrophonePresent) {
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title:  this.$t('errors.error'),
                        text: this.$t('no-mic')
                    }
                })
            } else if (!this.isWebcameraPresent) {
                this.modalOpen({
                    component: ContinueWithoutCamera,
                    props: {
                        btnOk: {
                            cb: () => this.createConf(video),
                        },
                    }
                })
            }
        },
        createConf(video) {
            this.modalOpen({
                component: CreateConf,
                props: {
                    video,
                    limit: 5
                }
            })
        },
        onConference() {
            let { login, password } = this[GET_VM_CONF_CONNECTION_PARAMS]
            if (login && password) {
                this.openMainVideoConferences()
            } else {
                this.modalOpen({ component: EnterConf })
            }
        },
        openMainVideoConferences() {
            this[ACT_REPLACE_MAIN_TYPE]({type: MAIN_TYPES.VIDEOCONFS})
        },
        openVideoMostConfMembers() {
            this.modalOpen({ component: VideoMostConfMembers })
        },
        doMenu(event, position) {
            let handlers = []
            let open = this.cmOpen

            handlers.push({ item_name: this.$t('group-call'), handler: () => this.createVideoConf(false) })
            handlers.push({ item_name: this.$t('group-video-call'), handler: () => this.createVideoConf(true) })
            open(event, handlers, position)
        }
    }
}
