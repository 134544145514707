var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts-list", attrs: { "data-simplebar": "" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.changeFavouritesModeP,
              expression: "!changeFavouritesModeP"
            }
          ],
          staticClass: "section-header"
        },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t("contacts")))]),
          _c("div", { staticClass: "header-btns" }, [
            _c(
              "div",
              {
                staticClass: "circle-animation",
                attrs: { title: _vm.$t("mainPage.menu") },
                on: {
                  click: function($event) {
                    return _vm.doMenu($event, "bottom-right")
                  }
                }
              },
              [
                _c("svg", { attrs: { width: "10px", height: "24px" } }, [
                  _c("circle", { attrs: { cx: "5", cy: "5", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "12", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "19", r: "2" } })
                ])
              ]
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.changeFavouritesModeP,
              expression: "changeFavouritesModeP"
            }
          ],
          staticClass: "section-header"
        },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t("contacts")))]),
          _c("div", { staticClass: "header-btns" }, [
            _c(
              "span",
              {
                staticClass: "change-favourites-complete",
                on: { click: _vm.disableChangeFavouritesMode }
              },
              [_vm._v(_vm._s(_vm.$t("done")))]
            )
          ])
        ]
      ),
      _c("div", { staticClass: "side-body" }, [
        _c(
          "div",
          { staticClass: "side-list" },
          [
            _vm.birthdays_show ? _c("birthdays") : _vm._e(),
            _vm.contacts.length
              ? _c(
                  "ul",
                  { staticClass: "list" },
                  [
                    _c(
                      "draggable",
                      _vm._b(
                        {
                          staticClass: "list-group",
                          attrs: {
                            tag: "ul",
                            disabled: !_vm.changeFavouritesModeP
                          },
                          on: {
                            start: function($event) {
                              _vm.drag = true
                            },
                            end: function($event) {
                              _vm.drag = false
                            }
                          },
                          model: {
                            value: _vm.draggable_contacts,
                            callback: function($$v) {
                              _vm.draggable_contacts = $$v
                            },
                            expression: "draggable_contacts"
                          }
                        },
                        "draggable",
                        _vm.dragOptions,
                        false
                      ),
                      [
                        _c(
                          "transition-group",
                          {
                            attrs: {
                              type: "transition",
                              name: !_vm.drag ? "flip-list" : null
                            }
                          },
                          [
                            _vm._l(
                              _vm.draggable_contacts || _vm.contacts,
                              function(contact, i) {
                                return [
                                  _vm.exclude.indexOf(contact.cid) === -1
                                    ? _c(
                                        "li",
                                        {
                                          key: i + 1,
                                          on: {
                                            click: function($event) {
                                              return _vm.doOnSelected(
                                                contact.cid,
                                                i
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "contacts-list-item",
                                            {
                                              attrs: {
                                                contact: contact,
                                                noswitch: _vm.noswitch
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.changeFavouritesModeP,
                                                      expression:
                                                        "changeFavouritesModeP"
                                                    }
                                                  ],
                                                  staticClass: "draggable-menu",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "draggable-move"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-bars",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "hold-to-move"
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "draggable-item-menu",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.doContactMenu(
                                                            $event,
                                                            contact,
                                                            "bottom-right"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-ellipsis-v"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "hor-line light-line"
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }