import { i18n } from '../../../../ext/i18n'
const locale = i18n.messages[i18n.locale]

import {
    GET_ACTIVE_MICROPHONE,
    GET_ACTIVE_WEBCAMERA,
    GET_MEDIA_DEVICE_LAST_OK,
    GET_TYPE_ASISTANT,
} from '../../gettersTypes'

import {
    ACT_SET_CONTACT_STATUS,
    ACT_UPDATE_USER_PARAMS,
    ACT_CHANGE_PERMISSION_DEVICE_EVENT,
    ACT_WEBCAMERA_ENABLE,
    ACT_CHECK_MEDIA_DEVICE,
    ACT_MODAL_OPEN,
    ACT_CHECK_MEDIA_DEVICES,
    ACT_REQUEST_MEDIA_DEVICE_ACCESS,
    ACT_REQUEST_MEDIA_DEVICES_ACCESS,
} from '../../actionsTypes'

import {
    MUT_SET_CONTACT_STATUS,
    MUT_SET_USER_PARAMS,
    MUT_SAVE_ASSISTANTS,
    MUT_WEBCAMERA_ENABLE,
    MUT_SET_MEDIA_DEVICE_LAST_OK,
    MUT_SAVE_INTEGRATION_SETTINGS,
} from '../../mutationsTypes'

import proto from '../../../protocol'
import {
    ASISTANTS,
    MODAL,
    PHONE,
} from '../../modulesNames'

import module from '../../modules/userdata'
import ipc from "../../../../electron/ipc"

import moment from 'moment'

const actions = {
    async [ACT_UPDATE_USER_PARAMS]({ commit, rootGetters }) {
        try {
            let params = await proto.getUserParams()
            if (params.services.length) {
                commit(`${ASISTANTS}/${MUT_SAVE_ASSISTANTS}`, params.services, { root: true })

                params.services.forEach((service) => {
                    if (service.type === 'schedule') {
                        const { type, scheduleType: integrationName, ...settings } = service
                        commit(MUT_SAVE_INTEGRATION_SETTINGS, {integrationName, settings})
                    }
                })
            }
            commit(MUT_SET_USER_PARAMS, params)
            let typeAssistant = rootGetters[`${ASISTANTS}/${GET_TYPE_ASISTANT}`]
            if (typeAssistant && !['none', 'roschat'].includes(typeAssistant)) proto.setUserPhone({ type: typeAssistant })
        } catch (e) {}
    },
    [ACT_SET_CONTACT_STATUS] (obj, value) {
        proto.setContactsStatus(value)
        obj.commit(MUT_SET_CONTACT_STATUS, value)
    },
    [ACT_WEBCAMERA_ENABLE]({commit, dispatch}, data) {
        dispatch(`${PHONE}/${ACT_CHANGE_PERMISSION_DEVICE_EVENT}`, {type: 'audio', volume: data}, {root: true});
        commit(MUT_WEBCAMERA_ENABLE, data);
    },
    async [ACT_CHECK_MEDIA_DEVICES]({dispatch, rootGetters, getters}, { audio, video, audioAlert = true, videoAlert = false, checkInterval }) {
        const result = {}
        if (audio) result.audio = await dispatch(ACT_CHECK_MEDIA_DEVICE, { deviceType: 'audio', showAlert: audioAlert, checkInterval })
        if (video) result.video = await dispatch(ACT_CHECK_MEDIA_DEVICE, { deviceType: 'video', showAlert: videoAlert, checkInterval })
        return result
    },
    async [ACT_CHECK_MEDIA_DEVICE]({dispatch, commit, rootGetters, getters}, { deviceType, showAlert, checkInterval  = 0 }) {
        let device
        let constraints = { audio: false, video: false }
        const unixTime = moment().unix()

        if (checkInterval) {
            const lastOk = getters[GET_MEDIA_DEVICE_LAST_OK](deviceType)
            if (lastOk && ((lastOk + checkInterval) > unixTime)) return true
        }

        if (deviceType === 'audio') {
            device = getters[GET_ACTIVE_MICROPHONE]
        } else {
            device = getters[GET_ACTIVE_WEBCAMERA]
        }
        const deviceId = device && device.deviceId
        const deviceCommonErrorText = locale[`check-device-${deviceType}-error`]

        constraints[deviceType] = deviceId ? { deviceId: { exact: device.deviceId } } : true

        const updateDeviceList = deviceId === ''

        return new Promise(async (resolve) => {
            try {
                await dispatch(ACT_REQUEST_MEDIA_DEVICE_ACCESS, { deviceType })
                const stream = await navigator.mediaDevices.getUserMedia(constraints)
                try {
                    try { stream.getTracks().forEach((track) => track.stop()) } catch (e) {}
                    if (updateDeviceList) navigator.mediaDevices.ondevicechange && await navigator.mediaDevices.ondevicechange()
                } catch (e) {}
                commit(MUT_SET_MEDIA_DEVICE_LAST_OK, { deviceType, time: unixTime })
                resolve(true)
            } catch (e) {
                console.log(`!! ${deviceType} is NOT ok!`, e)
                let deviceDetailsErrorText
                if (e.name === "NotFoundError" || e.name === "DevicesNotFoundError") {
                    deviceDetailsErrorText = locale['check-device-error-not-found']
                } else if (e.name === "NotReadableError" || e.name === "TrackStartError") {
                    deviceDetailsErrorText = locale['check-device-error-busy']
                } else if (e.name === "OverconstrainedError" || e.name === "ConstraintNotSatisfiedError") {
                    deviceDetailsErrorText = locale['check-device-error-not-connected']
                } else if (e.name === "NotAllowedError" || e.name === "PermissionDeniedError") {
                    deviceDetailsErrorText = locale['check-device-error-no-permission']
                }
                let errorText = deviceCommonErrorText
                if (deviceDetailsErrorText) errorText += `. ${deviceDetailsErrorText}`
                showAlert && showDeviceAlert(dispatch, errorText)
                commit(MUT_SET_MEDIA_DEVICE_LAST_OK, { deviceType })
                resolve(false)
            }
        })
    },
    async [ACT_REQUEST_MEDIA_DEVICES_ACCESS] ({dispatch, rootGetters}, { audio, video }) {
        if (audio) await dispatch(ACT_CHECK_MEDIA_DEVICE, { deviceType: 'audio' }).catch(() => {})
        if (video) await dispatch(ACT_CHECK_MEDIA_DEVICE, { deviceType: 'video' }).catch(() => {})
    },
    async [ACT_REQUEST_MEDIA_DEVICE_ACCESS] ({rootGetters}, { deviceType }) {
        const isElectron = rootGetters['clientdata/getIsElectron']

        if (!isElectron) return

        let electronDeviceType
        let isAccessGranted

        if (deviceType === 'audio') {
            electronDeviceType = 'microphone'
        } else {
            electronDeviceType = 'camera'
        }

        isAccessGranted = (await ipc.sendAsync('get-media-access-status', electronDeviceType) === 'granted')
        if (!isAccessGranted) {
            try {
                isAccessGranted = await ipc.sendAsync('ask-media-access', electronDeviceType)
            } catch (e) {
                isAccessGranted = false
            }
            if (!isAccessGranted) {
                let error = new Error('not-granted')
                error.name = 'PermissionDeniedError'
                throw error
            }
        }
    },
}

function showDeviceAlert(dispatch, text) {
    dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
        name: 'alert',
        props: {
            title: locale.errors.error,
            text: text
        }
    }, { root: true })
}

Object.assign(module.actions, actions)

export default module
