var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loaded,
          expression: "!loaded"
        }
      ],
      attrs: { id: "login-screen" }
    },
    [
      _c("section", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.auto || _vm.error || _vm.pinrequired,
                expression: "!auto || error || pinrequired"
              }
            ],
            staticClass: "login-block"
          },
          [
            _c("div", { staticClass: "left-block side-block" }, [
              _c("div", { staticClass: "block-warapper" }, [
                _c("div", { staticClass: "project-logo" }, [
                  _c("div", { staticClass: "logo-wrapper" }, [
                    _c("img", { attrs: { src: _vm.logoImg } })
                  ])
                ]),
                _c("div", { staticClass: "greeting-block" }, [
                  _c("p", { staticClass: "greeting" }, [
                    _vm._v(_vm._s(_vm.$t("loginPage.greeting")))
                  ]),
                  _c("p", { staticClass: "slogan" }, [
                    _vm._v(_vm._s(_vm.$t("loginPage.tagLine")))
                  ])
                ]),
                _c("div", { staticClass: "entrance-text" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("loginPage.entrance-text")))
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "right-block side-block" }, [
              _c(
                "div",
                { staticClass: "block-warapper" },
                [
                  _c("entrance", {
                    ref: "entrance",
                    attrs: { logging: _vm.changed },
                    on: {
                      "update:logging": function($event) {
                        _vm.changed = $event
                      }
                    }
                  }),
                  _vm.logined && !_vm.loaded
                    ? _c("div", { staticClass: "entrance-status" }, [
                        _c("div", { staticClass: "status-wrapper" }, [
                          _vm._m(0),
                          _c("div", { staticClass: "status-text" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("loginPage.loading-profile"))
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.auto && !_vm.error && !_vm.pinrequired,
                expression: "auto && !error && !pinrequired"
              }
            ],
            staticClass: "autologin-status"
          },
          [_vm._m(1)]
        )
      ]),
      _c("footer", [
        _c("div", { staticClass: "copyright" }, [
          _vm._v(
            "© " +
              _vm._s(_vm.$t("loginPage.itsoft")) +
              " " +
              _vm._s(_vm.year) +
              " " +
              _vm._s(_vm.$t("year"))
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status-icon" }, [
      _c("i", { staticClass: "fal fa-spinner fa-spin" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status-wrapper" }, [
      _c("div", { staticClass: "status-icon" }, [
        _c("i", { staticClass: "fal fa-spinner fa-spin" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }