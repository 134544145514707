//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import ChatMessage from './ChatMessage.vue'
import ReplyMessage from './ReplyMessage.vue'
import CustomTextarea from '../custom/CustomTextarea.vue'
import IconImage from '../IconImage.vue'
import DetailsMsg from '../info/DetailsMsg.vue'
import store from '../../store/main/store'
import ChatPollMessage from '../chatPollMessage.vue'
import AttachMessage from './AttachMessage.vue'
import {
    CONTENT_MANAGER,
    BOTS,
    CHATS,
    CHAT,
    CONTACTS,
    INFO,
    LOGIN,
    USERDATA,
    SOCKET,
} from '../../store/modulesNames'
import {
    GET_CHAT_MESSAGES,
    GET_MAIN_TYPE,
    GET_BOT_BY_ID,
    GET_IS_CHAT_MEMBER,
    GET_DOCUMENT_HIDDEN,
    GET_IS_LOGINED,
    GET_CHAT_DRAFT,
    GET_CHAT_POSITION,
    GET_SERVER_API,
    IS_CHAT_READ_ONLY,
    IS_CHAT_ADMIN,
    GET_ACTIVE_MICROPHONE,
    GET_CHAT_MEMBERS,
    GET_CHAT,
    GET_CHAT_EDIT_MESSAGE_ID,
    GET_CHATT_REPLY_MESSAGE_ID,
    GET_CHAT_SELECTED_MSG_ENTITIES,
    GET_CONNECTION_STATUS,
    GET_MY_CONTACT,
    GET_SUPPORT,
    GET_CHAT_ITEM_TEXT,
    GET_SIDE_BAR_NOTIFICATION,
    GET_SELECT_MODE,
    GET_SELECTED_MSGS,
    GET_IS_COMPACT_MODE,
} from '../../store/gettersTypes'
import {
    ACT_UPDATE_CONTACT_STATUS,
    ACT_INFO_REPLACE,
    ACT_CHAT_SCROLL_UP,
    ACT_WATCH_MESSAGES,
    ACT_WATCH_ALL_CHAT_MESSAGES,
    ACT_SET_CHAT_DRAFT,
    ACT_SET_CHAT_POSITION,
    ACT_BOT_ADD,
    ACT_SEND_MESSAGE,
    ACT_CHAT_CHANGE_MSG_TEXT,
    ACT_CHAT_UPDATE_EDITED,
    ACT_CHAT_GET_MESSAGE_BY_ID,
    ACT_CHAT_UPDATE_REPLY,
    ACT_SEND_FILE_MESSAGE,
    ACT_SEND_POOL_MESSAGE,
    ACT_SEND_GEO_MESSAGE,
    ACT_SEND_DATA_MESSAGE,
    ACT_SEND_TYPING_EVENT,
    ACT_SET_CHAT_MARKED,
    ACT_CHAT_ADD_MESSAGE,
    ACT_SET_SELECTED_MSGS,
} from '../../store/actionsTypes'
import { MUT_SET_SIDE_BAR_NOTIFICATION, MUT_DELETE_SIDE_BAR_NOTIFICATION } from '../../store/mutationsTypes'
import BotCommands from '../bots/BotCommands.vue'
import botKeyboardChat from '../bots/BotKeyboardChat.vue'
import SearchContacts from '../SearchContacts.vue'
import DropFile from '../DropFile.vue'
import LoaderFiles from '../LoaderFiles.vue'
import { i18n } from '../../../ext/i18n'
import SelectContactSendInChat from "../modal/SelectContactSendInChat.vue"
import EmojiPicker from "./EmojiPicker.vue";
import { MAIN_TYPES, SIDE_TYPES } from "../../store/modules/content-manager"
import AudioMsgRecorder from './AudioMsgRecorder.vue'
import ChatSelectedWrapper from './ChatSelectedWrapper.vue'
import ManageSelectedMessages from './ManageSelectedMessages.vue'
import ChatMessageMixin from './chat-message-mixin'

let locale = i18n.messages[i18n.locale]

export default {
    name: 'Chat',
    mixins: [ChatMessageMixin],
    components: {
        'chat-message': ChatMessage,
        'custom-textarea': CustomTextarea,
        'reply-message': ReplyMessage,
        'icon-image': IconImage,
        'details-msg': DetailsMsg,
        'chat-poll-message': ChatPollMessage,
        'attach-message': AttachMessage,
        'bot-commands': BotCommands,
        'bot-keyboard-chat': botKeyboardChat,
        'search-contacts': SearchContacts,
        'drop-file': DropFile,
        'emoji-picker': EmojiPicker,
        AudioMsgRecorder,
        ChatSelectedWrapper,
        ManageSelectedMessages,
    },
    store,
    i18n,
    data () {
        return {
            preview_id: 1,
            cid: null,
            cidType: null,
            message_input: '',
            messages_input: '',
            clip_open: false,
            message_length_limit: declarations.msgLimits.msgLength,
            file_size_limit: declarations.msgLimits.maxFileSize,
            filetype: '*',
            alert: false,
            alert_msg: '',
            pause_send_typing: true,
            unwatched_start_msg_id: null,
            visible: false,
            isBot: false,
            isSystemMsg: false,
            bot: null,
            showBotCommand: false,
            showBotKeyboard: true,
            showBotKeyboardChat: false,
            searchContacts: false,
            searchContactInput: '',
            msgs_load_possible: true,
            msgs_load_locked: false,
            msgs_load_step_size: 50,
            first_messages_load: true,
            previous_first_message_id: 0,
            scroll: {
                old_top_position: 0,
                msgs_start_update_top_position: 0,
                is_visible: false,
                direction_up: false,
            },
            scroll_up: false,
            dropFile: false,
            dropFileTimer: null,
            loaded: false,
            newMsgAnimation: false,
            sendMessageBlocked: false,
            watchDelayId: 0,
            msgsWithDelayWatch: [],
            caretPositionWithinCustomTextArea: 0,
            caretPositionWithinCustomTextAreaTimeout: 0,
            contactStartCaretPosition: 0,
            showPicker: false,
            emojiImgTagClassName: 'emoji emojibgrd',
            emojiPickerIconTagName: 'i',
            emojiPickerBtnClassName: 'emoji-button',
            emojiPickerIconClassName: 'fal fa-smile',
            recordingAudioMessage: false,
            disablingWindow: null,
            editedMsgText: '',
            load_id: 1,
            childrenLoaded: false,
            maximagesize: {
                height: 0,
                width: 0,
            },
            mouseClickedAndWithinChat: false,
            isSelectMode: false,
        }
    },
    asyncComputed: { //@todo любое использование прямое и нет message_input в теплейте приводит к лагам при вводе текста
        inputTextLength() {
            return this.message_input && this.message_input.trim && this.message_input.trim().length || 0
        },
        inputTextIsSet() {
            return Boolean(this.inputTextLength)
        },
        disableBtn () {
            let blocked = !(this.isSocketConnected && this.inputTextLength <= this.message_length_limit && (this.inputTextIsSet || this.isEditMediaFile))
            if (!this.showEditBtn) { // набор нового сообщения
                blocked = blocked || this.sendMessageBlocked
            }
            return blocked
        },
        isEditMediaFile() {
            const id = this[GET_CHAT_EDIT_MESSAGE_ID]
            if (!id) return false
            const msg = app.store.getters['chat/getMessageById'](id)
            return msg.data.type === declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE || msg.data.type === declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO
        },
    },
    computed: {
        chat() { return { cid: this.cid, cidType: this.cidType }},
        focusedP () { return this.$store.getters['userdata/focusedP'] },
        classes() {
            return {
                'focused': this.focusedP,
                'with-scroll': this.scroll.is_visible,
                'visible-chat': this.visible
            }
        },
        selected_chat () {
            return this.$store.getters['chats/selected']
        },
        unwatched: function () {
            const unw_msgs = this.$store.getters['chats/getChatUnwatchedMessages']({ cid: this.cid, cidType: this.cidType })
            return unw_msgs > 99 ? '99+' : unw_msgs
        },
        unwatchedMap() {
            if (!this.focusedP) return {}
            if (this[GET_DOCUMENT_HIDDEN]) return {}
            return this.$store.getters['chats/getUnwatched']({ cid: this.cid, cidType: this.cidType, all: true }).reduce((result, cur) => {
                result[cur.id] = cur
                return result
            }, {})
        },
        messages: function () {
            let msgs = this[GET_CHAT_MESSAGES]
            let result = []
            let prev_msg; let cur_msg; let date_item
            let message_item
            for (let i = 0, count = msgs.length; i < count; i++) {
                cur_msg = msgs[i]
                date_item = getDateItem(prev_msg, cur_msg)
                message_item = getMessageItem(cur_msg)
                if (date_item) {
                    if (result.length) {
                        // Отображение иконки у последнего за день сообщения
                        let prev_result = result[result.length - 1]
                        if (message_item.type !== 'system' && prev_result.type !== 'system') {
                            prev_result.seriesMessagesEnd = true
                        }
                    }
                    result.push(date_item)
                }
                let prevResultIsSystem = result[result.length - 1] && result[result.length - 1].type === 'system'
                let prevMsgSenderSame = msgs[i - 1] && msgs[i - 1].senderId === cur_msg.senderId
                let nextMsg = msgs[i + 1]
                let nextMsgSenderSame = nextMsg && nextMsg.senderId === cur_msg.senderId
                // Отображение фио у первого cooбщения в серии сообщений одного пользователя
                if (message_item.type !== 'system' && (i === 0 || !prevMsgSenderSame || prevResultIsSystem)) {
                    message_item.seriesMessagesStart = true
                }
                // Отображение иконки у последнего cooбщения в серии сообщений одного пользователя

                if ((message_item.type !== 'system' && (i === msgs.length - 1 || !nextMsgSenderSame ||  nextMsg.dataType === 'system'))) {
                    message_item.seriesMessagesEnd = true
                }

                result.push(message_item)
                prev_msg = cur_msg
            }
            return result
        },
        is_member () { return this[GET_IS_CHAT_MEMBER](this.selected_chat) },
        uid () {
            return this.$store.getters['userdata/getUid']
        },
        botKeyboard () {
            return this.$store.getters['chats/getKeyboard'](this.cid)
        },
        marked () {
            let chat = this[GET_CHAT]({cid: this.cid, cidType: this.cidType})
            return chat && chat.settings && chat.settings.marked
        },
        draft() {
            return this[GET_CHAT_DRAFT]({cid: this.cid, cidType: this.cidType})
        },
        position(){
            return this[GET_CHAT_POSITION]({cid: this.cid, cidType: this.cidType}) || {}
        },
        readOnly() {
            return this[IS_CHAT_READ_ONLY]({cid: this.cid, cidType: this.cidType})
        },
        isContactBot() {
            return this.getChatContact && this.getChatContact.isBot 
        },
        getAllowedActions() {
            return this.getChatContact && this.getChatContact.actions || {}
        },
        isViewProfileAllowed() {
            if (!this.isRolesModelSupported || this.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) return true
            let isAllowed =true
            if (this.getAllowedActions.hasOwnProperty('view-contact')) isAllowed = this.getAllowedActions["view-contact"]
            return isAllowed
        },
        isAdmin() {
            return this[IS_CHAT_ADMIN]({cid: this.cid, cidType: this.cidType})
        },
        sendLocked() {
            return !this.isChatAllowed || this.isSystemMsg || !this.is_member && !this.isBot || this.readOnly && !this.isAdmin || this.ownChat
        },
        ownChat() {
            return this.cidType === 'user' && this.cid === this.uid && this[GET_MAIN_TYPE] === MAIN_TYPES.CONTACT
        },
        myContactActions() {
            return this[GET_MY_CONTACT].actions || []
        },
        isMyAllowedReceiveMessage() {
            if (!this.isRolesModelSupported) return true
            if (this.selected_chat.cid === this[GET_MY_CONTACT].cid) return true
            const myActions = this.myContactActions
            return !!myActions["receive-message"]
        },
        isContactAllowedReceiveMessage() {
            if (!this.isRolesModelSupported || this.cidType === 'group') return true
            const contactActions = this.getAllowedActions
            let isAllowed = !!contactActions["receive-message"]
            return isAllowed
        },
        isContactAllowedSendMessage() {
            if (!this.isRolesModelSupported || this.cidType === 'group') return true
            const contactActions = this.getAllowedActions
            let isAllowed = !!contactActions["send-message"]
            return isAllowed
        },
        isContactAllowedRemoteSendMessage() {
            if (!this.isRolesModelSupported) return true
            const contactActions = this.getAllowedActions
            if (!contactActions.hasOwnProperty('remote-send-message')) return true
            let isAllowed = !!contactActions["remote-send-message"]
            return isAllowed
        },
        isChatAllowed() {
            if (!this.isRolesModelSupported || this.isContactBot || !this.selected_chat.cid) return true
            if (this.selected_chat.cid === this[GET_MY_CONTACT].cid || this.selected_chat.cid === (this[GET_SUPPORT] || {}).cid) return true
            if (!this.isViewProfileAllowed) return false
            return this.isSendMessageAllowed && this.isReceiveMessageAllowed || this.showWarning
        },
        isSendMessageAllowed() {
            return  this.cidType === 'group' || this.isContactBot || this.isViewProfileAllowed && this.isContactAllowedSendMessage && this.isContactAllowedReceiveMessage
        },
        isReceiveMessageAllowed() {
            return this.cidType === 'group' || this.isContactBot || this.isMyAllowedReceiveMessage && this.isContactAllowedRemoteSendMessage
        },
        getChatNotAllowedMessage() {
            if (!this.selected_chat || !this.selected_chat.cid) return ''
            if (!this.isSendMessageAllowed && this.isReceiveMessageAllowed) return this.$t('roles.not-allowed-send-allow-receive')
            else if (this.showWarning) return this.$t('roles.not-allowed-send-you')
            else if (!this.isContactAllowedSendMessage && !this.isContactAllowedReceiveMessage) return this.$t('roles.not-allowed-send-and-receive')
            else if (!this.isChatAllowed) return this.$t('cant-start-chat')
        },
        showWarning() {
            return this.isSendMessageAllowed && !this.isReceiveMessageAllowed
        },
        chatPlaceholder() {
            return this.$t('chat.enter-msg')
        },
        disableContBtn() {
            return !this.isSendMediaMessage
        },
        sendFilesTitle() {
            return this.isSendMediaMessage ? this.$t('chat.send-file') : this.$t('roles.no-send-media-message')
        },
        getRecordAudioTitle() {
            return this.isSendMediaMessage ? this.$t('chat.record-audio-msg') : this.$t('roles.no-send-media-message')
        },
        cantStartChat() {
            return this.selected_chat.cidType === 'user' && (!this.isSystemMsg || this.ownChat)
        },
        showBtnNewMsg() {
            return this.scroll_up;
        },
        showBotConnect() {
            if (!this.isBot || this.cidType !== 'user') return false
            return !this[GET_CHAT]({cid: this.cid, cidType: this.cidType})
        },
        membersCount() {
            return this[GET_CHAT_MEMBERS]({cid: this.cid, cidType: this.cidType}).length
        },
        showEditBtn() {
            return !!this[GET_CHAT_EDIT_MESSAGE_ID]
        },
        showCommandBtn() {
            let show = !this.recordingAudioMessage // не пишем аудио
            //show = show && !this.showEditBtn // не редактируем
            show = show && !this.showBotCommand // команды не открыты
            show = show && this.isBot // чат с ботом
            show = show && this.bot && this.bot.commands && this.bot.commands.length > 0 // у бота есть команды
            return show
        },
        showKeyboardBtn() {
            return !this.recordingAudioMessage && this.isBot && this.botKeyboard && this.botKeyboard.length
        },
        isSocketConnected() {
            return this[GET_CONNECTION_STATUS] === 'connected'
        },
        isUpdatingChat() {
            return this.$store.getters['chat/isUpdating']
        },
        ...mapGetters(BOTS, [GET_BOT_BY_ID]),
        ...mapGetters(CHAT, [GET_CHAT_MESSAGES, GET_CHAT_EDIT_MESSAGE_ID, GET_CHATT_REPLY_MESSAGE_ID, GET_CHAT_SELECTED_MSG_ENTITIES, GET_SELECT_MODE, GET_SELECTED_MSGS]),
        ...mapGetters(CHATS, [GET_CHAT, GET_IS_CHAT_MEMBER, GET_CHAT_DRAFT, GET_CHAT_POSITION, IS_CHAT_READ_ONLY, IS_CHAT_ADMIN, GET_CHAT_MEMBERS]),
        ...mapGetters(CONTENT_MANAGER, [GET_DOCUMENT_HIDDEN, GET_MAIN_TYPE, GET_SIDE_BAR_NOTIFICATION]),
        ...mapGetters(LOGIN, [GET_IS_LOGINED, GET_SERVER_API]),
        ...mapGetters(USERDATA, [GET_ACTIVE_MICROPHONE]),
        ...mapGetters(SOCKET, [GET_CONNECTION_STATUS]),
        ...mapGetters(CONTACTS, [GET_SUPPORT]),
        ...mapGetters(INFO, [GET_IS_COMPACT_MODE]),            
    },
    watch: {
        [GET_CHAT_MESSAGES]() {
            this.$nextTick(() => {
                this.onMsgsLoaded()
            })
        },
        messages(newVal, prevVal) {
            let prevLen = prevVal.length
            if (this.first_messages_load) return
            let newLen = newVal.length
            let lastPrev = prevVal[prevLen - 1]
            let lastNew = newVal[newLen - 1]
            if (lastPrev && lastNew && lastPrev.cid === lastNew.cid && lastPrev.cidType === lastNew.cidType && lastPrev.id < lastNew.id) {
                this.onNewMsg(lastNew)
            }
        },
        async selected_chat (chat) {
            try {
                this.beforeUnmount()
            } catch (e) {}
            await this.setChat(chat.cid, chat.cidType)
            this.botUpdate()
        },
        message_input() { // @todo вынести текст в константы
            if (!this.message_input.length) this.showBotCommand = false
            else if (/^\//.test(this.message_input) && this.isBot) this.showBotCommand = true
            this.sendTyping()
        },
        inputTextLength(len) {
            let moreThenLimit = len > this.message_length_limit
            if (moreThenLimit) {
                this.alert_msg = `${locale.chat['max-length-exceeded']} ${len}/${this.message_length_limit}`
            } else {
                this.alert_msg = '';
            }
            this.alert = moreThenLimit
        },
        cid () {
            if (this.cid === 0) this.isSystemMsg = true
            else this.isSystemMsg = false
        },
        scroll_up() {
            this.$store.dispatch(`${CHAT}/${ACT_CHAT_SCROLL_UP}`, this.scroll_up)
        },
        [GET_DOCUMENT_HIDDEN](hidden) {
            if (!(hidden || (this.scroll.is_visible && this.scroll_up))) {
                const {cid, cidType} = this
                const messages = this.$store.getters['chats/getUnwatched']({ cid, cidType })
                if (messages.length) this[ACT_WATCH_MESSAGES]({ messages })
            }
        },
        [GET_IS_LOGINED](newVal) {
            if (newVal) {
                this.sendMessageBlocked = false
            }
        },
        membersCount() {
            this.botUpdate()
        },
        [GET_CHATT_REPLY_MESSAGE_ID]() {
            this.setCursorToInputEnd()
        },
        async [GET_CHAT_EDIT_MESSAGE_ID](id, oldId) {
            if (!this.childrenLoaded) return
            let inputText = ''
            let newMsg = id && await this[ACT_CHAT_GET_MESSAGE_BY_ID]({id})
            newMsg = newMsg && getMessageItem(newMsg)
            if (!newMsg) inputText = ''
            else if (newMsg.type === 'text' && newMsg.msg) inputText = newMsg.msg
            else if (newMsg.msg && newMsg.msg.text) inputText = newMsg.msg.text
            else if (newMsg.msg && newMsg.type === 'data' && newMsg.sub_type === 'text') {
                inputText = newMsg.msg
                let { text, entities } = this[GET_CHAT_SELECTED_MSG_ENTITIES]
                if (inputText === text) {
                    text = text.replace(/</g,'\x7F').replace(/>/g,'\x8F')
                    inputText = this.applyInputTextFormat(text, entities)
                    inputText = inputText.replace(/\x7F/g,'<').replace(/\x8F/g,'>')
                }
            }
            let sanitized_text = inputText.replace(/</g,'&lt;').replace(/>/g,'&gt;')
            this.editedMsgText = sanitized_text
            if (newMsg) this.setInputText(sanitized_text)
            else if (oldId) this.inputReset()
        },
        botKeyboard() {
            this.botKeyBoardAction()
        },
        isSendMediaMessage(isAllowed) {
            const currentMsg = this[GET_SIDE_BAR_NOTIFICATION](SIDE_TYPES.CHATS)
            const isExist = currentMsg.indexOf(locale.roles["no-send-media-message"]) > -1
            const payload = { type: SIDE_TYPES.CHATS, msg: currentMsg ? currentMsg + this.$t("roles.no-send-media-message") : this.$t("roles.no-send-media-message") }
            if (!isAllowed) {
                if (!isExist) this.$store.commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload)
            } else {
                payload.msg = this.$t("roles.no-send-media-message")
                if (isExist) this.$store.commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload)
            }
        }
    },
    methods: {
        botUpdate() {
            let chat = { cid: this.cid, cidType: this.cidType }
            let bot
            if (chat.cidType === 'group') {
                let members = [...this[GET_CHAT_MEMBERS](chat)]
                let member
                while (!bot && (member = members.shift())) {
                    bot = this[GET_BOT_BY_ID](member.cid)
                }
            } else {
                bot = this[GET_BOT_BY_ID](chat.cid)
            }

            if (bot) {
                this.isBot = true
                this.bot = bot
            } else {
                this.bot = null
                this.isBot = false
            }
        },
        onNewMsg(message) {
            let inMsg = message.type === 'in' || (message.senderId && message.senderId !== this.uid)
            this.newMsgAnimation = !this.first_messages_load && this.focusedP

            this.$nextTick(() => {
                !inMsg && this.autoScrollChat()
                this.scroll_up = !this.scrolledToBottom()

                if (inMsg) { // входящие
                    if (!this.scroll_up) {                              // скрол внизу
                        if (this.focusedP) {                            // приложение в фокусе
                            this.$store.dispatch(`${CHATS}/${ACT_WATCH_MESSAGES}`, {messages: [message]})
                        }
                        this.autoScrollChat()
                        this.scroll_up = !this.scrolledToBottom()
                    }
                }
            })
        },
        onMsgsLoaded() {
            this.$nextTick(() => {
                if (this.first_messages_load) {
                    this.first_messages_load = false
                    this.msgs_load_possible = true
                    let firstUnwatched = this[GET_CHAT_MESSAGES].find(msg => ((msg.type === 'in' || this.uid !== msg.senderId) && !('watchedTime' in msg)))
                    if (firstUnwatched) this.unwatched_start_msg_id = firstUnwatched.id
                }
            })
        },
        saveScrollPosition() {
            let payload = { cid: this.cid, cidType: this.cidType }
            if (!this.scrolledToBottom()) {
                let list = this.$refs.messageList
                let listVisibilityStartPosition = list.scrollTop
                let messagesNodes = this.$refs.ulList.querySelectorAll('li[id]')
                let anchorNodeIndex = Array.prototype.findIndex.call(messagesNodes, (node) => listVisibilityStartPosition <= node.offsetTop + node.clientHeight)
                let startNodeIndex = Math.max(anchorNodeIndex - anchorNodeIndex % this.msgs_load_step_size, 0)
                let anchorNode = messagesNodes[anchorNodeIndex]
                let startNode = messagesNodes[startNodeIndex]
                let anchorOffset = anchorNode.offsetTop - listVisibilityStartPosition
                let anchorId = +anchorNode.parentNode.__vue__.$el.id //anchorNode.__vue__.message.id
                let startId = +startNode.parentNode.__vue__.$el.id //__vue__.message.id
                payload.position = {startId, anchorOffset, anchorId}
            }
            this[ACT_SET_CHAT_POSITION](payload)
        },
        saveDraft() {
            if (this.$refs.custom_textarea) {
                this[ACT_SET_CHAT_DRAFT](this.getInputText())
            }
        },
        resetDraft() {
            this[ACT_SET_CHAT_DRAFT]()
        },
        showContextMenu(e) {
            const target = e.target
            if (target.localName === 'div' && target.className === 'inner-wrapper' ||
                target.localName === 'ul' && target.className === 'list' || 
                target.localName === 'li' && target.classList[0] === 'item' || 
                target.localName === 'div' && target.classList[0] === 'message-list') {
                    let itemName = this.isSelectMode ? this.$t('chat.cancel-select') : this.$t('chat.select-messages')
                    let handlers = [{item_name: itemName, handler: () => { 
                        this.isSelectMode = !this.isSelectMode
                        if (!this.isSelectMode) this[ACT_SET_SELECTED_MSGS]([])
                    }
                }]
                this.cmOpen(e, handlers, 'right-bottom')
            }
        },
        setSelectMode(val) {
            this.isSelectMode = val
        },
        dragenter(e) {
            e.preventDefault()
            if (e.dataTransfer.types) {
                for (let i = 0; i < e.dataTransfer.types.length; i++) {
                    if (e.dataTransfer.types[i] == "Files") {
                        if (!this.dropFile) this.dropFile = true
                    }
                }
            }
        },
        dragover(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        dragleave(e) {
            this.dropFile = false
        },
        dropdone(e) {
            this.dropFile = false
        },
        async doGotoNewMsg () {
            this.scroll_up = false
            await this.autoScrollChat()
            this.updateUnwatchedMessages()
        },
        async doSendMessage () {
            this.showPicker = false
            if(!this.disableBtn) {
                if (this.showEditBtn) {
                    await this.saveEditedMsg()
                } else {
                    const inText = this.getInputText()
                    const { outText, entities } = this.extractInputTextFormat(inText)
                    await this.sendTextMessage(outText, entities, true)
                }
                this.setCursorToInputEnd()
            }
        },
        async doSendBotCommand(cmd, reset) {
            if (this.showEditBtn) reset = false
            let entities = [{ type:"bot-command", offset: 0, length: cmd.length, botCommand: cmd }]
            this.sendTextMessage(cmd, entities, reset)
        },
        async sendTextMessage(text, entities, reset) {
            let { cid, cidType } = this
            this.pause_send_typing = false
            if (reset) this.sendMessageBlocked = false
            this[ACT_UPDATE_CONTACT_STATUS](cid)
            if (!text) return
            let data = await this[ACT_SEND_MESSAGE]({
                cid,
                cidType,
                dataType: declarations.msgDataTypes.MSG_DATA_TYPE_DATA,
                data: JSON.stringify({ type: declarations.msgDataTypes.MSG_DATA_TYPE_TEXT, text, entities })
            })
            if (!data.hasOwnProperty('id')) {
                let message = {
                    cid,
                    cidType,
                    dataType: declarations.msgDataTypes.MSG_DATA_TYPE_DATA,
                    data:  JSON.stringify({ type: declarations.msgDataTypes.MSG_DATA_TYPE_TEXT, text, entities }),
                    id: getlastIdMsg(),
                    senderId: app.getUid(),
                    status: 'sending',
                    time: 0,
                    type: 'out',
                }
                this.$store.dispatch(`${CHAT}/${ACT_CHAT_ADD_MESSAGE}`, { message })

                function getlastIdMsg () {
                    let arr = this.messages
                    return arr[arr.length - 1].id + 1
                }
            }
            if (reset) {
                this.inputReset()
                this.sendMessageBlocked = false
                this.resetDraft()
            }
        },
        startSearchContact(searchString, position) {
            if ((position > 0 && searchString.charAt(position - 1) === '\n') ||
                (position > 0 && searchString.charAt(position - 1) === ' ')) {
                this.searchContacts = false;
                return;
            }
            searchString = searchString.replace(/<br>/g,' ');
            const searchPattern = /^@(?!\[)(.*?)(?!\])\S*|^@(?!\[)(.*?)(?!\])(\s)|\s{1}@(?!\[)(.*?)(?!\])(\s)|\s{1}@(?!\[)(.*?)(?!\])$/g;
            let found = false;
            let foundInputContact = '';
            let matches = [...searchString.matchAll(searchPattern)];
            matches.forEach(match => {
                if (position >= match.index && position <= match.index + match[0].length) {
                    found = true;
                    foundInputContact = match[0].trim();
                }
            })
            if (!found) {
                this.searchContacts = false;
            }
            else {
                this.searchContacts = true;
                this.searchContactInput = foundInputContact;
            }
        },
        doContactsSend () {
            if (!this.isSendMediaMessage) return
            this.modalOpen({
                name: 'select-contacts-send',
                component: SelectContactSendInChat,
                props: {
                    cb: async ({contact, photoFileName, fields}) => {
                        const isLocal = this.$store.getters['contacts/getLocalUsers'].includes(contact.cid)
                        let data = { type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT}
                        if (Array.isArray(fields) && fields.length) data.fields = fields
                        if (isLocal) {
                            data = {...data}
                        } else {
                            data = {cid: contact.cid, ...data}
                        }
                        if (photoFileName) data = {...data, file: photoFileName}
                        this[ACT_SEND_DATA_MESSAGE]({ cid: this.cid, cidType: this.cidType, payload: data })
                    }
                }
            })
        },
        doPollSend () {
            if (!this.isSendMediaMessage) return
            this[ACT_SEND_POOL_MESSAGE]({cid: this.cid, cidType: this.cidType})
        },
        doGeoDataSend () {
            if (!this.isSendMediaMessage) return
            this[ACT_SEND_GEO_MESSAGE]({cid: this.cid, cidType: this.cidType})
        },
        async onVoiceMsg(voiceBlob) {
            if (!this.isSendMediaMessage) return
            this[ACT_SEND_FILE_MESSAGE]({cid: this.cid, cidType: this.cidType, file: new File([voiceBlob], '')})
        },
        doDataFileSend (filetype) {
            if (!this.isSendMediaMessage) return
            this.filetype = filetype
            this.dataFileSend()
        },
        doEncFileSend(filetype) {
            if (!this.isSendMediaMessage) return
            this.filetype = filetype
            this.dataFileSend(true)
        },
        dataFileSend (enc = false) {
            let fileLoader = this.$refs.fileLoader
            fileLoader.value = ''
            fileLoader.accept = this.filetype
            fileLoader.setAttribute('multiple', true)
            let changeFn = () => {
                this.openLoaderFiles(enc)
                fileLoader.removeEventListener('change', changeFn)
            }
            fileLoader.addEventListener('change', changeFn)
            fileLoader.click()
        },
        openLoaderFiles (enc = false) {
            let fileLoader = this.$refs.fileLoader
            if (!fileLoader.files || !fileLoader.files.length) return

            let arr = []
            let mediaDescription = false
            for (let i = 0; i < fileLoader.files.length; i++) {
                if (!enc && (fileLoader.files[i].type.includes('image') || fileLoader.files[i].type.includes('video') || fileLoader.files[i].type.includes('file'))) {
                    mediaDescription = true
                }
                if (mediaDescription) arr.push(fileLoader.files[i])
                else this[ACT_SEND_FILE_MESSAGE]({cid: this.cid, cidType: this.cidType, file: fileLoader.files[i], enc})
            }

            if (mediaDescription) {
                this.modalOpen({
                    component: LoaderFiles,
                    props: {cid: this.cid, cidType: this.cidType, files: arr, input: fileLoader},
                })
            }
        },
        async doOnScrolling (event) {
            if (!this.scrolledToBottom() && !this.scroll_up) this.scroll_up = true
            let node = event.target

            let old_height = node.scrollHeight

            if (node.scrollHeight === node.clientHeight + this.scroll.old_top_position) {
                this.scroll.direction_up = true
            } else if (!this.scroll.old_top_position) {
                this.scroll.direction_up = false
            } else {
                this.scroll.direction_up = node.scrollTop < this.scroll.old_top_position
            }

            this.scroll.old_top_position = node.scrollTop

            if (this.scrolledToBottom() && !this.scroll.direction_up) {
                this.scroll_up = false
                this.updateUnwatchedMessages()
            }
            this.scroll.is_visible = old_height !== node.clientHeight

            if (!this.msgs_load_possible) return
            if (this.msgs_load_locked || !(node.scrollHeight > node.clientHeight && node.scrollTop <= 100 && this.scroll.direction_up)) return
            this.scroll.msgs_start_update_top_position = node.scrollTop
            this.msgs_load_locked = true
            await this.getMoreMessages()
            this.msgs_load_locked = false
            this.$nextTick(() => {
                node.scrollTop = node.scrollHeight - old_height - this.scroll.msgs_start_update_top_position
            })
            while (node.scrollTop === 0 && this.msgs_load_possible && this.mouseClickedAndWithinChat) {
                await this.getMoreMessages()
            }
        },
        clipOpen () {
            if (this[GET_CHAT_EDIT_MESSAGE_ID]) return
            let checkClickElement =  (event) => {
                if (event.target.className !== 'clip-cont') {
                    this.clip_open = !this.clip_open
                    document.removeEventListener('click', checkClickElement)
                }
            }
            if (!this.clip_open) {
                this.clip_open = !this.clip_open
                setTimeout(() => {
                    document.addEventListener('click', checkClickElement)
                }, 0)
            }
        },
        closeEvent: function () {
            this.clip_open = false
        },
        botCommand () {
            this.showBotCommand = true
            this.setInputText('/')
        },
        closeBotCommands () {
            if (this.showBotCommand && document.activeElement.className === 'custom-textarea') {
                this.showBotCommand = false
                this.$refs.custom_textarea.reset()
            }
        },
        toggleKeyboardChat () {
            this.showBotKeyboardChat = !this.showBotKeyboardChat;
            const el = document.querySelector('.message-list');
            if (!el) this.scroll_up = false;
            const scrollHeight = Math.round(el.scrollHeight);
            const scrollTop = Math.round(el.scrollTop);
            const clientHeight = Math.round(el.clientHeight);
            if (this.showBotKeyboardChat) el.scrollTop = el.scrollTop
            let scrolled = (clientHeight + scrollTop >= scrollHeight - 32)? true: false;
            if (!scrolled || this.scrolledToBottom()) {
                this.scroll_up = false;
            }
            else this.scroll_up = true;
        },
        botKeyBoardAction () {
            this.showBotKeyboardChat = false
        },
        addContactInput (user) {
            let textArea = this.$refs.custom_textarea;
            let textAreaInput = textArea.$refs.custom_input_textarea;
            let allInput = this.unwrapEmojiToNative(textAreaInput.innerHTML);
            let contactInput = this.searchContactInput;
            let contactInputLength = contactInput.length;
            // let contactStr = '@' + '[' + user.cid + ':' + user.fio + ']';
            let contactStr = `@[${user.fio}][contact:${user.cid}] `
            // contactStr = contactStr + ' ';
            let slicedIndex = allInput.search(contactInput);
            const position = slicedIndex + contactInputLength;
            let firstPart = allInput.slice(0, slicedIndex);
            if (allInput.charAt(slicedIndex + contactInputLength) === ' ') slicedIndex++;
            let secondPart = allInput.slice(slicedIndex + contactInputLength, allInput.length);
            let wholeStr = firstPart + contactInput + secondPart;
            wholeStr = wholeStr.replace(contactInput, contactStr);
            textArea.text = wholeStr;
            textAreaInput.innerText = wholeStr;
            textAreaInput.innerHTML = this.wrapEmoji(wholeStr);
            textArea.setCursorToPosition(textAreaInput, position, contactInputLength, contactStr.length);

            this.setCurrentCaretPosition();
            this.searchContacts = false
            this.searchContactInput = ''
            this.message_input = this.getInputText()
        },
        onAddEmoji(emoji) {
            let position = this.caretPositionWithinCustomTextArea;
            let slicedPosition = position;
            let firstHalf = '', secondHalf = '';
            let emojiNative = emoji.native;
            let textArea = this.$refs.custom_textarea;
            textArea.placeholder_up = false;
            let textAreaInput = textArea.$refs.custom_input_textarea;
            let inputWithNativeEmoji = this.unwrapEmojiToNative(textAreaInput.innerHTML);
            let totalLength = inputWithNativeEmoji.length;
            if (slicedPosition > inputWithNativeEmoji.length) slicedPosition = inputWithNativeEmoji.length;
            firstHalf = inputWithNativeEmoji.slice(0, slicedPosition);
            secondHalf = inputWithNativeEmoji.slice(slicedPosition, totalLength);
            let fullText = firstHalf + emojiNative + secondHalf;
            textArea.text = fullText.replace(/<br>/g,'\n');
            textAreaInput.innerText = textArea.text;
            let innerHTML = this.wrapEmoji(fullText);
            innerHTML = innerHTML.replace(/<br>/g, '\n');
            textAreaInput.innerHTML =innerHTML;
            textArea.setCursorToPosition(textAreaInput, position, 0, emojiNative.length);
            this.setCurrentCaretPosition();
            this.message_input = this.getInputText()
        },
        async getMoreMessages () {
            let first_message = (this[GET_CHAT_MESSAGES] || []).slice(0, 1).pop()
            let first_id = first_message && first_message.id || 0
            if (!first_id || this.previous_first_message_id === first_id) {
                this.msgs_load_possible = false
                return
            }
            this.previous_first_message_id = first_id
            let msgs = await this.$store.dispatch('chat/update', {
                cid: this.cid,
                cidType: this.cidType,
                startId: --first_id,
                count: this.msgs_load_step_size,
                non_clear: true,
            })
            if (msgs.length < this.msgs_load_step_size) this.msgs_load_possible = false
        },
        onResize () {
            let node = this.$refs.messageList
            this.scroll.is_visible = node.scrollHeight !== node.clientHeight
            this.maximagesize.width = node.clientWidth
            this.maximagesize.height = node.clientHeight
            //imgMaxHeight
            if (this.scrolledToBottom() && this.scroll_up) this.scroll_up = false
        },
        dropData () {
            this.visible = false
            this.unwatched_start_msg_id = null
            this.msgs_load_possible = true
            this.first_messages_load = true
            this.loaded = false
            this.newMsgAnimation = false
            let scroll = this.scroll
            this.scroll_up = false
            scroll.old_top_position = 0
            scroll.is_visible = false
            scroll.direction_up = false
            this.clearWatchDelayTimeout()
            this.msgsWithDelayWatch = []
            this.childrenLoaded = false
            this.pause_send_typing = true
            this.inputReset()
        },
        withAnimation(index) {
            return this.newMsgAnimation && (index === (this.messages.length - 1))
        },
        onSelection(selection) {
            if (!selection) return
        },
        onCustomTextAreaKeyup() {
            this.setCurrentCaretPosition();
        },
        onCustomTextAreaClick(event) {
            if (event.type === "click" && event.target.attributes["data-text"] && event.target.attributes["data-text"].value){
                let position = event.target.attributes["data-text"].value;
                let textArea = this.$refs.custom_textarea;
                let textAreaInput = textArea.$refs.custom_input_textarea;
                let emoji = event.target;
                let emojiClick = { before: false };
                if (event.x >= emoji.x - emoji.width/2 && event.x <= emoji.x + emoji.width/2 ) emojiClick.before = true;
                textArea.setCursorToPosition(textAreaInput, position, 0, 2, emojiClick);
            }
            this.setCurrentCaretPosition();
        },
        setCurrentCaretPosition() {
            let textArea = this.$refs.custom_textarea;
            let el = textArea && textArea.$refs.custom_input_textarea;
            if (el) {
                let inText = this.unwrapEmojiToNative(el.innerHTML);
                let range = (window.getSelection().rangeCount) ? window.getSelection().getRangeAt(0) : null
                let position = range ? this.getCharacterOffsetWithin(range, el) : this.caretPositionWithinCustomTextArea
                if (this.caretPositionWithinCustomTextAreaTimeout) clearInterval(this.caretPositionWithinCustomTextAreaTimeout)
                if (range) this.caretPositionWithinCustomTextAreaTimeout = setTimeout(() => this.caretPositionWithinCustomTextArea = position, 0)
                this.startSearchContact(inText, position)
            }
        },
        toggleEmojiPicker() {
            this.showPicker = !this.showPicker;
        },
        showEmojiPicker() {
            this.showPicker = true;
        },
        hideEmojiPicker($event) {
            if ($event.target.localName !==this.emojiPickerIconTagName &&
                $event.target.className !== this.emojiPickerIconClassName &&
                $event.target.className.toString().indexOf(this.emojiPickerBtnClassName) === -1)
                this.showPicker = false;
        },
        setInputText(text = '') {
            let textArea = this.$refs.custom_textarea;
            let textAreaInput = textArea.$refs.custom_input_textarea;
            if (textArea) {
                textArea.text = text;
                this.message_input = text;
                this.caretPositionWithinCustomTextArea = text.length;
                let sanitized_text = text.replace(/</g,'&lt;').replace(/>/g,'&gt;')
                textAreaInput.innerHTML = this.wrapEmoji(sanitized_text);
                textArea.setCursorToEnd(textAreaInput);
            }
        },
        getCharacterOffsetWithin(range, node) {
            let treeWalker = document.createTreeWalker(
                node,
                NodeFilter.SHOW_ALL,
                function(node) {
                    var nodeRange = document.createRange();
                    nodeRange.selectNode(node);
                    return nodeRange.compareBoundaryPoints(Range.END_TO_END, range) < 1 ?
                        NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
                },
                false
            );
            let charCount = 0;
            while (treeWalker.nextNode()) {
                let currentNode = treeWalker.currentNode;
                let length = treeWalker.currentNode.length;
                if (length) charCount += length;
                else if (currentNode.className === this.emojiImgTagClassName) charCount += currentNode.alt.length;
                else charCount++;
            }
            if (range.startContainer.nodeType == 3) {
                charCount += range.startOffset;
            }
            return charCount;
        },
        setCursorToInputEnd() {
            if (this.$refs.custom_textarea) this.$refs.custom_textarea.setCursorToEnd()
        },
        onUnwatchedVisible(isVisible, entry, id) {
            if (isVisible) {
                let message = this.unwatchedMap[id]
                if (message) {
                    this.msgsWithDelayWatch.push(message)
                    this.updateWatchDelayTimeout()
                }
            }
        },
        updateWatchDelayTimeout() {
            this.clearWatchDelayTimeout()
            this.watchDelayId = setTimeout(() => {
                this.watchVisibleMsgs()
                this.clearWatchDelayTimeout()
            }, 100)
        },
        clearWatchDelayTimeout() {
            if (this.watchDelayId) clearTimeout(this.watchDelayId)
        },
        watchVisibleMsgs() {
            this[ACT_WATCH_MESSAGES]({messages: this.msgsWithDelayWatch, cid: this.cid, cidType: this.cidType})
            this.msgsWithDelayWatch = []
        },
        messageBlockMouseLeave(e) {
            this.mouseClickedAndWithinChat = false
        },
        messageBlockMouseDown(e) {
            this.mouseClickedAndWithinChat = true
        },
        messageBlockMouseUp(e) {
            this.mouseClickedAndWithinChat = false
        },
        async autoScrollChat(first) {
            const el = this.$refs.messageList
            el.scrollTop = el.scrollHeight
            let last_msg
            let offset
            if (first) {
                if (this.unwatched) {
                    last_msg = el && el.querySelector('.list > .notice-new-msg-wrapper')
                } else if (this.position.anchorId) {
                    let id = this.position.anchorId
                    while (!last_msg && id >= this.position.startId) {
                        last_msg = el && el.querySelector(`.list > .inner-wrapper > #msg${id--}`)
                    }
                    if (last_msg) offset = this.position.anchorOffset
                }
            }
            if (!last_msg) {
                last_msg = el && el.querySelector('ul.list').lastChild //el && el.querySelector('.list > .inner-wrapper > .item:last-child')
            }
            if (!!last_msg && !!last_msg.scrollIntoView) {
                last_msg.scrollIntoView()
                if (offset) el.scrollTop = el.scrollTop - offset
            }
            this.scroll.is_visible = last_msg && (el.scrollHeight !== el.clientHeight)
        },
        async setRecordAudio(val) {
            this.recordingAudioMessage = val
        },
        async botConnect() {
            await this[ACT_BOT_ADD]({cid: this.cid})
        },
        getInputText() {
            let textArea = this.$refs.custom_textarea;
            let textAreaInput = textArea.$refs.custom_input_textarea;
            let text = this.unwrapEmojiToNative(textAreaInput.innerHTML.replace(/<br>/g,'\n'))
            text = text.trim()
            return text
        },
        async saveEditedMsg() {
            if (this.message_input !== this.editedMsgText) {
                let text = this.getInputText()
                const { outText, entities } = this.extractInputTextFormat(text)
                let id = this[GET_CHAT_EDIT_MESSAGE_ID]
                await this[ACT_CHAT_CHANGE_MSG_TEXT]({id, text: outText, newEntities: entities})
            } else {
                await this[ACT_CHAT_UPDATE_EDITED]()
            }
            this.inputReset()
            this.resetDraft()
        },
        onUp(e) {
            if (this[GET_SERVER_API] >= declarations.serverAPILevels.LEVEL_8 || this.message_input || this[GET_CHAT_EDIT_MESSAGE_ID] || this[GET_CHATT_REPLY_MESSAGE_ID]) return
            let lastMessage = this.messages[this.messages.length - 1]
            if (!lastMessage.ownMsg) return
            e.preventDefault()
            this[ACT_CHAT_UPDATE_EDITED](lastMessage)
        },
        subscribeOnChildrenLoad() {
            this.childLoadPromises = {}
            let promises = []

            promises.push(new Promise((resolve) => {
                this.childLoadPromises.attach = resolve
            }))

            if (this.draft) {
                let { replyId, editId, text } = this.draft
                if (text) this.setInputText(text)
                if (editId) this[ACT_CHAT_UPDATE_EDITED]({id: editId})
                else if (replyId) this[ACT_CHAT_UPDATE_REPLY]({id: replyId})
            } else {
                this[ACT_CHAT_UPDATE_REPLY]()
                this.setCursorToInputEnd()
            }

            if (!(this.showBotConnect || this.sendLocked)) promises.push(new Promise((resolve) => {
                this.childLoadPromises.select = resolve
            }))

            this.load_id++

            return Promise.all(promises)
        },
        onAttachLoaded() {
            this.childLoadPromises.attach && this.childLoadPromises.attach()
        },
        onSelectedLoaded() {
            this.childLoadPromises.select && this.childLoadPromises.select()
        },
        updateUnwatchedMessages() {
            if (this.focusedP) {
                let { cid, cidType } = this
                this[ACT_WATCH_ALL_CHAT_MESSAGES]({ cid, cidType })
                this.marked && this[ACT_SET_CHAT_MARKED]({cid, cidType, marked: false})
            }
        },
        scrolledToBottom() {
            const el = this.$refs.messageList
            if (!el) return
            const scrollHeight = Math.round(el.scrollHeight)
            const scrollTop = Math.round(el.scrollTop)
            const clientHeight = Math.round(el.clientHeight)
            return (clientHeight + scrollTop >= scrollHeight - 32)? true: false
        },
        async setChat(cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER) {
            this && this.dropData() && this.dropData()
            Vue.set(this, 'cid', cid)
            Vue.set(this, 'cidType', cidType)
            this.$store.commit('chat/updateCurrentChat', { cid, cidType })

            this[ACT_INFO_REPLACE]({
                type: cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER ? 'contact-info' : 'chat-info',
                params: { cid, cidType, isBot: this.isBot },
            })

            let updatePayload = { cid, cidType }
            let stepSize = this.msgs_load_step_size
            if (this.position.startId) {
                let minId = this.position.startId
                let messages = await this.$store.dispatch('chat/update', { // грузим с id
                    ...updatePayload,
                    minId
                })
                let loadMoreCount
                if (messages.length < stepSize) loadMoreCount = stepSize - messages.length
                if (loadMoreCount) {
                    await this.$store.dispatch('chat/update', { // догружаем предшествующие сообщения
                        ...updatePayload,
                        startId: Math.max(minId - 1, 1),
                        count: loadMoreCount,
                        non_clear: true
                    })
                }
            } else {
                await this.$store.dispatch('chat/update', {
                    ...updatePayload,
                    count: stepSize,
                })
            }

            const message_list = document.querySelector('.message-list')
            message_list.style.visibility = 'hidden'

            Vue.set(this, 'visible', true)
            await this.subscribeOnChildrenLoad()
            this.childrenLoaded = true
            this.pause_send_typing = false
            this.$nextTick(async () => {
                let node = this.$refs.messageList
                this.maximagesize.width = node.clientWidth
                this.maximagesize.height = node.clientHeight
                this.$nextTick(async () => {
                    await this.autoScrollChat(true)
                    message_list.style.visibility = 'visible'
                })
            })
        },
        inputReset() {
            if (!this.$refs.custom_textarea) return
            this.sendMessageBlocked = false
            Vue.set(this, 'message_input', '')
            this.$refs.custom_textarea.reset()
        },
        sendTyping() {
            if (!this.pause_send_typing && this.message_input) {
                this.pause_send_typing = true
                this[ACT_SEND_TYPING_EVENT]({cidType: this.cidType, cid: this.cid})
                setTimeout(() => { this.pause_send_typing = false }, declarations.typingSendInterval)
            }
        },
        clearSelectedMode() {
            this[ACT_SET_SELECTED_MSGS]([])
            this.isSelectMode = false
        },
        beforeUnmount() {
            this.clearSelectedMode()
            this.saveDraft()
            this.saveScrollPosition()
        },
        ...mapActions(CHAT, [
            ACT_CHAT_CHANGE_MSG_TEXT,
            ACT_CHAT_UPDATE_EDITED,
            ACT_CHAT_UPDATE_REPLY,
            ACT_CHAT_GET_MESSAGE_BY_ID,
            ACT_SET_CHAT_DRAFT,
            ACT_SET_SELECTED_MSGS,
        ]),
        ...mapActions(CHATS, [
            ACT_WATCH_MESSAGES,
            ACT_WATCH_ALL_CHAT_MESSAGES,
            ACT_SET_CHAT_POSITION,
            ACT_SEND_MESSAGE,
            ACT_SEND_POOL_MESSAGE,
            ACT_SEND_GEO_MESSAGE,
            ACT_SEND_FILE_MESSAGE,
            ACT_SEND_DATA_MESSAGE,
            ACT_SEND_TYPING_EVENT,
            ACT_SET_CHAT_MARKED,
        ]),
        ...mapActions(CONTACTS, [ACT_UPDATE_CONTACT_STATUS]),
        ...mapActions(INFO, [ACT_INFO_REPLACE]),
        ...mapActions(BOTS, [ACT_BOT_ADD]),
    },
    created() {
        const { cid, cidType } = this.selected_chat
        this.setChat(cid, cidType)
    },
    mounted() {
        const mainContentElem = document.getElementById('main-content')
        if (mainContentElem && this[GET_IS_COMPACT_MODE] && mainContentElem.className.includes('pubCompactMode')) {
            mainContentElem.classList.remove('pubCompactMode')
        }            
        window.addEventListener('resize', this.onResize)
        window.addEventListener('unload', this.beforeUnmount)
        this.subscribeOnSelectionChange(this.onSelection)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('unload', this.beforeUnmount)
        this.unsubscribeOnSelectionChange(this.onSelection)
    },
}

function getDateItem (prev, cur) {
    var prev_date = date_helper.secondsLeftToDateFormat(prev && prev.time)
    var cur_date = date_helper.secondsLeftToDateFormat(cur.time)
    if (prev_date !== cur_date || !prev) {
        return {
            senderId: 0,
            type: 'system',
            sub_type: 'time',
            msg: cur_date,
            side: 'center',
            classes: ['system-message', 'side-center', 'time'],
        }
    }
}

function getMessageItem (message) {
    let out = message.type === 'out' || app.getUid() === message.senderId
    let time = date_helper.secondsLeftToTimeFormat(message.time)
    const isPollId = message.data.pollId || false
    if (isPollId) message.data.type = 'poll'
    return {
        id: message.id,
        cid: message.cid,
        cidType: message.cidType,
        senderId: message.senderId,
        author: message.author,
        isNote: message.cid === message.senderId,
        type: message.dataType,
        sub_type: message.data && message.data.type,
        time: time,
        dateTime: message.timeDetails.fullTime,
        msg: app.store.getters[`${CHAT}/${GET_CHAT_ITEM_TEXT}`]({message}),
        fields: message.data && message.data.fields,
        entities: message.data && message.data.entities,
        ownMsg: out,
        status: out ? getMessageStatus(message) : '',
        sentTime: message.timeDetails.sentTime,
        keyboard: message.data.keyboard,
        replyId: message.replyId,
        edited: 'editedTime' in message,
        previewSize: message.data && message.data.previewSize,
    }
}

function getMessageStatus (message) {
    if (message.watchedTime !== undefined) return 'watched'

    if (message.receivedTime !== undefined) return 'received'
    return ''
}

