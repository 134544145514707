var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section-header info-header" },
    [
      _vm.canBack
        ? _c(
            "div",
            {
              staticClass: "header-back circle-animation",
              on: { click: _vm.goBack }
            },
            [_c("i", { staticClass: "far fa-arrow-left" })]
          )
        : _vm._e(),
      _vm.infoTitleLength
        ? _c("div", { staticClass: "header-text" }, [
            _c("span", [_vm._v(_vm._s(_vm.infoToTitle))])
          ])
        : _vm._e(),
      _vm.isSearchInChat
        ? _c("custom-search", {
            key: "1",
            ref: "searchInput",
            class: _vm.searchChatsClass,
            attrs: {
              search: _vm.chatsSearch,
              placeholder: _vm.placeHolderStr,
              focus: true,
              clearable: false
            },
            on: {
              "update:search": function($event) {
                _vm.chatsSearch = $event
              }
            }
          })
        : _vm._e(),
      _vm.isSearchInChannel
        ? _c("custom-search", {
            key: "2",
            ref: "searchInput",
            class: _vm.searchChatsClass,
            attrs: {
              search: _vm.channelSearch,
              placeholder: _vm.placeHolderStr,
              focus: true,
              clearable: false
            },
            on: {
              "update:search": function($event) {
                _vm.channelSearch = $event
              }
            }
          })
        : _vm._e(),
      _vm.isSearchInMedia
        ? _c("custom-search", {
            key: "3",
            ref: "searchInput",
            class: _vm.searchChatsClass,
            attrs: {
              search: _vm.mediaSearch,
              placeholder: _vm.placeHolderStr,
              focus: true,
              clearable: false
            },
            on: {
              "update:search": function($event) {
                _vm.mediaSearch = $event
              }
            }
          })
        : _vm._e(),
      _vm.isShowSearch
        ? _c("div", {
            staticClass: "header-search",
            on: { click: _vm.showSearch }
          })
        : _vm._e(),
      _c("custom-search", {
        key: "4",
        ref: "searchGroupContacts",
        class: _vm.searchClass,
        attrs: { search: _vm.searchContacts, focus: true, clearable: false },
        on: {
          "update:search": function($event) {
            _vm.searchContacts = $event
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "header-btns circle-animation",
          on: { click: _vm.doHide }
        },
        [_c("i", { staticClass: "fal fa-times" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }