import Vue from 'vue';
import proto from "../../protocol";
import {
    GET_MAIN_TYPE,
    GET_CHAT_SCROLL_UP,
    IS_OWN_CHAT,
    IS_NOTE_CHAT,
    GET_CHAT_EDIT_MESSAGE_ID,
    GET_CHATT_REPLY_MESSAGE_ID,
    GET_CHAT_SELECTED_MSG_ENTITIES,
    GET_CHAT_SEARCH_STRING,
    GET_CHAT_MEDIA_SEARCH_STRING,
    GET_CHAT_ITEM_TEXT,
    GET_MERGED_CONTACT_BY_ID,
    GET_SELECT_MODE,
    GET_SELECTED_MSGS,
    GET_MESSAGE_BY_ID,
    GET_CHAT_MESSAGES,
} from '../gettersTypes'
import {
    MUT_SELECTED_MESSAGE,
    MUT_CHAT_SCROLL_UP,
    MUT_SET_MSG_ENTITIES,
    MUT_CHAT_SET_SEARCH,
    MUT_CHAT_SET_MEDIA_SEARCH,
    MUT_SET_SELECT_MODE,
    MUT_SET_SELECTED_MSGS,
    MUT_DELETE_SELECTED_MSGS,
} from '../mutationsTypes';
import {
    ACT_OPEN_CHAT,
    ACT_CHAT_SCROLL_UP,
    ACT_CHAT_UPDATE_EDITED,
    ACT_CHAT_UPDATE_REPLY,
    ACT_CHAT_REMOVE_MSG,
    ACT_CHAT_CHANGE_MSG_TEXT,
    ACT_CHAT_GET_MESSAGE_BY_ID,
    ACT_SET_CHAT_DRAFT,
    ACT_CHAT_SET_MSG_ENTITIES,
    ACT_CHAT_SET_SEARCH,
    ACT_CHAT_SET_MEDIA_SEARCH,
    ACT_OPEN_CUSTOM_NOTIFICATION,
    ACT_SET_CHAT_MARKED,
    ACT_CHAT_ADD_MESSAGE,
    ACT_CHAT_UPDATE_MESSAGE,
    ACT_UPDATE_SEARCHED_CONTACT,
    ACT_SET_SELECT_MODE,
    ACT_SET_SELECTED_MSGS,
    ACT_DELETE_SELECTED_MSGS,
} from '../actionsTypes'
import {
    CONTENT_MANAGER,
    CHATS,
    NOTIFICATIONS,
    CONTACTS,
} from '../modulesNames'

import { SELECTED_MSG_TYPES } from '../../constants'
import moment from 'moment';
import {i18n} from '../../../ext/i18n'
import { CHAT_MAIN_TYPES } from './content-manager'
const locale = i18n.messages[i18n.locale]

function isSameChat (obj, obj2) {
    return obj.cid === obj2.cid && obj.cidType === obj2.cidType
}

function getMessageById(messages, message_id){
    return messages.find(message => {
        return message.id === message_id
    })
}

function convertTimeMsg(msg) {
    let time = {}
    time.moment = moment()
    if('time' in msg && typeof msg.time === 'number') {
        time.sentDate = moment().subtract(msg.time, 'seconds').format('DD.MM.YYYY')
        time.sentTime = moment().subtract(msg.time, 'seconds').format('HH:mm')
        time.fullTime = moment().subtract(msg.time, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    }
    if('receivedTime' in msg && typeof msg.receivedTime === 'number') {
        time.receivedDate = moment().subtract(msg.receivedTime, 'seconds').format('DD.MM.YYYY')
        time.receivedTime = moment().subtract(msg.receivedTime, 'seconds').format('HH:mm')
    }
    if('watchedTime' in msg && typeof msg.watchedTime === 'number') {
        time.watchedDate = moment().subtract(msg.watchedTime, 'seconds').format('DD.MM.YYYY')
        time.watchedTime = moment().subtract(msg.watchedTime, 'seconds').format('HH:mm')
    }
    if('editedTime' in msg && typeof msg.editedTime === 'number') {
        time.editedDate = moment().subtract(msg.editedTime, 'seconds').format('DD.MM.YYYY')
        time.editedTime = moment().subtract(msg.editedTime, 'seconds').format('HH:mm')
    }
    return time
}

const state = {
    cid: null,
    cidType: null,
    messages: [],
    selectedMsg: null,
    selectedMsgEntities: {},
    poll: {},
    pollVotes: [],
    isUpdating: false,
    scroll_up: false,
    chatSearchString: null,
    mediaSearchString: null,
    isSelectMode: false,
    selectedMessages: [],
}

const getters = {
    isUpdating(state) {
        return state.isUpdating
    },
    [GET_CHAT_MESSAGES](state) {
        return state.messages;
    },
    [GET_MESSAGE_BY_ID](state) {
        return function (message_id) {
            return getMessageById(state.messages, message_id)
        }
    },
    [GET_CHATT_REPLY_MESSAGE_ID](state) {
        return state.selectedMsg && state.selectedMsg.type === SELECTED_MSG_TYPES.REPLY && state.selectedMsg.id
    },
    [GET_CHAT_EDIT_MESSAGE_ID](state) {
        return state.selectedMsg && state.selectedMsg.type === SELECTED_MSG_TYPES.EDITED && state.selectedMsg.id
    },
    getPollVoteById(state) {
        return function (id) {
            let result = false;
            state.pollVotes.forEach(function(item) {
                if(item === id) result = true;
            });
            return result;
        };
    },
    [GET_CHAT_SCROLL_UP](state) {
        return state.scroll_up
    },
    [GET_CHAT_SELECTED_MSG_ENTITIES](state) {
        return state.selectedMsgEntities
    },
    [GET_CHAT_SEARCH_STRING](state) {
        return state.chatSearchString
    },
    [GET_CHAT_MEDIA_SEARCH_STRING](state) {
        return state.mediaSearchString
    },
    [GET_CHAT_ITEM_TEXT]: (state, getters, rootState, rootGetters) => ({message, list = false}) => {
        let text
        switch (message.dataType) {
            case declarations.msgDataTypes.MSG_DATA_TYPE_TEXT:
                text = message.data
                break
            case declarations.msgDataTypes.MSG_DATA_TYPE_DATA:
                if (message.data.pollId) message.data.type = 'poll'
                text = list ? getListDataText(message.data) : getDataText({state, getters, rootState, rootGetters}, message.data)
                break
            case declarations.msgDataTypes.MSG_DATA_TYPE_UNSTORED:
                text = message.data
                break
            case declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM:
                text = getSystemText({state, getters, rootState, rootGetters}, message.data, )
                break
            default:
                text = locale['unknown-data-type']
        }
        return text
    },
    [GET_SELECT_MODE]: (state) => state.isSelectMode,   
    [GET_SELECTED_MSGS]: (state) => state.selectedMessages,
}

const actions = {
    async update ({state, dispatch, commit, rootGetters}, params) {
        let { cid = state.cid, cidType = state.cidType, non_clear } = params
        if (!params.cid) params.cid = cid
        if (!params.cidType) params.cidType = cidType
        let noLoad = rootGetters[`${CHATS}/${IS_OWN_CHAT}`](params) && !rootGetters[`${CHATS}/${IS_NOTE_CHAT}`](params)
        let messages = noLoad ? [] : await proto.getMessages(params)
        if (!non_clear) commit('clearMessages')
        messages.forEach((message) => dispatch(ACT_CHAT_UPDATE_MESSAGE, {
            cid,
            cidType,
            message,
        }))
        commit('updateMessages', {
            cid,
            cidType,
            messages
        });
        return state.messages
    },
    async getMessages({}, params) {
        return proto.getMessages(params)
    },
    async getMessagesMedia({}, params) {
        return proto.getMessagesMedia(params)
    }, 
    async getPublicationsMedia({}, params) {
        return proto.getPublicationsMedia(params)
    },    
    setUpdating({commit}, isUpdating) {
        commit('setUpdating', isUpdating)
    },
    //@todo разобраться и удалить, открывать через chats
    [ACT_OPEN_CHAT]({state, rootGetters, dispatch, commit}, { cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER }) {
        const mainType = rootGetters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`]
        const marked = rootGetters['chats/isMarked']({cid, cidType})
        if (CHAT_MAIN_TYPES.includes(mainType) && cidType === state.cidType && cid === state.cid) return
        commit('acs/setCid', cid, {root: true})
        dispatch('chats/open', {cid, cidType}, {root: true})
        if (marked) dispatch(`${CHATS}/${ACT_SET_CHAT_MARKED}`, {
            cidType,
            cid,
            marked: false,
        }, {root: true})
    },
    [ACT_CHAT_SCROLL_UP]({state, commit}, params) {
        commit(MUT_CHAT_SCROLL_UP, params)
    },
    [ACT_CHAT_SET_MSG_ENTITIES]({state, commit}, msgEntities) {
        commit(MUT_SET_MSG_ENTITIES, msgEntities)
    },
    [ACT_CHAT_UPDATE_EDITED]({state, dispatch, commit}, message) {
        if (!message) message = {}
        let { id = null } = message
        //let { cid, cidType } = state
        commit(MUT_SELECTED_MESSAGE, {type: SELECTED_MSG_TYPES.EDITED, id})
        //dispatch(`${CHATS}/${ACT_SET_CHAT_DRAFT}`, {cid, cidType, draft: {editId}}, {root: true})
    },
    [ACT_CHAT_UPDATE_REPLY]({state, dispatch, commit}, message) {
        if (!message) message = {}
        let { id = null } = message
        //let { cid, cidType } = state
        commit(MUT_SELECTED_MESSAGE, {type: SELECTED_MSG_TYPES.REPLY, id})
        //dispatch(`${CHATS}/${ACT_SET_CHAT_DRAFT}`, {cid, cidType, draft: {replyId}}, {root: true})
    },
    async [ACT_CHAT_REMOVE_MSG]({state, commit}, {msg, everyone}) {
        await proto.setMessageDeleted(msg.id, everyone)
        if (isSameChat(state, msg)) {
            commit('removeMessage', msg.id)
        }
    },
    async [ACT_CHAT_CHANGE_MSG_TEXT]({state, commit, dispatch, getters}, {id, text, newEntities}) {
        let message = getters['getMessageById'](id)
        if (!message) return
        message = JSON.parse(JSON.stringify(message))
        let { dataType, data } = message
        if (message.type === 'text' || dataType === 'text') data = text
        else if (data) {
            data.text = text
            let { entities } = data
            if (entities) data.entities = newEntities
            data = JSON.stringify(message.data)
        } else {
            return
        }
        try {
            await proto.editMessage({id, dataType, data})
            dispatch(ACT_CHAT_UPDATE_EDITED)
        } catch (e) {
            if (e && e.message && e.message === 'dlp-forbidden') {
                dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, {
                    type: 'alert',
                    title: locale['dlp-alert-data-forbidden-title'],
                    subtitle: locale['dlp-alert-data-forbidden-message'],
                }, {root: true})
            }
        }

    },
    async [ACT_CHAT_GET_MESSAGE_BY_ID]({state, getters}, {id}) {
        let msg = getters['getMessageById'](id)
        const { cid, cidType } = state
        if (!msg) {
            try {
                msg = await proto.getMessage({cid, cidType, id})
            } catch (e) {}
        }
        return msg
    },
    [ACT_SET_CHAT_DRAFT]({state, dispatch, getters}, text = '') {
        let { cid, cidType } = state
        let replyId = getters[GET_CHATT_REPLY_MESSAGE_ID]
        let editId = getters[GET_CHAT_EDIT_MESSAGE_ID]
        let draft = {}
        if (text) draft.text = text
        if (replyId) draft.replyId = replyId
        if (editId) draft.editId = editId
        if (!Object.keys(draft).length) draft = null
        dispatch(`${CHATS}/${ACT_SET_CHAT_DRAFT}`, { cid, cidType, draft }, {root: true})
    },
    [ACT_CHAT_SET_SEARCH]({commit}, str) {
        commit(MUT_CHAT_SET_SEARCH, str)
    },
    [ACT_CHAT_SET_MEDIA_SEARCH]({commit}, str) {
        commit(MUT_CHAT_SET_MEDIA_SEARCH, str)
    },
    [ACT_CHAT_ADD_MESSAGE]({commit, dispatch, getters, rootGetters}, payload) {
        const message = payload.message
        commit('addMessage', message)
        if (message.author) {
            let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](message.author)
            if (!contact || !contact.cid) dispatch(`${CONTACTS}/${ACT_UPDATE_SEARCHED_CONTACT}`, {cid: message.author},  {root: true }).catch()
        }
    },
    [ACT_CHAT_UPDATE_MESSAGE]({commit, dispatch, getters, rootGetters}, payload) {
        const message = payload.message
        commit('updateMessage', payload)
        if (message.author) {
            let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](message.author)
            if (!contact || !contact.cid) dispatch(`${CONTACTS}/${ACT_UPDATE_SEARCHED_CONTACT}`, {cid: message.author},  {root: true }).catch()
        }

    },
    [ACT_SET_SELECT_MODE]({commit}, msgIds = []) {
        commit(MUT_SET_SELECT_MODE, msgIds)
    },    
    [ACT_SET_SELECTED_MSGS]({commit}, msgIds = []) {
        commit(MUT_SET_SELECTED_MSGS, msgIds)
    },
    [ACT_DELETE_SELECTED_MSGS]({commit}, msgIds = []) {
        commit(MUT_DELETE_SELECTED_MSGS, msgIds)
    }    
}

const mutations = {
    setUpdating(state, isUpdating) {
        state.isUpdating = isUpdating
    },
    setCid(state, cid) {
        state.cid = cid;
    },
    updateCurrentChat(state, params) {
        if (isSameChat(state, params)) return;
        if ("cid" in params) state.cid = params.cid;
        if ("cidType" in params) state.cidType = params.cidType;
    },
    updateMessages(state) {
        state.messages = state.messages.sort((a, b) => {
            if (isNaN(a.id) || isNaN(b.id) || a.id === b.id) return 0
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
        })
    },
    updateMessage(state, params) {
        let message_id = params.message.id,
            old_message = getMessageById(state.messages, message_id),
            index = old_message && state.messages.indexOf(old_message);

        if (!old_message) {
            params.message.timeDetails = convertTimeMsg(params.message);
            if (isSameChat({cid: state.cid, cidType: state.cidType}, params.message)) state.messages.push(params.message);
        } else {
            Vue.set(state.messages, index, Object.assign(old_message, params.message))
        }
    },
    updateMessageProps(state, params) {
        let message_id = params.message.id,
            time = convertTimeMsg(params.message),
            old_message = getMessageById(state.messages, message_id),
            index = old_message && state.messages.indexOf(old_message);
        if (old_message) {
            Object.assign(old_message.timeDetails, time)
        }
        if (old_message && 'deletedTime' in params.message) {
            state.messages.splice(index, 1);
        } else if (old_message) {
            Vue.set(state.messages, index, Object.assign(old_message, params.message))
        }
    },
    addMessage(state, params) {
        state.messages.push(params);

        // var message_id = params.message.id,
        //     old_message = getMessageById(state.messages, message_id),
        //     index = old_message && state.messages.indexOf(old_message) || -1;
        // if (index === -1) {
        //     if (isSameChat(params, params.message)) state.messages.push(params.message);
        // } else {
        //     Vue.set(state.messages, index, Object.assign(old_message, params.message))
        // }
    },
    removeMessage(state, message_id) {
        var message = getMessageById(state.messages, message_id),
            index = state.messages.indexOf(message);
        if (index !== -1) state.messages.splice(index, 1);
    },
    clearMessages(state) {
        state.messages.splice(0, state.messages.length);
    },
    [MUT_SELECTED_MESSAGE](state, {type, id = null}) {
        let selected = id ? { type, id } : null
        Vue.set(state, 'selectedMsg', selected);
    },
    [MUT_SET_MSG_ENTITIES](state, msgEntities = {}) {
        Vue.set(state, 'selectedMsgEntities', msgEntities)
    },
    addPoll(state, obj) {
        state.poll.id = obj.id;
        state.poll.question = obj.question;
        state.poll.answer = obj.answer;
    },
    updatePollVote(state, pollId) {
        state.pollVotes.push(pollId);
    },
    deletePollVote(state, pollId) {
        state.pollVotes.forEach(function(item, i) {
            if(item === pollId) delete state.pollVotes[i];
        });
    },
    [MUT_CHAT_SCROLL_UP](state, params) {
        state.scroll_up = params
    },
    [MUT_CHAT_SET_SEARCH](state, str) {
        state.chatSearchString = str
    },
    [MUT_CHAT_SET_MEDIA_SEARCH](state, str) {
        state.mediaSearchString = str
    },
    [MUT_SET_SELECT_MODE](state, mode) {
        state.isSelectMode = mode
    },
    [MUT_SET_SELECTED_MSGS](state, msgIds) {
        state.selectedMessages = msgIds
    },
    [MUT_DELETE_SELECTED_MSGS](state, msgIds) {
        msgIds.forEach(m => {
            const index = state.selectedMessages.indexOf(m)
            if (index > -1) state.selectedMessages.splice(index, 1)
        })
    }    
}

function getSystemText ({state, getters, rootState, rootGetters}, data) {
    let editor
    let target
    let text
    switch (data.type) {
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_CREATED:
            editor = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            text = editor.fio + ' ' + locale['created-chat']
            break
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_ADDED:
            editor = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.editorId)
            target = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            text = editor.fio + locale.added + target.fio
            break
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_CHANGED:
            editor = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.editorId)
            target = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            text = editor.fio + locale.changed + target.fio
            break
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_DELETED:
            editor = data.editorId && rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.editorId)
            target = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            text = (editor ? editor.fio + locale.removed + target.fio : target.fio + locale.left)
            break
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_UPDATED:
            editor = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            if ('title' in data && data.title) {
                text = editor.fio + locale['changed-group-name']
            }
            if ('icon' in data && typeof data.icon === 'string') {
                text = editor.fio + locale['update-group-photo']
            }
            if ('pinMessage' in data && data.pinMessage > 0) {
                text = editor.fio + locale['s1-pinned-msg']
            }
            if ('pinMessage' in data && data.pinMessage === 0) {
                text = editor.fio + locale['unpinned-msg']
            }
            if ('readOnly' in data) {
                text = i18n.t(data.readOnly ? 'read-only-on-msg' : 'read-only-off-msg', {name: editor.fio})
            }
            if (!text) text = i18n.t('default-change-msg', {name: editor.fio})
            break
        default:
            text = locale['unknown-sys-msg']
    }
    return text
}

function getDataText ({state, getters, rootState, rootGetters}, data) {
    let text
    switch (data.type) {
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
            if (data.cid) {
                let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
                if (contact) {
                    text = {
                        cid: data.cid,
                        fio: contact.fio,
                        photo: contact.photo,
                    }
                    if (data.file) text = {...text, file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file}
                }
            } else {
                const fields = Array.isArray(data.fields) && data.fields
                const phones = fields && fields.filter(f => f.type === 'phone' || f.type === 'workphone')
                const phone = phones && phones.length && phones[0].value
                const mails = fields && fields.filter(f => f.type === 'mail')
                const mail = mails && mails.length && mails[0].value
                const fio = (fields && fields.fio) || phone || mail
                text = {
                    fio,
                    file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                }
            }

            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
            text = data
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
            text = {
                chId: data.chId,
                pubId: data.pubId,
                text: data.text,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
            text = {
                location_link_url: app.getLocationLinkUrl(data),
                location_image_url: app.getLocationImageUrl(data),
                longitude: data.longitude,
                latitude: data.latitude,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
            text = {
                size: data.size,
                extension: data.extension,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                preview: data.preview,
                name: data.name,
                text: data.text,
                previewFile: data.previewFile,
                previewSize: data.previewSize,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
            text = {
                size: data.size,
                extension: data.extension,
                duration: data.duration,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                preview: data.preview,
                name: data.name,
                text: data.text,
                previewFile: data.previewFile,
                previewSize: data.previewSize,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
            text = {
                size: data.size,
                extension: data.extension,
                duration: data.duration,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                name: data.name,
                text: data.text,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
            text = {
                size: data.size,
                extension: data.extension,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                name: data.name,
                text: data.text,
                readOnly: data.readOnly,
                key: data.key,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SURVEY:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SCHEDULE:
            text = data
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
            text = data.text
            break
        default:
            text = locale['unknown-msg-type']
    }
    return text
}

function getListDataText(data) {
    let text
    switch (data.type) {
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
            text = locale.modal['sent-contact']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
            text = locale.modal['sent-channel']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
            text = locale.modal['sent-post']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
            text = locale.modal['sent-geo']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
            text = locale.modal['sent-img']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
            text = locale.modal['sent-video']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
            text = locale.modal['sent-audio']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
            text = locale.modal['sent-file']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
            text = locale.modal['sent-poll']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
            text = locale.modal['sent-call-availability']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SURVEY:
            text = locale.chat['survey']
            break  
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SCHEDULE:
            text = locale.chat['schedule']
            if (data.eventType === 'invite') text += ': ' + locale.chat['schedule-names'].invite
            else text += ': ' + locale.chat['schedule-names']['appointment-start']
            break                    
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
            text = data.text
            break
        default:
            text = locale['unknown-msg-type']
    }
    return text
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
