var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "select-contact-modal dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("custom-search", {
            ref: "clearSearch",
            staticClass: "search-contact search-input-wrapper",
            attrs: { search: _vm.search, focus: true },
            on: {
              "update:search": function($event) {
                _vm.search = $event
              }
            }
          }),
          _vm.showContactsTabs
            ? _c("custom-tabs", {
                attrs: { tabItems: _vm.tabs },
                on: { tabClick: _vm.onTabClick }
              })
            : _vm._e(),
          _c("select-contact-body", {
            attrs: {
              multiple: _vm.multiple,
              limit: _vm.props.limit,
              excludeMembers: _vm.excludeMembers,
              botsIsVisible: _vm.botsIsVisible,
              filter: _vm.search,
              isLocalContacts: _vm.isLocalContacts,
              searchType: _vm.searchType
            },
            on: {
              selected: _vm.onContactSelected,
              unselected: _vm.onContactUnselected
            }
          })
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.cancelText },
            on: { click: _vm.modalClose }
          }),
          _vm.multiple
            ? _c("modal-btn", {
                attrs: { text: _vm.okText, disabled: !_vm.selected.length },
                on: {
                  click: function($event) {
                    return _vm.onOk(_vm.selected)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }