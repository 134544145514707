'use strict'

import Logger from "./common/Logger"
import event_bus from './eventBus'
import io from 'socket.io-client'
import store from './store/main/store'
import axios from 'axios'
import EventEmitter from "../app/common/EventEmitter"

import { PHONE, USERPROFILE, CHANNELS } from './store/modulesNames'
import { ACT_EVENT_PHONE, ACT_HANDLE_CHANNEL_CHANGE_EVENT, ACT_SET_USER_PROFILE } from './store/actionsTypes'

import { decryptText, encryptText } from './common/Encrypter'

import ipc from '../electron/ipc'

const API_LEVEL = 13

class Proto extends EventEmitter {
    constructor (wsServer) {
        super()
        var self = this
        var socket
        this.socket = socket
        this.logger = new Logger('proto')
        this.sessionKey = null
        this.serverApi = 0
        let log = (str) => {
            this.logger.info(str)
        }

        self.getCompanies = async function () {
            try {
                return (await axios.get('https://firelink.me/get-companies')).data
            } catch (e) {
                console.log(e)
                return []
            }
        }

        // self.setWsServer = function (wsServer) {
        //     server = wsServer
        // }
        /**
         * @param [cb] callback
         */
        let reconnect = true
        self.disconnect = () => new Promise((resolve, reject) => {
            reconnect = false
            if (socket && socket.close) {
                self.sessionKey = null
                socket.close()
                log('Socket disconnect')
            }
            setTimeout(resolve, 100)
        })
        self.server = null
        self.connect = function (server = self.server) {
            return new Promise(async (resolve, reject) => {
                if (!server) reject('Server not specified')
                self.serverApi = 0
                self.server = server
                await self.disconnect()
                socket = io.connect(server, {
                    reconnection: false,
                    withCredentials: true,
                    // transports: ['websocket']
                    // нельзя websocket, т.к. нужны cookie
                })
                this.socket = socket
                socket.on('connect', () => {
                    reconnect = true
                    var date = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
                    const name = 'io'
                    const value = socket.io.engine.id
                    const expires = date.toUTCString()

                    const urlObj = new URL(server)
                    const url = urlObj.protocol + '//' + urlObj.hostname
                    if (window.rpc) window.rpc.setCookie({ url, name, value, expires })
                    log('connect: connected to ' + server)
                    resolve(socket.id)
                    self.emit('connect', socket)
                })

                socket.on('disconnect', function (e) {
                    log('connect: disconnect from server ' + server)
                    self.emit('disconnect', reconnect)
                    reject(e)
                })
                socket.on('connect_error', (e) => {
                    log('connect: error ' + e)
                    self.emit('connect_error', reconnect)
                    reject(e)
                })
                bindSocket()
            })
        }
        self.setProtoSessionKey = (key) => {
            self.sessionKey = key
        }
        self.isEncryptedEvent = (event) => {
            return !['get-public-key', 'set-session-key'].includes(event)
        }
        self.decryptProtoData = async (data) => {
            if (!data) return data
            if (!self.sessionKey) throw Error('session key not set')
            if (!data.encryptedData) throw Error('data not encrypted')
            const decryptedString = await decryptText(self.sessionKey, data.encryptedData)
            return JSON.parse(decryptedString)
        }
        self.encryptProtoData = async (data) => {
            if (!data) return data
            if (!self.sessionKey) throw Error('session key not set')
            const dataString = JSON.stringify(data)
            const encryptedData = await encryptText(self.sessionKey, dataString)
            return { encryptedData }
        }
        function bindSocket () {
            let socketOnOrigin = socket.on
            let socketEmitOrigin = socket.emit

            socket.emit = async (event, data, cb) => {
                let overloadingCb = cb
                if (self.sessionKey && self.isEncryptedEvent(event)) {
                    data = data && await self.encryptProtoData(data)
                    if (overloadingCb) overloadingCb = async (data) => {
                        if (data && !data.error) data = await self.decryptProtoData(data)
                        cb(data)
                    }
                }
                socketEmitOrigin.apply(socket, [event, data, overloadingCb])
            }

            socket.on = (event, cb) => {
                socketOnOrigin.apply(socket, [event, async (data, cbSocket) => {
                    let overloadingCb = cbSocket
                    if (self.sessionKey && self.isEncryptedEvent(event)) {
                        data = data && await self.decryptProtoData(data)
                        if (overloadingCb) overloadingCb = async (data) => cb(await self.encryptProtoData(data))
                    }
                    cb && cb(data, overloadingCb)
                }])
            }

            socket.on('favorites-change-event', favourites => {
                store.dispatch('contacts/changeFavouritesEvent', favourites)
            })
            socket.on('contact-change-event', function (data) {
                log('bind: < contact-change-event: ' + JSON.stringify(data))
                self.emit('contact-change-event', { type: 'global', data })
            })

            socket.on('local-contact-change-event', function (data) {
                log('bind: < contact-change-event: ' + JSON.stringify(data))
                self.emit('contact-change-event', { type: 'local', data })
            })

            socket.on('contact-status-event', function (data) {
                log('bind: < contact-status-event: ' + JSON.stringify(data))
                // Данный event не используется
                // store.commit('contacts/updateContactStatus', data);
            })

            socket.on('contact-busy-event', function (data) {
                log('bind: < contact-busy-event: ' + JSON.stringify(data))
                self.emit('contact-busy-event', data)
            })

            socket.on('history-call-event', function (data) {
                log('bind: < history-call-event: ' + JSON.stringify(data))
                self.emit('history-call-event', data)
            })

            socket.on('history-call-change-event', function (data) {
                log('bind: < history-call-change-event: ' + JSON.stringify(data))
                self.emit('history-call-change-event', data)
            })

            socket.on('chat-change-event', (data) => {
                log('bind: < chat-change-event: ' + JSON.stringify(data))
                addChatDefaultData(data)
                self.emit('chat-change-event', data)
            })

            socket.on('message-event', function (data) {
                log('bind: < message-event: ' + JSON.stringify(data))
                if (addMessageDefaultData(data)) self.emit('message-event', data)
            })

            socket.on('message-change-event', function (data) {
                log('bind: < message-change-event: ' + JSON.stringify(data))
                self.emit('message-change-event', data)
            })

            socket.on('message-update-event', function (data) {
                log('bind: < message-update-event: ' + JSON.stringify(data))
                if (addMessageDefaultData(data)) self.emit('message-update-event', data)
            })

            socket.on('rtc-call-event', function (data) {
                console.log('bind: < rtc-call-event: ' + JSON.stringify(data))
                self.emit('calls-event', {type: 'rtc-call-event', data})
            })

            socket.on('rtc-call-ringing-event', function (data) {
                log('bind: < rtc-call-ringing-event: ' + JSON.stringify(data))
                //ipc.send('proto-call-event', { eventName: 'rtc-call-ringing-event', data })
                store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data: data, eventName: 'rtc-call-ringing-event' })
                self.emit('rtc-call-ringing-event', data)
            })

            socket.on('rtc-call-answer-event', function (data) {
                log('bind: < rtc-call-answer-event: ' + JSON.stringify(data))
                //ipc.send('proto-call-event', { eventName: 'rtc-call-answer-event', data })
                store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data: data, eventName: 'rtc-call-answer-event' })
                self.emit('rtc-call-answer-event', data)
            })

            socket.on('rtc-call-termination-event', function (data) {
                log('bind: < rtc-call-termination-event: ' + JSON.stringify(data))
                // @todo тут был таймаут, мешал схлоповать вызовы для конфы и транзита
                // @todo по-хорошему интервал тоже убрать, можно иными способами узнать о завершении вызова
                // таймаут добавлен по причине описаной в задаче №1242
                // проблема возникает когда евент call-termination приходит быстрей чем создастся
                // окно телефона и класс callWorker.
                self.emit('rtc-call-termination-event', data)
                let attempt = 0
                let interval = setInterval(() => {
                    self.emit('rtc-call-termination-event', data)
                    if (++attempt > 3) clearInterval(interval)
                }, 1000)
            })

            socket.on('rtc-call-processing-event', function (data) {
                log('bind: < rtc-call-processing-event: ' + JSON.stringify(data))
                //ipc.send('proto-call-event', { eventName: 'rtc-call-processing-event', data })
                store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data: data, eventName: 'rtc-call-processing-event' })
                self.emit('rtc-call-processing-event', data)
            })

            socket.on('rtc-call-options-event', function (data) {
                log('bind: < rtc-call-options-event: ' + JSON.stringify(data))
                //ipc.send('proto-call-event', { eventName: 'rtc-call-options-event', data })
                store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data: data, eventName: 'rtc-call-options-event' })
            })

            socket.on('rtc-call-conference-event', (data) => {
                log('bind: < rtc-call-conference-event: ' + JSON.stringify(data))
                self.emit('rtc-call-conference-event', data)
            })

            socket.on('voicemail-messages-event', function (data) {
                log('bind: < voicemail-messages-event: ' + JSON.stringify(data))
                self.emit('voicemail-messages-event', data)
            })

            socket.on('publication-event', function (data) {
                log('bind: < publication-event: ' + JSON.stringify(data))
                self.emit('publication-event', data)
            })

            socket.on('channel-change-event', function (data) {
                log('bind: < channel-change-event: ' + JSON.stringify(data))
                addChannelDefaultData(data)
                store.dispatch(`${CHANNELS}/${ACT_HANDLE_CHANNEL_CHANGE_EVENT}`, data)
            })

            socket.on('qr-login-data-event', function (data) {
                log('bind: < qr-login-data-event: ' + JSON.stringify(data))
                event_bus.$emit('qr-login-data-event', data)
            })

            socket.on('set-chat-keyboard', function (data) {
                log('bind: < set-chat-keyboard: ' + JSON.stringify(data))
                store.commit('chats/updateKeyboard', data)
            })

            socket.on('conference-event', (data) => {
                log('bind: < conference-event: ' + JSON.stringify(data))
                self.emit('calls-event', {type: 'conference-event', data})
            })
            
            socket.on('conference-ringing-event', (data) => {
                log('bind: < conference-ringing-event: ' + JSON.stringify(data))
                self.emit('conference-ringing-event', data)
            })

            socket.on('conference-answer-event', (data) => {
                log('bind: < conference-answer-event: ' + JSON.stringify(data))
                self.emit('conference-answer-event', data)
            })

            socket.on('conference-processing-event', (data) => {
                log('bind: < conference-processing-event: ' + JSON.stringify(data))
                self.emit('conference-processing-event', data)
            })

            socket.on('conference-termination-event', (data) => {
                log('bind: < conference-termination-event: ' + JSON.stringify(data))
                self.emit('conference-termination-event', data)
            })

            const onAssistantCallInfoEvent = (data) => {
                log(`bind: < : assistant-call-info-event ${JSON.stringify(data)}`)
                self.emit('assistant-call-info-event', data)
            }
            socket.on('call-info-event', onAssistantCallInfoEvent)
            socket.on('assistant-call-info-event', onAssistantCallInfoEvent)

            const onAssistantCallsInfoEvent = (data) => {
                log(`bind: < : assistant-calls-info-event ${JSON.stringify(data)}`)
                self.emit('assistant-calls-info-event', data)
            }

            socket.on('calls-info-event', onAssistantCallsInfoEvent)
            socket.on('assistant-calls-info-event', onAssistantCallsInfoEvent)

            const onAssistantTaInfoEvent = (data) => {
                log(`bind: < : assistant-ta-info-event ${JSON.stringify(data)}`)
                self.emit('assistant-ta-info-event', data)
            }

            socket.on('ta-info-event', onAssistantTaInfoEvent)
            socket.on('assistant-ta-info-event', onAssistantTaInfoEvent)

            socket.on('assistant-conference-event', (data) => {
                log(`bind: < : assistant-conference-event: ${JSON.stringify(data)}`)
                self.emit('assistant-conference-event', data)
            })

            socket.on('profile-event', (data) => {
                log('bind: < profile-event: ' + JSON.stringify(data));
                self.emit('profile-event', data);
                store.dispatch(`${USERPROFILE}/${ACT_SET_USER_PROFILE}`, data);
            })

            socket.on('tokens-event', (data) => {
                log('bind: < tokens-event: ' + JSON.stringify(data))
                self.emit('tokens-event', data)
            })

            socket.on('role-revision-change-event', (data) => {
                log('bind: < role-revision-change-event: ' + JSON.stringify(data))
                console.log('bind: < role-revision-change-event: ', JSON.stringify(data))
                self.emit('role-revision-change-event', data)
                store.dispatch('contacts/changeRolesRevisionEvent', data)
            })

            socket.on('contact-connection-change-event', (data) => {
                log('bind: < contact-connection-change-event: ' + JSON.stringify(data))
                console.log('bind: < contact-connection-change-event: ', JSON.stringify(data))
                self.emit('contact-connection-change-event', data)
                store.dispatch('contacts/contactConnectionChangeEvent', data)
            })
        }

        self.getPublicKey = () => new Promise(async (resolve, reject) => {
            log('getPublicKey: >')
            let data
            try {
                data = await self._emitWithTimeOut('get-public-key', null)
            } catch (e) {
                log('getPublicKey: < error ' + e.message)
                reject(e)
            }
            if (data.error) {
                log('getPublicKey: < error: ' + data.error)
                reject(data.error)
            } else {
                log('getPublicKey: < length ' + data.publicKey && data.publicKey.length || 0)
                resolve(data.publicKey)
            }
        })

        self.setSessionKey = (encSessionKey) => new Promise(async (resolve, reject) => {
            log(`setSessionKey: > ${ encSessionKey }`)
            let data
            try {
                data = await self._emitWithTimeOut('set-session-key', { encSessionKey })
            } catch (e) {
                log('setSessionKey: < error: ' + e.message)
                reject(e)
            }
            if (data && data.error) {
                log('setSessionKey: < error: ' + data.error)
                reject(data.error)
            } else {
                log('setSessionKey: < ok')
                resolve()
            }
        })

        self.addAcsRecord = (record, ext) => {
            return new Promise((resolve, reject) => {
                log(`bind: > add-acs-record: ${JSON.stringify(record)}, ${JSON.stringify(ext)}`)
                socket.emit('add-acs-record', { record, ext }, data => {
                    if (data.result) {
                        log(`bind: < add-acs-record success`)
                        resolve()
                    } else {
                        log(`bind: < add-acs-record failed`)
                        reject()
                    }
                })
            })
        }

        self.removeAcsRecord = (recId, from, to, ext = {}) => {
            return new Promise((resolve, reject) => {
                if (!!recId) {
                    log(`bind: > delete-acs-record by recId: ${recId}`)
                    socket.emit("delete-acs-record", { recId, ext },
                        data => {
                            if (data.result) {
                                resolve();
                                log(`bind: < delete-acs-record by recId success`);
                            } else {
                                log(`bind: < delete-acs-record by recId failed`);
                                reject();
                            }
                        }
                    )
                }
                else {
                    log(`bind: > delete-acs-record: ${from} - ${to}`)
                    socket.emit("delete-acs-record", { from, to, ext },
                        data => {
                            if (data.result) {
                                resolve();
                                log(`bind: < delete-acs-record success`);
                            } else {
                                log(`bind: < delete-acs-record failed`);
                                reject();
                            }
                        }
                    )
                }
            })
        }

        self.checkOmLogin = (login, password) => {
            return new Promise((resolve, reject) => {
                log(`bind: > check-om-login`)
                socket.emit('check-om-login', { login, password }, data => {
                    if (data.result) {
                        log(`bind: < check-om-login success`)
                        resolve()
                    } else {
                        log(`bind: < check-om-login failed`)
                        reject()
                    }
                })
            })
        }

        self.getAcs = contact_id => {
            return new Promise((resolve, reject) => {
                log('bind: > get acs info')
                socket.emit('get-acs-info', contact_id, (data) => {
                    resolve(data)
                })
            })
        }

        self.getAcsContacts = () => {
            return new Promise((resolve, reject) => {
                socket.emit('get-acs-contacts', null, (contact_ids) => {
                    resolve(contact_ids)
                })
            })
        }

        self.login = function ({login, password, silentMode, oauth2 }) {
            return new Promise((resolve, reject) => {
                let data = {};
                if (silentMode) data = { login: login, password: password, APILevel: API_LEVEL, silentMode: silentMode, oauth2 }
                else data = { login: login, password: password, APILevel: API_LEVEL, oauth2 }
                if (socket && !socket.disconnected) {
                    log('login: > ' + (oauth2 ? 'with tokens' : login))
                    socket.emit('login', data, function (data) {
                        log('login: < ' + JSON.stringify(data))
                        if (data.error) {
                            if (data.error && data.status === 'relogin-required') reject(data.status)
                            else reject(data.error)
                        } else {
                            if (data.APILevel) self.serverApi = data.APILevel
                            resolve(data)
                        }
                    })
                } else {
                    self.emit('disconnect')
                    reject('not-connected')
                }
            })
        }

        self.logout = function () {
            log('logout: >')
            reconnect = false
            socket.emit('logout')
        }

        self.sendLoginPin = function (pin) {
            return new Promise((resolve, reject) => {
                socket.emit('send-login-pin', { 'pin': pin }, function (data) {
                    if (data.error) {
                        reject(data.error)
                    } else resolve(data)
                })
            })
        }

        self.requestLoginPin = function (pin) {
            return new Promise((resolve, reject) => {
                socket.emit('request-login-pin', { 'pin': pin }, function (data) {
                    if (data.error) {
                        reject(data.error)
                    } else resolve(data)
                })
            })
        }

        self.sendPassRestoreMail = function (mail) {
            return new Promise((resolve, reject) => {
                log('passRestore: >')
                if (socket && !socket.disconnected) {
                    socket.emit('send-pass-restore-mail', { mail: mail }, function (data) {
                        log('passRestore: < ' + JSON.stringify(data))
                        if (data.error) {
                            reject(data.error)
                        }
                        return resolve(data)
                    })
                } else {
                    self.emit('disconnect')
                    reject('not-connected')
                }
            })
        }

        self.getUserInfo = function (cb) {
            log('getUserInfo: >')
            socket.emit('get-user-info', null, function (data) {
                log('getUserInfo: < ' + JSON.stringify(data))
                return cb && cb(data)
            })
        }

        self.getUserParams = () => new Promise((resolve, reject) => {
            log("get-user-params: >");
            socket.emit('get-user-params', null, (data) => {
                if (!data) {
                    log('getUserParams: < failed')
                    return reject()
                }
                log("get-user-params: < " + JSON.stringify(data));
                resolve(data)
            })
        })

        self.getTurnServer = function (cb) {
            return new Promise((resolve, reject) => {
                log('getTurnServer: >')
                socket.emit('get-turn-server', null, function (data) {
                    log('getTurnServer: < ' + JSON.stringify(data))
                    resolve(data)
                    return cb && cb(data)
                })
            })
        }

        self.setUserPhone = data => {
            console.log('set-user-phone: >', data)
            socket.emit('set-user-phone', data, ({error}) => console.log(`set-user-phone: < ${error}`))
        }

        self.getRoleRevision = () => new Promise((resolve, reject) => {
            log('RoleRevision: > ')
            socket.emit('get-role-revision', null, response => {
                log('get-role-revision: < ' + response)
                resolve(response)
            })
        })

        self.getContacts = global => new Promise((resolve, reject) => {
            const data = { type: global ? 'global' : 'local' }
            log('getContacts: > ' + JSON.stringify(data))
            socket.emit('get-contacts', data, response => {
                log('getContacts: < ' + response.length)
                resolve(response)
            })
        })

        self.getContact = params => new Promise((resolve, reject) => {
            log('getContact: > ' + JSON.stringify(params))
            socket.emit('get-contact', params, response => {
                log('getContact: < ' + JSON.stringify(response))
                resolve(response)
            })
        })

        self.getContactsStatus = async function(params) {
            log('getContactsStatus: >')
            try {
                let response = await this._emitWithTimeOut('get-contacts-status', [params])
                log('getContactsStatus: < ' + response.length)
                return response
            } catch (e) {
                log('getContactsStatus: < error ' + e.message)
                return [{"cid": params, "status": {"statusTime": -1}}]
            }
        }

        self.setContactsStatus = function (params) {
            log('setContactsStatus: > ' + params)
            socket && socket.emit('set-contact-status', { status: params })
        }

        self.getBusyContacts = () => new Promise((resolve, reject) => {
            log('getBusyContacts: >')
            socket.emit('get-busy-contacts', null, response => {
                log('getBusyContacts: < ' + response.length)
                resolve(response)
            })
        })

        self.changeContact = data => new Promise((resolve, reject) => {
            log('changeContact: >')
            socket.emit('change-contact', data, response => {
                if (response.error) {
                    log('changeContact error: ' + response.error)
                    return reject(response.error)
                }
                log('changeContact: < rev - ' + response.rev)
                resolve(response.rev)
            })
        })

        self.addContact = data => new Promise((resolve, reject) => {
            log('addContact: >')
            socket.emit('add-contact', data, (response = {}) => {
                let {error, cid} = response
                if (error) {
                    log('addContact error: ' + error)
                    return reject(error)
                }
                log('addContact: < cid - ' + cid)
                resolve(cid)
            })
        })

        self.searchContacts = data => new Promise((resolve, reject) => {
            log('search-contacts: > ' + JSON.stringify(data))
            socket.emit('search-contacts', data, (response = {}) => {
                let { error } = response
                if (error) {
                    console.log("!! -> file: protocol.js -> line 634 -> socket.emit -> error", error)
                    log('search-contacts error: ' + error)
                    return reject(error)
                }
                log('searchContacts: < response.length - ' + response.length)
                resolve(response)
            })
        })

        self.getContactsOrganizationFilter = () => new Promise((resolve, reject) => {
            log('get-contacts-organization-filter: >')
            socket.emit('get-contacts-organization-filter', null, (response) => {
                if (response?.error) {
                    log('get-contacts-organization-filter error: ' + response?.error)
                    return reject(response?.error)
                }
                log('get-contacts-organization-filter: < ' + response.length)
                resolve(response)
            })
        })

        self.addContactConnections = data => new Promise((resolve, reject) => {
            log('add-contact-connections: >' + data)
            socket.emit('add-contact-connections', data, (response = {}) => {
                let { error } = response
                if (error) {
                    log('add-contact-connections error: ' + error)
                    return reject(error)
                }
                log('add-contact-connections: < ')
                resolve(response)
            })
        })

        self.deleteContact = data => new Promise((resolve, reject) => {
            log('deleteContact: >')
            socket.emit('delete-contact', data, response => {
                log('addContact: < ' + response.length)
                resolve(response)
            })
        })

        self.getBirthdays = data => new Promise((resolve, reject) => {
            log('get-birthdays: >')
            socket.emit('get-birthdays', data, response => {
                log('get-birthdays: < ')  //+ response)
                resolve(response)
            })
        })

        self.addOptions = function (options) {
            log('addOptions: ' + JSON.stringify(options))
            socket.emit('add-options', options)
        }

        self.getHistoryCalls = function (params, cb) {
            var data = { startId: params.startId, count: params.count, minId: params.minId }
            log('getHistoryCalls: > ' + JSON.stringify(data))
            socket.emit('get-history-calls', data, function (data) {
                log('getHistoryCalls: < ' + data.length)
                return cb && cb(data)
            })
        }

        self.setHistoryCallStatus = function (params, cb) {
            log('setHistoryCallStatus: > ' + JSON.stringify(params))
            socket.emit('set-history-call-status', params, function (data) {
                log('setHistoryCallStatus: < ' + data.length)
                return cb && cb(data)
            })
        }

        /**
         * @param params {Object}
         * @param [params.cidType='user'] {String}
         * @param params.cid {Number}
         * @param [params.dataType='text']
         * @param [params.data] {String}
         * @param [params.dataFile] {String}
         * @param cb
         */
        self.sendMessage = function (params, cb) {
            // var data = { cidType: params.cidType, cid: params.cid, dataType: params.dataType, data:  params.data, dataFile: params.dataFile, replyId: params.replyId }; //@todo здесь в строку преоброзововать
            log('sendMessage: > ' + JSON.stringify(params))
            socket.emit('send-message', params, function (data) {
                log('sendMessage: < ' + JSON.stringify(data))
                return cb && cb(data)
            })
        }

        self.sendMessageAsync = (params) => new Promise((resolve, reject) => self.sendMessage(params, resolve))

        /**
         * @param cidType {String}
         * @param cid {Number}
         */
        self.sendTypingEvent = function (cidType, cid) {
            let dataType = 'unstored'
            let data = { cidType, cid, dataType, data: JSON.stringify({ 'type': 'message-writing' }) }
            log('sendTyping: > ' + JSON.stringify(data))
            socket.emit('send-message', data, function (data) {
                log('sendTyping: < ' + JSON.stringify(data))
            })
        }

        self.getMessages = (params) => new Promise((resolve, reject) => {
            let data = { cidType: params.cidType, cid: params.cid, startId: params.startId, count: params.count, minId: params.minId }
            log('getMessages: > ' + JSON.stringify(data))
            socket.emit('get-messages', data, (data) => {
                log('getMessages: < ' + data.length)
                data.forEach((cur, index) => {
                    if (!addMessageDefaultData(cur)) delete data[index]
                })
                resolve(data)
            })
        })
        
        self.getMessagesMedia = (params) => new Promise((resolve, reject) => {
            let data = { cidType: params.cidType, cid: params.cid, type: params.type,  filter: params.filter, startId: params.startId, count: params.count }
            log('getMessagesMedia: > ' + JSON.stringify(data))
            socket.emit('get-messages-media', data, (data) => {
                log('getMessagesMedia: < ' + data.length)
                data.forEach((cur, index) => {
                    if (!addMessageDefaultData(cur)) delete data[index]
                })
                resolve(data)
            })
        })

        self.getPublicationsMedia = (params) => new Promise((resolve, reject) => {
            let data = { chId: params.chId, type: params.type,  filter: params.filter, fromPubId: params.fromPubId, count: params.count }
            log('getPublicationsMedia: > ' + JSON.stringify(data))
            socket.emit('get-publications-media', data, (data) => {
                log('getPublicationsMedia: < ' + data.length)
                data.forEach((cur, index) => {
                    if (!addMessageDefaultData(cur)) delete data[index]
                })
                resolve(data)
            })
        })

        self.getMessage = (params) => new Promise((resolve, reject) => {
            let data = { cidType: params.cidType, cid: params.cid, id: params.id }
            log('getMessage: > ' + JSON.stringify(params))
            socket.emit('get-message', data, (data) => {
                log('getMessage: < ' + JSON.stringify(data))
                if (!addMessageDefaultData(data)) data = null
                resolve(data)
            })
        })

        self.getMessageDetails = function (params, cb) {
            var data = { cidType: 'group', cid: params.cid, id: params.id }
            log('getMessageDetails: > ' + JSON.stringify(data))
            data && socket.emit('get-message-details', data, function (data) {
                log('getMessageDetails: < ' + data.length)
                return cb && cb(data)
            })
        }

        self.setMessageDeleted = (id, everyone) => new Promise((resolve, reject) => {
            let data = { id: id }
            if (everyone) data.everyone = everyone
            log('setMessageDeleted: > ' + JSON.stringify(data))
            socket.emit('set-message-deleted', data, function (data) {
                log('setMessageDeleted: < ' + JSON.stringify(data))
                resolve(data)
            })
        })

        self.editMessage = (payload) => new Promise((resolve, reject) => {
            log('editMessage: > ' + JSON.stringify(payload))
            socket.emit('edit-message', payload, function (data) {
                let { rev, error } = data || {}
                if (error) {
                    log('editMessage: < edit-message error: ' + error)
                    return reject(new Error(error))
                }
                log('edit-message: < rev: ' + rev)
                resolve(rev)
            })
        })

        self.setMessageReceived = (id) => new Promise((resolve, reject) => {
            const data = { id }
            log('setMessageReceived: > ' + JSON.stringify(data))
            socket.emit('set-message-received', data, function (data) {
                if (!data || !data.rev) {
                    log('setMessageReceived: < set-message-received error: ' + JSON.stringify(data))
                    return reject()
                }
                log('setMessageReceived: < ' + JSON.stringify(data))
                resolve(data.rev)
            })
        })

        self.setMessageWatched = function (id, cb) {
            var data = { id: id }
            log('setMessageWatched: > ' + JSON.stringify(data))
            socket.emit('set-message-watched', data, function (data) {
                log('setMessageWatched: < ' + JSON.stringify(data))
                return cb && cb(data)
            })
        }

        self.setMessageWatchedAsync = (id) => new Promise((resolve, reject) => {
            let data = { id }
            log('setMessageWatched: > ' + JSON.stringify(data))
            socket.emit('set-message-watched', data, function (data) {
                log('setMessageWatched: < ' + JSON.stringify(data))
                resolve(data)
            })
        })

        self.getChats = (payload) => new Promise((resolve, reject) => {
            const data = { startMessageId: payload.startMessageId, count: payload.count, minMessageId: payload.minMessageId }
            log('getChats: > ' + JSON.stringify(data))
            socket.emit('get-chats', data, (data) => {
                log('getChats: < ' + data.length)
                data.forEach((cur) => addChatDefaultData(cur))
                resolve(data)
            })
        })

        self.addChat = ({ name, icon, contacts }) => new Promise((resolve, reject) => {
            const data = { contacts: contacts, icon: icon, name: name }
            const _cb = function (res) {
                if (!res.cid) {
                    log('addChat: < add-chat error: ' + JSON.stringify(res))
                    return reject()
                }
                /* app.store.commit('changeChat', {
                    cid: res.cid,
                    cidType: 'group',
                    name: name,
                    icon: icon
                }); */
                resolve(res.cid)
            }
            log('addChat: > contacts = ' + JSON.stringify(contacts) + ', name = ' + name + ', icon = ' + icon)
            socket.emit('add-chat', data, _cb)
        })

        /**
         * @param {Object} params
         * @param {String} params.cidType
         * @param {Number} params.cid
         * @param {String} [params.name]
         * @param {String} [params.icon]
         * @param {Array} [params.addContacts] {cid, privilege}
         * @param {Array} [params.deleteContacts] {cid}
         */

        self.changeChat = (params) => new Promise((resolve, reject) => {
            if (!params.cidType || !('cid' in params)) throw new Error('No cidType or cid in params')
            let data = {}
            for (let param in params) data[param] = params[param]
            log('changeChat: > ' + JSON.stringify(data))
            socket.emit('change-chat', data, (data) => {
                if (!data.rev) {
                    log('changeChat: < change-chat error')
                    return reject()
                }
                log('changeChat: < rev:' + data.rev)
                resolve(data.rev)
            })
        })

        /**
         * @param cidType
         * @param cid
         * @param contacts
         */
        self.addToChat = (cidType, cid, contacts) => new Promise((resolve, reject) => {
            // for (var i = 0; i < contacts.length; ++i) contacts[i] = { cid:+contacts[i], privilege:"admin" };
            var data = { cidType: cidType, cid: cid, addContacts: contacts }
            log('addToChat > ' + JSON.stringify(data))
            socket.emit('change-chat', data, function (data) {
                if (!data.rev) {
                    log('addToChat < error')
                    return reject()
                }
                log('addToChat < rev:' + data.rev)
                resolve(data.rev)
            })
        })
        /**
         * @param cidType
         * @param cid
         * @param contacts
         */
        self.delFromChat = (cidType, cid, contacts) => new Promise((resolve, reject) => {
            // for (var i = 0; i < contacts.length; ++i) contacts[i] = { cid:+contacts[i] };
            var data = { cidType: cidType, cid: cid, deleteContacts: contacts }
            log('delFromChat > ' + JSON.stringify(data))
            socket.emit('change-chat', data, function (data) {
                if (!data.rev) {
                    log('delFromChat < error')
                    return reject()
                }
                log('delFromChat < rev:' + data.rev)
                resolve(data.rev)
            })
        })

        /**
         * @param cidType {String}
         * @param cid {Number}
         */
        self.deleteChat = (cidType, cid) => new Promise((resolve, reject) => {
            const data = { cidType, cid }
            log('deleteChat > ' + JSON.stringify(data))
            socket.emit('delete-chat', data, (data) => {
                if (data.result) {
                    log('deleteChat < success')
                    resolve()
                } else {
                    log('deleteChat < error')
                    reject()
                }
            })
        })

        /**
         * @param cidType {String}
         * @param cid {Number}
         */
        self.exitChat = (cidType, cid) => new Promise((resolve, reject) => {
            const data = { cidType: cidType, cid: cid }
            log('exitChat > ' + JSON.stringify(data))
            socket.emit('exit-chat', data, (data) => {
                if (data.error) {
                    log('exitChat < error: ' + data.error)
                    return reject(new Error(data.error))
                }
                log('exitChat < result:' + data.result)
                resolve(data.result)
            })
        })

        self.addPoll = function (cb) {
            socket.emit('add-poll', null, function (data) {
                return cb && cb(data)
            })
        }

        self.givePollVote = function (params, cb) {
            socket.emit('give-poll-vote', params, function (data) {
                return cb && cb(data)
            })
        }

        self.getPollResult = params => {
            return new Promise(resolve => {
                socket.emit('get-poll-result', params, data => {
                    resolve(data)
                })
            })
        }

        self.getFavouritesList = () => {
            return new Promise(resolve => {
                // socket.emit('get-favorites-list', null, favorites => {resolve(favorites);}); //DEPRECATED
                socket.emit('get-favorites', null, favourites => {
                    // Андроидяне сохраняют cid с типом string, а мы с number это для совместимости
                    for (let i = 0; i < favourites.length; i++) favourites[i].cid = +favourites[i].cid
                    resolve(favourites)
                })
            })
        }

        self.setFavouritesList = favourites => {
            return new Promise((resolve, reject) => {
                if (favourites.constructor !== Array) {
                    reject('incorrect data')
                    return
                }
                // Андроидяне сохраняют cid с типом string, а мы с number это для совместимости
                favourites = favourites.map(({cid}) => ({cid: '' + cid}))
                socket.emit('set-favorites', favourites, result => {
                    log(`setFavouritesList > ${favourites.length}`)
                    if (result) resolve()
                    else reject('Error')
                })
            })
        }

        self.getChatsChanges = (minRev) => new Promise((resolve, reject) => {
            const cb = (data) => {
                log('getChatsChanges < ' + data.length)
                data.forEach((cur) => addChatDefaultData(cur))
                resolve(data)
            }
            log('getChatsChanges > minRev = ' + minRev)
            socket.emit('get-chats-changes', { minRev }, cb)
        })

        self.getChatsLastRevision = () => new Promise((resolve, reject) => {
            const cb = (data) => {
                if (!('rev' in data)) {
                    log('getChatsLastRevision < error')
                    return reject()
                }
                log('getChatsLastRevision < rev:' + data.rev)
                resolve(data.rev)
            }
            log('getChatsLastRevision >')
            socket.emit('get-chats-last-revision', null, cb)
        })

        self.getUnwatchedMessages = function (msgIds, cb) {
            var data = msgIds
            log('getUnwatchedMessages: > ' + JSON.stringify(data))
            socket.emit('get-unwatched-messages', data, function (data) {
                log('getUnwatchedMessages:  < ' + JSON.stringify(data))
                data.forEach(function (cur) { addMessageDefaultData(cur) })
                cb && cb(data)
            })
        }

        self.getUnwatchedMessagesAsync = (msgIds) => new Promise((resolve, reject) => {
            let data = msgIds
            log('getUnwatchedMessagesAsync: > ' + JSON.stringify(data))
            socket.emit('get-unwatched-messages', data, (data) => {
                log('getUnwatchedMessagesAsync:  < ' + JSON.stringify(data))
                data.forEach( (cur) => addMessageDefaultData(cur) )
                resolve(data)
            })
        })

        function addChatDefaultData (chat) {
            if (!('cidType' in chat)) chat.cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER
            if (chat.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP && !chat.contacts) chat.contacts = []
            try {
                if (chat.lastMessage && chat.lastMessage.dataType && chat.lastMessage.dataType === 'data') chat.lastMessage.data = JSON.parse(chat.lastMessage.data)
                return true
            } catch (e) {
                log('addChatDefaultData: < error: invalid data: ' + chat.lastMessage.data)
                chat.lastMessage.data = {}
                return false
            }
        }

        function addMessageDefaultData (msg) {
            if (!msg) return false
            if (!('cidType' in msg)) msg.cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER
            try {
                if (msg.dataType && (msg.dataType === 'data' || msg.dataType === 'unstored')) {
                    msg.data = JSON.parse(msg.data)
                }
                return true
            } catch (e) {
                log('addMessageDefaultData: < error: invalid data: ' + msg.data)
                return false
            }
        }

        //* *** Channels proto start ****//

        self.addChannel = (params) => new Promise((resolve, reject) => {
            const data = { chType: params.chType, name: params.name, info: params.info }
            if (params.icon) data.icon = params.icon
            log('addChannel: >' + JSON.stringify(data))
            socket.emit('add-channel', data, (data) => {
                if (data.error) {
                    log('addChannel error: ' + data.error)
                    return reject()
                }
                log('addChannel: < add-channel success, chId:' + data.chId)
                resolve(data.chId)
            })
        })

        self.changeChannel = (params) => new Promise((resolve, reject) => {
            const data = { chId: params.chId, chType: params.chType }
            if (params.name) data.name = params.name
            if (params.info) data.info = params.info
            if (typeof params.icon === 'string') data.icon = params.icon
            if (params.settings) data.settings = params.settings
            log('changeChannel: > ' + JSON.stringify(data))
            socket.emit('change-channel', data, (err) => {
                if (err && err.error) {
                    log('changeChannel < error: ' + err.error)
                    return reject(err.error)
                }
                log('changeChannel: < success')
                resolve()
            })
        })

        /* self.changeChannelSettings = function (params, cb) {
          var data = {chId: params.chId, settings: params.settings || {}};
          log('changeChannelSettings: > ' + JSON.stringify(data));
          socket.emit('change-channel', data, function(err) {
          if (err && err.error) {
          return log('changeChannelSettings < error: ' + err.error);
          }
          log('changeChannelSettings: < success');
          cb && cb();
          });
          }; */

        self.delChannel = (params) => new Promise((resolve, reject) => {
            const data = { chId: params.chId }
            log('delChannel: > ' + JSON.stringify(data))
            socket.emit('delete-channel', data, (err) => {
                if (err && err.error) {
                    log('delChannel: < error: ' + err.error)
                    return reject(err.error)
                }
                log('delChannel < success')
                resolve()
            })
        })

        self.getChannelDetails = (params, cb) => {
            return new Promise((resolve, reject) => {
                var data = { chId: params.chId }
                log('getChannelDetails: > ' + JSON.stringify(data))
                socket.emit('get-channel-details', data, channel => {
                    if (channel.error) reject('getChannelDetails: < error: ' + channel.error) // @todo как-то не очень
                    channel.chId = params.chId
                    log('getChannelDetails: < success')
                    resolve(channel)
                })
            })
        }

        self.getChannelUsers = function (params) {
            return new Promise((resolve, reject) => {
                const data = { chId: params.chId }
                if (params.privilege) data.privilege = params.privilege
                log('getChannelUsers: > ' + JSON.stringify(data))
                socket.emit('get-channel-users', data, function (users) {
                    if (users) {
                        log('getChannelUsers: < users length - ' + users.length)
                        resolve(users)
                    } else {
                        log('getChannelUsers: < error')
                        reject()
                    }
                })
            })
        }

        self.getChannels = params => new Promise((resolve, reject) => {
            const data = { minRev: params.minRev, count: params.count }
            log('getChannels: > ' + JSON.stringify(data))
            socket.emit('get-channels', data, response => {
                log('getChannels: < rev - ' + response.rev + ' length - ' + response.channels.length)
                resolve(response)
            })
        })

        self.searchChannels = function (params, cb) {
            return new Promise((resolve, reject) => {
                let data = { filter: params.filter, shift: params.shift, count: params.count }
                log('searchChannels: > ' + JSON.stringify(data))
                socket.emit('search-channels', data, function (chList) {
                    log('searchChannels: < length - ' + chList.length)
                    resolve(chList)
                    cb && cb(chList)
                })
            })
        }

        self.searchChannelPublications = params => {
            return new Promise((resolve, reject) => {
                const { chId, filter, fromPubId, count } = params
                const data = { chId, filter, fromPubId, count }
                log('searchChannels: > ' + JSON.stringify(data))
                //console.log('searchChannels: > ', JSON.stringify(data))
                socket.emit('search-channel-publications', data, chList => {
                    log('searchChannelPublications: < chList.length - ' + chList.length)
                    //console.log('searchChannelPublications: < chList - ', chList)
                    resolve(chList)
                })
            })
        }

        self.addPublication = params => new Promise((resolve, reject) => {
            const data = {
                chId: params.chId,
                publisher: params.publisher,
                title: params.title,
                info: params.info,
                data: params.data,
            }
            log('addPublication: > ' + JSON.stringify(data))
            socket.emit('add-publication', data, (data, pubId) => {
                if (data.error) {
                    log('addPublication <  error: ' + data.error)
                    return reject(new Error(data.error))
                }
                log('addPublication < success, pubId:' + data.pubId)
                resolve(data.pubId)
            })
        })

        self.changePublication = params => new Promise((resolve, reject) => {
            const data = {
                chId: params.chId,
                pubId: params.pubId,
                publisher: params.publisher,
                title: params.title,
                info: params.info,
                data: params.data,
            }
            log('changePublication: > ' + JSON.stringify(data))
            socket.emit('change-publication', data, (err) => {
                if (err && err.error) {
                    log('changePublication: < error: ' + err.error)
                    return reject(new Error(err.error))
                }
                log('changePublication < success')
                resolve()
            })
        })

        self.delPublication = (params) => new Promise((resolve, reject) => {
            const data = { pubId: params.pubId, chId: params.chId }
            log('delPublication: > ' + JSON.stringify(data))
            socket.emit('delete-publication', data, (err) => {
                if (err && err.error) {
                    log('delPublication: < error: ' + err.error)
                    return reject()
                }
                log('delPublication: < success')
                resolve()
            })
        })

        self.getPublications = function ({ chId, fromPubId = 0, count}, cb) {
            var data = { chId, fromPubId, count }
            log('getPublications: > ' + JSON.stringify(data))
            socket.emit('get-publications', data, function (pubList) {
                /* pubList.forEach(function(publication){
                  publication.data = publication.data.map(function (cur) {
                  try {
                  cur = JSON.parse(cur);
                  } catch (e) {
                  log('getPublications: < error: invalid data: ' + cur);
                  }
                  return cur;
                  });
                  }); */
                log('getPublications: < ' + pubList.length)
                cb && cb(pubList)
            })
        }

        self.getPublicationDetails = params => new Promise((resolve, reject) => {
            const data = { chId: params.chId, pubId: params.pubId }
            log('getPublicationDetails: > ' + JSON.stringify(data))
            socket.emit('get-publication-details', data, publication => {
                if (publication.error) reject('getPublicationDetails: < error: ' + publication.error)
                publication.chId = params.chId
                publication.pubId = params.pubId
                log('getPublicationDetails: < success')
                resolve(publication)
            })
        })

        self.addChannelUser = params => new Promise((resolve, reject) => {
            const data = { chId: params.chId, contacts: params.contacts }
            log('addChannelUser: > ' + JSON.stringify(data))
            socket.emit('add-channel-users', data, err => {
                if (err && err.error) {
                    reject('addChannelUser: < error: ' + err.error)
                }
                resolve()
            })
        })

        self.deleteChannelUser = (params, cb) => new Promise((resolve, reject) => {
            const data = { chId: params.chId, contacts: params.contacts }
            log('deleteChannelUser: > ' + JSON.stringify(data))
            socket.emit('delete-channel-users', data, (err, response) => {
                if (err && err.error) {
                    reject('deleteChannelUser: < error: ' + err.error)
                } else {
                    log('deleteChannelUser: < OK')
                    resolve()
                }
            })
        })

        self.getPublicationLikes = function (params, cb) {
            var data = { pubId: params.pubId, chId: params.chId, fromLikeId: params.fromLikeId, count: params.count }
            log('getPublicationLikes: > ' + JSON.stringify(data))
            socket.emit('get-publication-likes', data, function (likes_list) {
                log('getPublicationLikes: < ' + likes_list.length)
                cb && cb(likes_list)
            })
        }

        self.publicationLike = (params) => new Promise((resolve, reject) => {
            let data = { pubId: params.pubId, chId: params.chId, state: params.state }
            log('publicationLikes > ' + JSON.stringify(data))
            socket.emit('publication-like', data, (err) => {
                if (err && err.error) {
                    log('publicationLike: < error: ' + err.error)
                    return reject(new Error(err.error))
                }
                resolve()
            })
        })

        self.getPublicationComments = function (params) {
            var data = { pubId: params.pubId, chId: params.chId, fromComId: params.fromComId, count: params.count }
            log('getPublicationComments: > ' + JSON.stringify(data))
            return new Promise((resolve, reject) => {
                socket.emit('get-publication-comments', data, result => {
                    log('getPublicationComments: < ' + result.length)
                    if (result) resolve(result)
                    else reject()
                })
            })
        }

        self.addPublicationComment = (params) => new Promise((resolve, reject) => {
            var data = { pubId: params.pubId, chId: params.chId, text: params.text }
            log('addPublicationComment > ' + JSON.stringify(data))
            socket.emit('add-publication-comment', data, (err) => {
                if (err && err.error) {
                    log('addPublicationComment: < error: ' + err.error)
                    return reject(new Error(err.error))
                }
                resolve()
            })
        })

        self.watchChannelPublications = function (params, cb) {
            var data = { chId: params.chId }
            log('watchChannelPublications > ' + JSON.stringify(data))
            socket.emit('watch-channel-publications', data, function (err) {
                if (err && err.error) {
                    return log('watchChannelPublications: < error: ' + err.error)
                }
                cb && cb()
            })
        }

        function addChannelDefaultData (channel) {
            if (!('chType' in channel)) channel.chType = declarations.channel_types.PUBLIC
            if (!('privilege' in channel)) channel.privilege = declarations.channel_user_privilege.USER
            if (!('status' in channel)) channel.status = declarations.channel_user_statuses.ABSENT
            if (!('verified' in channel)) channel.verified = false
        }

        //* *** Channels proto end ****//

        self.rtcCall = function (cid, options, number, sdp, candidates) {
            return new Promise((resolve, reject) => {
                if (!!options.video) options.video = true
                let data = { options, sdp, candidates }
                if (typeof cid === 'number') data.cid = cid
                if (number) data.number = number
                log('rtcCall: > ' + JSON.stringify(data))
                socket.emit('rtc-call', data, function (data) {
                    log('rtcCall: < ' + JSON.stringify(data))
                    resolve(data)
                })
            })
        }

        self.rtcCallRinging = function (callId) {
            return new Promise((resolve, reject) => {
                let data = { callId: callId }
                log('rtcCallRinging: > ' + JSON.stringify(data))
                socket.emit('rtc-call-ringing', data)
                resolve()
            })
        }

        self.rtcCallAnswer = function (callId, options, sdp, candidates) {
            return new Promise((resolve, reject) => {
                let data = { callId: callId, options: options, sdp: sdp, candidates: candidates }
                log('rtcCallAnswer: > ' + JSON.stringify(data))
                socket.emit('rtc-call-answer', data)
                resolve()
            })
        }

        self.rtcCallTermination = data => {
            log('rtcCallTermination: > ' + JSON.stringify(data))
            socket.emit('rtc-call-termination', data)
        }

        self.rtcCallProcessing = function (callId, msg, data_) {
            let data = { callId, msg, data: data_ }
            log('rtcCallProcessing: > ' + JSON.stringify(data))
            socket.emit('rtc-call-processing', data)
        }

        self.rtcCallHold = function (callId, options) {
            return new Promise((resolve, reject) => {
                let data = { callId: callId, options: options }
                log('rtcCallHold: > ' + JSON.stringify(data))
                socket.emit('rtc-call-options', data)
                resolve()
            })
        }

        self.rtcCallTransfer = function (callId, transferId) {
            let data = { callId, transferId }
            log('rtcCallTransfer: > ' + JSON.stringify(data))
            socket.emit('rtc-call-transfer', data)
        }

        self.rtcCallConference = function (callId, confId) {
            let data = { calls: [callId, confId] }
            log('rtcCallConference: > ' + JSON.stringify(data))
            socket.emit('rtc-call-conference', data)
        }

        self.rtcCallDtmf = function (callId, digits) {
            return new Promise((resolve, reject) => {
                let data = { callId, digits }
                log('rtcCallDtmf: > ' + JSON.stringify(data))
                socket.emit('rtc-call-dtmf', data)
                resolve()
            })
        }

        self.rtcSynchronizeCalls = function (callId, digits) {
            let data = { callId, digits }
            log('rtcSynchronizeCalls: > ' + JSON.stringify(data))
            socket.emit('rtc-synchronize-calls', data, data => {

            })
        }

        self.setBoosterStatus = status => {
            socket.emit('set-booster-status', {status}, result => {

            })
        }

        self.rtcCallAvailability = ({cid, number}) => new Promise ((resolve, reject) => {
            let data = {}
            if (number) data.number = number
            else data.cid = cid
            log('rtcCallAvailability: > ' + JSON.stringify(data))
            socket.emit('rtc-call-availability', data, (result) => {
                log('rtcCallAvailability: < ' + JSON.stringify(result))
                resolve(result && result.available)
            })
        })

        self.rtcCallSubscribeAvailability = (data) => new Promise ((resolve, reject) => {
            log('rtcCallSubscribeAvailability: > ' + JSON.stringify(data))
            socket.emit('rtc-call-subscribe-availability', data, result => {
                log('rtcCallSubscribeAvailability: < ' + JSON.stringify(result))
                if (result.error) return reject(result.error)
                resolve(result && result.available)
            })
        })

        self.rtcCallConferenceList = (data) => new Promise((resolve, reject) => {
            log('rtcCallConferenceList: > ' +  JSON.stringify(data))
            socket.emit('rtc-call-conference-list', data, result => {
                log('rtcCallConferenceList: < ' + JSON.stringify(result))
                resolve(result || [])
            })
        })

        self.sendCallsStats = (data) => new Promise((resolve, reject) => {
            log('sendCallsStats: > ' + JSON.stringify(data))
            socket.emit('report-status', data, result => {})
        })

        self.getVmMessages = function (cb) {
            log('getVmMessages: > ')
            socket.emit('get-voicemail-messages', null, function (data) {
                log('getVmMessages: < ' + JSON.stringify(data))
                return cb && cb(data)
            })
        }

        self.viewVoiceMailMessage = function (cb, box_id, time, number) {
            var data = { box_id: box_id, time: time, number: number }
            log('viewVoiceMailMessage: > ' + JSON.stringify(data))
            socket.emit('view-voicemail-message', data, function (data) {
                log('viewVoiceMailMessage: < ' + data)
                return cb && cb(data)
            })
        }

        self.deleteVoiceMailMessage = function (cb, box_id, time, number) {
            var data = { box_id: box_id, time: time, number: number }
            log('deleteVoiceMailMessage: > ' + JSON.stringify(data))
            socket.emit('delete-voicemail-message', data, function (data) {
                log('deleteVoiceMailMessage: < ' + data)
                return cb && cb(data)
            })
        }

        self.getSessions = function () {
            return new Promise((resolve, reject) => {
                log('get-sessions: >')
                socket.emit('get-sessions', null, data => {
                    if (data) {
                        log(`get-sessions: < success count - ${data.length}`)
                        resolve(data)
                    } else {
                        log(`get-sessions: < failed`)
                        reject()
                    }
                })
            })
        }

        self.dropSessins = function () {
            return new Promise((resolve, reject) => {
                log('drop-sessions: >')
                socket.emit('drop-sessions', null, data => {
                    if (data.result) {
                        log(`drop-sessions: < success`)
                        resolve(data)
                    } else {
                        log(`drop-sessions: < failed`)
                        reject()
                    }
                })
            })
        }

        self.searchBots = function (params) {
            return new Promise((resolve, reject) => {
                log('search-bots: > ' + JSON.stringify(params))
                socket.emit('search-bots', params, result => {
                    if (result) {
                        log('search-bots: < success')
                        resolve(result)
                    } else {
                        log('search-bots: < failed')
                    }
                })
            })
        }

        self.botManage = function (params) {
            return new Promise((resolve, reject) => {
                log('bot-manage: > ' + JSON.stringify(params))
                socket.emit('bot-manage', params, result => {
                    if (result) {
                        log('bot-manage: < success')
                        resolve(result)
                    } else {
                        log('bot-manage: < failed')
                    }
                })
            })
        }

        self.pressButton = function (params) {
            return new Promise((resolve, reject) => {
                log('press-button: > ' + JSON.stringify(params))
                socket.emit('press-button', params, result => {
                    if (result) {
                        log('press-button: < success')
                        resolve(result)
                    } else {
                        log('press-button: < failed')
                    }
                })
            })
        }

        /**================================================== *
         * ==========  Section Протокол "Локация"  ========== *
         * ================================================== */

        self.setLocation = (params) => new Promise((resolve, reject) => {
            log('get-location: > ' + JSON.stringify(cid));
            let {locationRid, status, statusTime} = params;
            let data = {locationRid, status, statusTime};
            console.log('req data: > ', data);
            socket.emit('set-location', data, res => {
                if (res) {
                    console.log('set-location res: < ', res);
                } else {
                    log('set-location failed')
                }
            })
        })

        self.getLocation = async (cid) =>  {
            log('get-location: > ' + JSON.stringify(cid));
            let data = {cid};
            log('req data: > ' + data);
            try {
                let res = await self._emitWithTimeOut('get-location', data);
                log('get-location res: < ' + res);
                return res;
            } catch(err) {
                log('get-location failed');
                throw('get-location failed');
            }
        }

        self.getLocationsConfig = () => new Promise((resolve, reject) => {
                log('get-locations-config')
                socket.emit('get-locations-config', null, result => {
                    if (result) {
                        log('get-locations-config: < success')
                        resolve(result)
                    } else {
                        log('get-locations-config: < failed')
                        reject()
                    }
                })
        })

        /* =======  End of Протокол "Локация"  ======= */

        self.addConference = params => {
            log('add-conference: > ' + JSON.stringify(params))
            return new Promise((resolve, reject) => {
                socket.emit('add-conference', params, result => {
                    if(result.error) console.log(result.error)
                    else resolve(result.roomId)                        
                })
            })            
        }

        self.conferenceProcessing = params => {
            log('conference-processing: > ' + JSON.stringify(params))
            socket.emit('conference-processing', params)            
        }

        self.conferenceRinging = params => {
            log('conference-ringing: > ' + JSON.stringify(params))
            socket.emit('conference-ringing', params)
        }

        self.conferenceAnsver = params => {
            log('conference-answer: > ' + JSON.stringify(params))
            socket.emit('conference-answer', params)
        }
        
        self.conferenceTermination = params => {
            log('conference-termination: > ' + JSON.stringify(params))
            socket.emit('conference-termination', params)
        }
        self.ConferenceAddUser = params => {
            return new Promise((resolve, reject) => {
                log('conference-add-users: > ' + JSON.stringify(params))
                socket.emit('conference-add-users', params, res => {
                    if (!res.error) resolve(true)
                    else {
                        resolve(false)
                        console.log(res.error)
                    }
                })
            })
        }

        self.pressDialKey = key => {
            log(`assistant-press-dial-key: > ${key}`)
            let event = 'press-dial-key'
            if (self.serverApi >= 11) event = `assistant-${event}`
            socket.emit(event, {key})
        }

        self.dialNumber = digits => {
            log(`assistant-dial-number: > ${digits}`)
            let event = 'dial-number'
            if (self.serverApi >= 11) event = `assistant-${event}`
            socket.emit(event, {digits})
        }

        self.setActiveCall = id => {
            log(`assistant-set-active-call: > ${id}`)
            let event = 'set-active-call'
            if (self.serverApi >= 11) event = `assistant-${event}`
            socket.emit(event, {id})
        }

        self.releaseCall = id => {
            log(`assistant-release-call: > ${id}`)
            let event = 'release-call'
            if (self.serverApi >= 11) event = `assistant-${event}`
            socket.emit(event, {id})
        }

        self.transit = (data) => {
            log(`assistant-transit: > ${JSON.stringify(data)}`)
            let event = 'transit'
            if (self.serverApi >= 11) event = `assistant-${event}`
            socket.emit(event, data)
        }

        self.conference = (data) => {
            log(`assistant-conference: > ${data}`)
            let event = 'conference'
            if (self.serverApi >= 11) event = `assistant-${event}`
            socket.emit(event, data)
        }

        self.microphone = () => {
            log('assistant-microphone: > ')
            let event = 'microphone'
            if (self.serverApi >= 11) event = `assistant-${event}`
            socket.emit(event)
        }

        self.speaker = () => {
            log('assistant-speaker: > ')
            let event = 'speaker'
            if (self.serverApi >= 11) event = `assistant-${event}`
            socket.emit(event)
        }

        self.dnd = () => {
            log('assistant-dnd: > ')
            let event = 'dnd'
            if (self.serverApi >= 11) event = `assistant-${event}`
            socket.emit(event)
        }

        self.getCallInfo = id => {
            return new Promise((resolve, reject) => {
                log('assistant-get-call-info: > ' + id)
                let event = 'get-call-info'
                if (self.serverApi >= 11) event = `assistant-${event}`
                socket.emit(event, {id}, res => resolve(res))
            })
        }

        self.getCallsInfo = () => {
            return new Promise((resolve, reject) => {
                log('assistant-get-calls-info: >')
                let event = 'get-calls-info'
                if (self.serverApi >= 11) event = `assistant-${event}`
                socket.emit(event, null, res => {
                    log(`assistant-get-calls-info: < ${JSON.stringify(res)}`)
                    resolve(res)
                })
            })
        }

        self.getTaInfo = () => {
            return new Promise((resolve, reject) => {
                log('assistant-get-ta-info: >')
                let event = 'get-ta-info'
                if (self.serverApi >= 11) event = `assistant-${event}`
                socket.emit(event, null, res => {
                    log(`assistant-get-ta-info: < ${JSON.stringify(res)}`)
                    resolve(res)
                })
            })
        }

        /**================================================== *
         * ===  Section Протокол "Профиль пользователя"  ==== *
         * ================================================== */

        self.getProfile = async () => {
            try {
                let res = await self._emitWithTimeOut('get-profile', null, 1000);
                log('get-profile: < ' + JSON.stringify(res));
                return res;
            } catch(err) {
                log('get-profile error '+  JSON.stringify(err));
                throw('get-profile error');
            }
        }

        self.setProfile = async (params) => {
            log('set-profile: > ' + JSON.stringify(params));
            const data = {
                key: params.key,
                value: params.value,
            };
            try {
                let profile = await self._emitWithTimeOut('set-profile', data);
                log('profile from server: ' + JSON.stringify(profile));
                if (profile.error) {
                    throw("set-profile error: < " + profile.error);
                }
                return profile;
            } catch(err) {
                log('set-profile error '+  JSON.stringify(err));
                throw('set-profile error');
            }
        }

        /* ======  End of  Протокол "Профиль пользователя"  ====== */

        // ************** Start of Radio 3.6 get token ***************
        self.radioGetToken = async (data) => {
            try {
                let answer = await self._emitWithTimeOut('radio-get-token', data)
                log('radio-get-token: < ' + JSON.stringify(answer))
                return answer
            } catch(err) {
                log('radio-get-token error '+  JSON.stringify(err))
                throw('radio-get-token error')
            }
        }
        // *************** End of Radio 3.6 get token *****************

        /**================================================== *
         * ==========  Section Протокол "DLP"  ============== *
         * ================================================== */
        self.dlpEvent = async (data) => {
            return new Promise((resolve, reject) => {
                log('dlp-event: > ' + JSON.stringify(data))
                socket.emit('dlp-event', data, res => {
                    if (res.error) {
                        log(`dlp-event: < error: ${res.error}`)
                        return reject(new Error(res.error))
                    }
                    log('dlp-event: < ok')
                    resolve()
                })
            })
        }
        /* =============  End of Протокол "DLP"  ============== */


        /**================================================== *
         * ========  Section Протокол "Расписание"  ========= *
         * ================================================== */

        /** schedule-credentials-check
         * @param type {String}  // 'exchange'
         * @param credentials {Object} // { login, password }
         */        
        self.scheduleCredentialsCheck = data => new Promise((resolve, reject) => {
            console.log('schedule-credentials-check: >', data)
            socket.emit('schedule-credentials-check', data, (response = {}) => {
                let isError = response && response.hasOwnProperty('error')
                if (isError) {
                    const error = response.error
                    return reject(error)
                }
                console.log('schedule-credentials-check: < ', response)
                resolve(true)
            })
        })

        /** schedule-delete-credentials
         * @param type {String}  // 'exchange'
         */        
        self.scheduleDeleteCredentials = data => new Promise((resolve, reject) => {
            console.log('schedule-delete-credentials: >', data)
            socket.emit('schedule-delete-credentials', data, (response = {}) => {
                let isError = response && response.hasOwnProperty('error')
                if (isError) {
                    const error = response.error
                    return reject(error)
                }
                console.log('schedule-delete-credentials: < ', response)
                resolve(true)
            })
        })        

        /** schedule-update-credentials
         * @param type {String}  // 'exchange'
         * @param credentials {Object} // { login, password }
         */        
        self.scheduleUpdateCredentials = data => new Promise((resolve, reject) => {
            console.log('schedule-update-credentials: >', data)
            socket.emit('schedule-update-credentials', data, (response = {}) => {
                let isError = response && response.hasOwnProperty('error')
                if (isError) {
                    const error = response.error
                    return reject(error)
                }
                console.log('schedule-update-credentials: < ', response)
                resolve(true)
            })
        })

        /** schedule-sync-appointments
         * @param type {String}  // 'exchange'
         */        
        self.scheduleSyncAppointments = data => new Promise((resolve, reject) => {
            console.log('schedule-sync-appointments: >', data)
            socket.emit('schedule-sync-appointments', data, (response = {}) => {
                let isError = response && response.hasOwnProperty('error')
                if (isError) {
                    const error = response.error
                    return reject(error)
                }
                console.log('schedule-sync-appointments: < ', response)
                resolve(response)
            })
        })

        /** schedule-add-appointment
         * @param type {String}  // 'exchange'
         * @param appointment {Object} // { 
         *  id	int	Идентификатор встречи
            type	scheduleType	Тип расписания
            subject	string	Тема встречи
            startTime	int	Время начала встречи
            endTime	int	Время окончания встречи
            allDayEvent	bool	Встреча на весь день
            isCancelled	bool	Встреча была отменена
            myResponseType	  Что я ответил на запрос   // ????
            organizer	string	Почта организатора встречи
            location	string	Место проведения встречи
            isPrivate	bool	Частная встреча
            attendees	scheduleAttendee	Участники встречи
            recurrence	recurrence	Сценарий повторений
         * }
         */        
        self.scheduleAddAppointment = data => new Promise((resolve, reject) => {
            console.log('schedule-add-appointment: >', data)
            socket.emit('schedule-add-appointment', data, (response = {}) => {
                let isError = response && response.hasOwnProperty('error')
                if (isError) {
                    const error = response.error
                    return reject(error)
                }
                console.log('schedule-add-appointment: < ', response)
                resolve(response)
            })
        })

        /** schedule-edit-appointment
         * @param type {String}  // 'exchange'
         * @param appointment {Object} // { 
         *  id	int	Идентификатор встречи
            type	scheduleType	Тип расписания
            subject	string	Тема встречи
            startTime	int	Время начала встречи
            endTime	int	Время окончания встречи
            allDayEvent	bool	Встреча на весь день
            isCancelled	bool	Встреча была отменена
            myResponseType	  Что я ответил на запрос   // ????
            organizer	string	Почта организатора встречи
            location	string	Место проведения встречи
            isPrivate	bool	Частная встреча
            attendees	scheduleAttendee	Участники встречи
            recurrence	recurrence	Сценарий повторений
         * }
         */        
            self.scheduleEditAppointment = data => new Promise((resolve, reject) => {
                console.log('schedule-edit-appointment: >', data)
                socket.emit('schedule-edit-appointment', data, (response = {}) => {
                    let { error } = response
                    if (error) {
                        return reject(error)
                    }
                    console.log('schedule-edit-appointment: < ', response)
                    resolve(response)
                })
            })

        /** schedule-delete-appointment
         * @param type {String}  // 'exchange'
         * @param id {int} // appointment id
         */        
            self.scheduleDeleteAppointment = data => new Promise((resolve, reject) => {
                console.log('schedule-delete-appointment: >', data)
                socket.emit('schedule-delete-appointment', data, (response = {}) => {
                    let { error } = response
                    if (error) {
                        return reject(error)
                    }
                    console.log('schedule-delete-appointment: < ', response)
                    resolve(response)
                })
            })


        /** schedule-get-appointment
         * @param type {String}  // 'exchange'
         * @param id {int} // appointment id
         */        
        self.scheduleGetAppointment = data => new Promise((resolve, reject) => {
            console.log('schedule-get-appointment: >', data)
            socket.emit('schedule-get-appointment', data, (response = {}) => {
                let isError = response && response.hasOwnProperty('error')
                if (isError) {
                    const error = response.error
                    return reject(error)
                }
                console.log('schedule-get-appointment: < ', response)
                resolve(response)
            })
        })

        /** schedule-get-calendar-appointments
         * @param type {String}  // 'exchange'
         * @param startDate {date} // 
         * @param endDate {date} // 
         */        
        self.scheduleGetCalendarAppointments = data => new Promise((resolve, reject) => {
            // console.log('schedule-get-calendar-appointments: >', data)
            socket.emit('schedule-get-calendar-appointments', data, (response = {}) => {
                let { error } = response
                if (error) {
                    console.log('schedule-get-calendar-appointments error: ', error)
                    return reject(error)
                }
                // console.log('schedule-get-calendar-appointments: < ', response)
                resolve(response)
            })
        })

        /** schedule-response-to-invitation
         * @param type {String}  // 'exchange'
         * @param id {int} // appointment id
         * @param response {String} // invitationResponseType: accept, accept-tentatively, decline
         */        
        self.scheduleResponseToInvitation = data => new Promise((resolve, reject) => {
            console.log('schedule-response-to-invitation: >', data)
            socket.emit('schedule-response-to-invitation', data, (response = {}) => {
                let isError = response && response.hasOwnProperty('error')
                if (isError) {
                    const error = response.error
                    return reject(error)
                }
                console.log('schedule-response-to-invitation: < ', response)
                resolve(response)
            })
        })

        /* =========  End of Протокол "Расписание"  ========= */


        self._emitWithTimeOut = (eventName, payload, timeOut = 3000) => {
            return new Promise((resolve, reject) => {
                let timeOutId = setTimeout(() => {
                    reject(new Error('emitTimeOut'))
                }, timeOut)
                socket.emit(eventName, payload, (...args) => {
                    clearTimeout(timeOutId)
                    resolve(...args)
                })
            })
        }
    }
}

const proto = new Proto()

ipc.on('proto', async (event, method, ...args) => {
    try {
        const data = await proto[method](...args)
        ipc.send('proto/ok', data)
    } catch (e) {
        ipc.send('proto/err', e)
    }
})

export default proto
