var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "publication-image-message" }, [
    _c(
      "div",
      {
        staticClass: "preview-block",
        on: {
          click: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k(
                $event.keyCode,
                "preventDefault",
                undefined,
                $event.key,
                undefined
              )
            ) {
              return null
            }
            return _vm.openViewer(_vm.index)
          }
        }
      },
      [
        _vm.isPending
          ? _c("div", { staticClass: "pending" }, [
              _c(
                "div",
                {
                  staticClass: "upload-abort-btn message-btn",
                  on: { click: _vm.abortAjaxCall }
                },
                [
                  _c("progress-circle", {
                    attrs: {
                      load_progress: _vm.loadProgress,
                      radius: 18,
                      width: 48,
                      height: 48
                    }
                  }),
                  _c("i", { staticClass: "fa fa-ban" })
                ],
                1
              )
            ])
          : _vm._e(),
        !_vm.isPending && !_vm.src ? _c("content-loader") : _vm._e(),
        _c(
          "div",
          {
            staticClass: "img-wrapper",
            class: { "img-wrapper-slider-cont": _vm.images > 1 },
            on: { click: _vm.doShowViewer }
          },
          [
            !_vm.src
              ? _c("img", {
                  attrs: {
                    src:
                      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  }
                })
              : _c("img", {
                  class: { "img-wrapper-slider": _vm.images > 1 },
                  attrs: { src: _vm.src, alt: "" }
                })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }