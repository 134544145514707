import { console } from "vuedraggable/src/util/helper"

const r = require('ramda')

import {
    BOTS,
    CONTENT_MANAGER,
    CHATS,
    CHANNELS,
    LOGIN,
    CONTACTS,
} from '../modulesNames'
import {
    GET_INFO_OPEN,
    GET_INFO_NEED_OPEN,
    GET_INFO_CAN_BACK,
    GET_INFO_HISTORY_LENGTH,
    GET_INFO_LAST_ACTION,
    GET_INFO_PAYLOAD_BY_MAIN_TYPE,
    GET_BOT_BY_ID,
    GET_MAIN_TYPE,
    GET_SELECTED_CHAT,
    GET_SELECTED_CHANNEL,
    IS_NOTE_CHAT,
    GET_IS_FIRST_TIME_OPENED,
    GET_SERVER_API,
    GET_IS_COMPACT_MODE,
} from '../gettersTypes'
import {
    ACT_INFO_PUSH,
    ACT_INFO_REPLACE,
    ACT_INFO_BACK,
    ACT_INFO_CLEAR,
    ACT_UPDATE_INFO_OPEN,
    ACT_INFO_TOGGLE,
    ACT_UPDATE_SEARCHED_CONTACT,
    ACT_SET_COMPACT_MODE,
} from '../actionsTypes'
import {
    MUT_INFO_PUSH,
    MUT_INFO_REPLACE,
    MUT_INFO_REPLACE_LAST,
    MUT_INFO_BACK,
    MUT_INFO_CLEAR,
    MUT_SET_INFO_OPEN,
    MUT_SET_FIRST_TIME_OPENED,
    MUT_SET_COMPACT_MODE,
} from '../mutationsTypes'

import { MAIN_TYPES } from './content-manager'

export const INFO_TYPES = {
    CHAT: 'chat-info',
    CONTACT: 'contact-info',
    BOT: 'bot-info',
    SYSTEM_CHAT: 'system-chat-info',
    SEARCH_CHAT: 'search-in-chat',
    SEARCH_CHANNEL: 'search-in-channel',
    MEDIA_SEARCH: 'media-search',
    NOTE: 'note-info',
    CONTACT_DETAILED: 'contact-detailed-info',
    CHANNEL: 'channel-info',
    CHAT_MEMBERS: 'chat-members',
    CHANNEL_MEMBERS: 'channel-members',
    DETAILS_MSG: 'details-msg',
    ACS_JOURNAL: 'acs-journal',
}

import { getIsCompactMode, 
    setMainVisible, 
    setMainHidden 
} from '../../../ext/trackResizes'

const state = {
    open: false,
    history: [],
    lastAction: null,
    firstTimeOpened: true,
    showSearch: false,
    isCompactMode: false,
}

const getters = {
    [GET_INFO_NEED_OPEN]: state => state.open,
    [GET_INFO_OPEN]: state => state.history[state.history.length - 1],
    [GET_INFO_CAN_BACK]: (state) => {
        return state.history.length > 1
    },
    [GET_INFO_HISTORY_LENGTH]: state => state.history.length,
    [GET_INFO_LAST_ACTION]: state => state.lastAction,
    [GET_INFO_PAYLOAD_BY_MAIN_TYPE]: (state, getters, rootState, rootGetters) => {
        const mainType = rootGetters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`]
        switch (mainType) {
            case MAIN_TYPES.CONTACT:
                return { type: INFO_TYPES.CONTACT, params: rootGetters[`${CHATS}/${GET_SELECTED_CHAT}`] }
            case MAIN_TYPES.CHAT:
                return { type: INFO_TYPES.CHAT, params: rootGetters[`${CHATS}/${GET_SELECTED_CHAT}`] }
            case MAIN_TYPES.CHANNEL:
                return { type: INFO_TYPES.CHANNEL, params: { chId: rootGetters[`${CHANNELS}/${GET_SELECTED_CHANNEL}`] } }
            case MAIN_TYPES.NOTE:
                return { type: INFO_TYPES.NOTE }
        }
    },
    [GET_IS_FIRST_TIME_OPENED]: state => state.firstTimeOpened,
    getShowSearch: state => state.showSearch,
    [GET_IS_COMPACT_MODE]: state => state.isCompactMode,
};

const actions = {
    [ACT_INFO_PUSH]({ dispatch, commit, getters }, payload) {
        let preparedPayload = payloadPrepare.call(this, payload)
        let payloadByMainType = payloadPrepare.call(this, getters[GET_INFO_PAYLOAD_BY_MAIN_TYPE])
        let isEqualMain
        if (payload.params && payload.params.isMyProfile) isEqualMain = true
        else isEqualMain = payloadByMainType.type === preparedPayload.type && r.equals(payloadByMainType.params, preparedPayload.params)
        const isCompactMode = getIsCompactMode()
        if (!getters[GET_INFO_HISTORY_LENGTH] && !isEqualMain) {
            commit(MUT_INFO_PUSH, payloadByMainType)
        }
        if (!isCompactMode && getters[GET_INFO_CAN_BACK] && getters[GET_INFO_OPEN]?.type === preparedPayload.type) {
            commit('setLastAction', ACT_INFO_REPLACE)
            commit(MUT_INFO_REPLACE_LAST, preparedPayload)
            dispatch(ACT_UPDATE_INFO_OPEN)
        } else if (!(getters[GET_INFO_HISTORY_LENGTH] === 1 && isEqualMain)) {
            commit('setLastAction', ACT_INFO_PUSH)
            commit(MUT_INFO_PUSH, preparedPayload)
            dispatch(ACT_UPDATE_INFO_OPEN)
        } 
    },
    [ACT_INFO_REPLACE]({ dispatch, commit }, payload) {
        commit('setLastAction', ACT_INFO_REPLACE);
        commit(MUT_INFO_REPLACE, payloadPrepare.call(this, payload));
        dispatch(ACT_UPDATE_INFO_OPEN);
    },
    [ACT_INFO_BACK]({ dispatch, commit }) {
        commit('setLastAction', ACT_INFO_BACK);
        commit(MUT_INFO_BACK);
        dispatch(ACT_UPDATE_INFO_OPEN);
    },
    [ACT_INFO_CLEAR]({ dispatch, commit }) {
        commit('setLastAction', ACT_INFO_CLEAR);
        commit(MUT_INFO_CLEAR);
        dispatch(ACT_UPDATE_INFO_OPEN);
    },
    [ACT_INFO_TOGGLE]({ dispatch, commit, getters }) {
        const open = getters[GET_INFO_OPEN];
        const isFirstTime = getters[GET_IS_FIRST_TIME_OPENED]
        const isCompactMode = getIsCompactMode()
        if (open) {
            if (isCompactMode) setMainVisible()
            dispatch(ACT_INFO_CLEAR);
        } else {
            if (isCompactMode) setMainHidden()
            let infoPayload = getters[GET_INFO_PAYLOAD_BY_MAIN_TYPE]
            dispatch(ACT_INFO_PUSH, payloadPrepare.call(this, infoPayload))
        }
        if (isFirstTime) commit(MUT_SET_FIRST_TIME_OPENED)
    },
    [ACT_UPDATE_INFO_OPEN]({ commit, getters }) {
        const open = getters[GET_INFO_OPEN];
        commit(MUT_SET_INFO_OPEN, Boolean(open));
    },
    actSetShowSearch({ commit }, value) {
        commit('mutSetShowSearch', value)
    },
    [ACT_SET_COMPACT_MODE]({ commit }, value) {
        commit(MUT_SET_COMPACT_MODE, value)
    }
};

const mutations = {
    [MUT_INFO_PUSH](state, payload) {
        state.history.push(payload);
        const isCompactMode = getIsCompactMode()
        if (isCompactMode) setMainHidden()
    },
    [MUT_INFO_REPLACE](state, payload) {
        if (state.history.length) state.history.splice(0, state.history.length, payload);
    },
    [MUT_INFO_REPLACE_LAST](state, payload) {
        if (state.history.length) state.history.splice(state.history.length - 1, 1, payload);
    },
    [MUT_INFO_BACK](state) {
        const isCompactMode = getIsCompactMode()
        if (isCompactMode && state.history.length < 2) setMainVisible()
        state.history.pop();
    },
    [MUT_INFO_CLEAR](state) {
        state.history.splice(0, state.history.length);
        const isCompactMode = getIsCompactMode()
        if (isCompactMode) setMainVisible()
    },
    [MUT_SET_INFO_OPEN](state, open) {
        state.open = open;
    },
    setLastAction(state, action) {
        state.lastAction = action;
    },
    [MUT_SET_FIRST_TIME_OPENED](state) {
        if (state.firstTimeOpened) state.firstTimeOpened = false
    },
    mutSetShowSearch(state, value) {
        state.showSearch = value
    },
    [MUT_SET_COMPACT_MODE](state, value) {
        state.isCompactMode = value
    }
};

function payloadPrepare(payload = {}) {
    if (payload.type !== INFO_TYPES.CONTACT) return payload
    const cid = +payload.params.cid
    payload.params.cid = cid
    delete payload.params.cidType
    if (cid === 0) payload.type = INFO_TYPES.SYSTEM_CHAT
    else if (this.getters[`${BOTS}/${GET_BOT_BY_ID}`](cid)) payload.type = INFO_TYPES.BOT
    else if (this.getters[`${CHATS}/${IS_NOTE_CHAT}`](payload.params)) payload.type = INFO_TYPES.NOTE
    if (cid !== 0 && this.getters[`${LOGIN}/${GET_SERVER_API}`] >= declarations.serverAPILevels.LEVEL_12) {
        this.dispatch(`${CONTACTS}/${ACT_UPDATE_SEARCHED_CONTACT}`, {cid}).catch()
    }
    return payload
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

