var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("videomost.conf-info-context")
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "conference-info" }, [
          _c(
            "div",
            { staticClass: "subject conf-item" },
            [
              _c("custom-input-text", {
                key: "0",
                ref: "vmConfSubject",
                attrs: {
                  default_text: _vm.conf.topic,
                  input_text: _vm.conf.topic,
                  label: _vm.$t("videomost.conf-subject"),
                  readonly: _vm.readonly
                },
                on: {
                  "update:input_text": function($event) {
                    return _vm.$set(_vm.conf, "topic", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "schedule conf-item" },
            [
              _c("date-range-picker", {
                attrs: {
                  default_from: _vm.getStartTime(_vm.conf),
                  from: _vm.conf.startUTime,
                  default_to: _vm.getFinishTime(_vm.conf),
                  to: _vm.conf.finishUTime,
                  disabled: _vm.readonly,
                  clearable: !_vm.readonly
                },
                on: {
                  "update:from": function($event) {
                    return _vm.$set(_vm.conf, "startUTime", $event)
                  },
                  "update:to": function($event) {
                    return _vm.$set(_vm.conf, "finishUTime", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "conf-auth conf-item" },
            [
              _c("custom-input-text", {
                key: "1",
                attrs: {
                  default_text: _vm.conf.confid,
                  input_text: _vm.conf.confid,
                  label: _vm.$t("videomost.conf-id"),
                  readonly: _vm.readonly
                },
                on: {
                  "update:input_text": function($event) {
                    return _vm.$set(_vm.conf, "confid", $event)
                  }
                }
              }),
              _c("custom-input-text", {
                key: "2",
                staticStyle: { "margin-left": "22px" },
                attrs: {
                  default_text: _vm.conf.password,
                  input_text: _vm.conf.password,
                  label: _vm.$t("videomost.conf-password"),
                  readonly: _vm.readonly
                },
                on: {
                  "update:input_text": function($event) {
                    return _vm.$set(_vm.conf, "password", $event)
                  }
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "edit" }, [
          _c(
            "div",
            {
              staticClass: "circle-animation conference",
              attrs: { title: _vm.$t("videomost.conf-members-choose") },
              on: {
                click: function($event) {
                  return _vm.openInviteContextMenu(_vm.conf)
                }
              }
            },
            [_c("i", { staticClass: "far fa-users" })]
          ),
          _c(
            "div",
            {
              staticClass: "circle-animation conference",
              attrs: { title: _vm.$t("videomost.conf-edit") },
              on: {
                click: function($event) {
                  return _vm.openEditConf(_vm.conf)
                }
              }
            },
            [_c("i", { staticClass: "far fa-edit" })]
          ),
          _c(
            "div",
            {
              staticClass: "circle-animation conference",
              attrs: { title: _vm.$t("videomost.conf-delete") },
              on: {
                click: function($event) {
                  return _vm.deleteConfConfirm(_vm.conf.confid)
                }
              }
            },
            [_c("i", { staticClass: "fal fa-trash-alt" })]
          )
        ]),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c("modal-btn", {
              attrs: { text: _vm.$t("close") },
              on: { click: _vm.closeModal }
            }),
            _c("modal-btn", {
              attrs: { text: _vm.startConfText },
              on: { click: _vm.startConf }
            })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }