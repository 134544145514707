//
//
//
//
//
//
//
//
//
//

    import RecycleContactsList from "./RecycleContactsList.vue"
    import ContactsListItem from "../listItems/ContactsListItem.vue"
    import SelectedItemsLine from "./SelectedItemsLine.vue"
    import {mapActions, mapGetters} from 'vuex'
    import {CONTACTS} from '../../store/modulesNames'
    import {ACT_SEARCH_CONTACTS} from '../../store/actionsTypes'
    import { GET_MERGED_CONTACT_BY_ID } from '../../store/gettersTypes'
    import {CONTACTS_SEARCH_TYPES} from '../../constants'

    export default {
        name: "SelectContactBody",
        components: { RecycleContactsList, ContactsListItem, SelectedItemsLine },
        props: {
            multiple: {
                type: Boolean,
                required: false,
                default: false,
            },
            excludeMembers: {
                type: Array,
                required: false,
                default: () => [],
            },
            groups: {
                type: Array,
                required: false,
                default: () => [],
            },            
            filter: {
                type: String,
                required: false,
                default: '',
            },
            limit: {
                type: Number,
                required: false,
            },
            selecter: {
                type: Boolean,
                required: false,
                default: true,
            },
            selectedDefault: {
                type: Array,
                required: false,
                default: () => [],
            },
            unselectedContact: {
                type: Number,
                required: false,
                default: 0,
            },
            botsIsVisible: {
                type: Boolean,
                required: false,
                default: false
            },
            isLocalContacts: {
                type: Boolean,
                required: false,
                default: false
            },
            searchType: {
                type: String,
                default: CONTACTS_SEARCH_TYPES.VIEW_CONTACT
            },
        },
        created() {
            this.searchContact = debounce(this.searchContact, 500)
        },
        data: () => {
            return {
                selected: [],
                contactsList: [],
                fromId: 0,
                searchStep: 50,
                isLoadPossible: true,
                loading: false
            }
        },
        async mounted() {
            this.selected = this.selectedDefault.map((cid) => this[GET_MERGED_CONTACT_BY_ID](cid))
            await this.searchContact()
            let scrollerEl = this.$refs.scroller.$el.querySelector('.vue-recycle-scroller')
            scrollerEl.addEventListener('scroll', (event) => {
                const el = scrollerEl
                if (!el) return
                const scrollHeight = Math.round(el.scrollHeight)
                const scrollTop = Math.round(el.scrollTop)
                const clientHeight = Math.round(el.clientHeight)
                if (clientHeight + scrollTop >= scrollHeight - 100) {
                    if (this.isLoadPossible && !this.loading) this.searchContact()
                }
            })
        },
        computed: {
            selectedItemsLine() {
                return this.selected.map(({fio, photo}) => ({iconPhoto: photo, iconText: fio, text: fio}))
            },
            filteredGroups() {
                if (!this.filter) return this.groups
                let filter = this.filter.toLowerCase()
                return this.groups.filter(g => {
                    let fio = g.fio.toLowerCase()
                    return g.fio.toLowerCase().includes(this.filter.toLowerCase())
                })
            },
            ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
        },
        methods: {
            selectContact(contact) {
                if (!this.multiple) return this.$emit('selected', contact)
                else if(this.limit && this.selected.length >= this.limit) {
                    this.modalOpen({
                        name: 'alert',
                        props: {
                            title: 'Ошибка',
                            text: `Можете выбрать не более ${this.limit} контактов`
                        }
                    })
                } else {
                    let add = true;
                    for(let i = 0; i < this.selected.length; i++) {
                        if(this.selected[i].cid === contact.cid) {
                            add = false;
                            break;
                        }
                    }
                    if (add) {
                        this.selected.push(contact)
                        this.$emit('selected', contact)
                    }
                }
            },
            unselectContact(index) {
                let contact = this.selected.splice(index, 1)
                this.$emit('unselected', contact)
            },
            isMarked(item) {
                return this.selected.includes(item)
            },
            isExcluded({cid}) {
                return this.excludeMembers.includes(cid)
            },
            async searchContact() {
                let contacts = []
                let isListPage = false
                try {
                    this.loading = true
                    const searchResult = await this[ACT_SEARCH_CONTACTS]({
                        search: this.filter,
                        searchBots: this.botsIsVisible,
                        isLocal: this.isLocalContacts,
                        type: this.searchType,
                        fromId: this.fromId,
                        count: this.searchStep
                    })
                    this.isLoadPossible = searchResult.isLoadPossible
                    if (searchResult.lastCid) this.fromId = searchResult.lastCid
                    isListPage = searchResult.isListPage
                    contacts = searchResult.contacts
                } catch (e) {}
                this.loading = false
                if (isListPage) {
                    this.contactsList = this.contactsList.concat(contacts)
                } else {
                    this.contactsList = contacts
                }
                this.contactsList = this.contactsList.concat(this.filteredGroups).sort((a, b) => {
                    let fa = a.fio.toLowerCase(), fb = b.fio.toLowerCase()
                    if (fa === fb) return 0
                    return (fa > fb) ? 1 : -1
                })
            },
            async newSearch() {
                this.fromId = 0
                this.isLoadPossible = true
                this.contactsList = []
                await this.searchContact()
                if (this.$refs.scroller && this.$refs.scroller.$el) this.$refs.scroller.$el.scrollTop = 0
            },
            ...mapActions(CONTACTS, [ACT_SEARCH_CONTACTS])
        },
        watch: {
            filter() {
                this.newSearch()
            },
            isLocalContacts() {
                this.newSearch()
            },
            unselectedContact(delCid) {
                let index = this.selected.findIndex(({cid}) => cid === delCid)
                if (index > -1) this.unselectContact(index)
            },

        }
    }

const debounce = (func, delay) => {
    let debounceTimer
    return function() {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => { func.apply(context, args) }, delay)
    }
}
