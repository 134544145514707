const MIN_CLIENT_WIDTH = 990
const MIN_SIDE_WIDTH = 250
const MAX_SIDE_WIDTH = 450

let isCompactMode = false, isMainVisible = true, isInfoVisible = false

const resizeDivider = document.getElementById('resize-divider')
const mainElement = document.getElementById('main')
let infoElement = document.getElementById('info')

const getIsCompactMode = () => { return isCompactMode }
const getIsMainVisible = () => { return isMainVisible }

const setMainInfoVisibility = () => {
    infoElement = document.getElementById('info')
    if (!isMainVisible) mainElement.classList.add('hidden')
    else mainElement.classList.remove('hidden')
    if (!isInfoVisible) infoElement.classList.add('hidden')
    else {
        infoElement.classList.remove('hidden')
        if (!isMainVisible) infoElement.classList.add('mainHidden')
    }
}

const setMainVisible = () => {
    isMainVisible = true
    isInfoVisible = false
    setMainInfoVisibility()
}

const setMainHidden = () => {
    isMainVisible = false
    isInfoVisible = true
    setMainInfoVisibility()
}

const setCompactMode = () => {
    isCompactMode = true 
    mainElement.classList.add('compactMode')
    resizeDivider.classList.add('compactMode')
    infoElement = document.getElementById('info')
    infoElement.classList.add('compactMode')
    // setMainInfoVisibility()
    setMainVisible()
    resizeDivider.addEventListener('mousedown', (e) => {
        document.addEventListener('mousemove', handleMouseMove, false)
        document.addEventListener('mouseup', clearEvents, false)
        document.addEventListener('blur', clearEvents, false)
    }, false)       
}

const restoreNormalMode = () => {
    mainElement.classList.remove('compactMode')
    resizeDivider.classList.remove('compactMode')
    infoElement.classList.remove('compactMode')
    mainElement.classList.remove('hidden')
    infoElement = document.getElementById('info')
    infoElement.classList.remove('hidden')
    infoElement.classList.remove('mainHidden')
    clearEvents()
    const sideElement = document.getElementById('side').firstChild
    sideElement.removeAttribute("style")
    isCompactMode = false
}    

const handleMouseMove = (e) => {
    const sideElement = document.getElementById('side').firstChild
    if (e.clientX >= MIN_SIDE_WIDTH && e.clientX <= MAX_SIDE_WIDTH) 
        sideElement.style.width = e.clientX + 'px'
}

const clearEvents = () => {
    document.removeEventListener('mousemove', handleMouseMove, false)
    document.removeEventListener('mouseup', clearEvents, false)
    document.removeEventListener('blur', clearEvents, false)
}

const debounce = (func, wait) => {
    let timeout
    return () => {
        const context = this, args = arguments
        const later = function() {
            timeout = null
            func.apply(context, args)
        }
        const callNow = !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

const setTrackResizes = (event_bus) => {
    const checkCompactMode = (innerWidth) => {
        if (!isCompactMode && innerWidth <= MIN_CLIENT_WIDTH) {
            setCompactMode()
            event_bus.$emit('compact-mode', true)
        }
        else if (isCompactMode && innerWidth > MIN_CLIENT_WIDTH) {
            restoreNormalMode()
            event_bus.$emit('compact-mode', false)
        }
    }
    let { innerWidth } = window
    checkCompactMode(innerWidth)
    window.addEventListener('resize', debounce(() => {
        let { innerWidth } = window
        checkCompactMode(innerWidth)
    }, 30, false), false)
}

export { setTrackResizes, getIsCompactMode, getIsMainVisible, setMainVisible, setMainHidden }