var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "item-list" }, [
        _c("div", { staticClass: "item-name" }, [_vm._v(_vm._s(_vm.contact))]),
        _vm.marked
          ? _c("div", { staticClass: "item-icon" }, [
              _c("i", {
                staticClass: "fa fa-check",
                attrs: { "aria-hidden": "true" }
              })
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }