import { USERDATA } from '../app/store/modulesNames'

import {
    ACT_ACTIVE_MICROPHONE,
    ACT_ACTIVE_WEBCAMERA,
    ACT_ACTIVE_SPEAKERS,
    ACT_MEDIA_DEVICES,
} from '../app/store/actionsTypes'

import {
    GET_ACTIVE_MICROPHONE,
    GET_ACTIVE_WEBCAMERA,
    GET_ACTIVE_SPEAKERS,
} from '../app/store/gettersTypes'

import { MEDIA_DEVICES } from '../app/constants'

export async function updateDevices(store) {
    let devices
    try {
        devices = await navigator.mediaDevices.enumerateDevices()
    } catch (e) {
        console.log('AV Devices Error :', err)
    }

    devices = JSON.parse(JSON.stringify(devices))

    let webcam_devices = getConnectedDevicesType(devices, MEDIA_DEVICES.VIDEO_INPUT)
    let mic_devices = getConnectedDevicesType(devices, MEDIA_DEVICES.AUDIO_INPUT)
    let speakers_devices = getConnectedDevicesType(devices, MEDIA_DEVICES.AUDIO_OUTPUT)

    await store.dispatch(`${USERDATA}/${ACT_MEDIA_DEVICES}`, [...webcam_devices, ...mic_devices, ...speakers_devices])

    let getActiveMic = store.getters[`${USERDATA}/${GET_ACTIVE_MICROPHONE}`]
    let getActiveWebcamera = store.getters[`${USERDATA}/${GET_ACTIVE_WEBCAMERA}`]
    let getActiveSpeakers = store.getters[`${USERDATA}/${GET_ACTIVE_SPEAKERS}`]

    if ((!getActiveWebcamera || !getActiveWebcamera.deviceId) && webcam_devices && webcam_devices.length > 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_WEBCAMERA}`, webcam_devices[0]);
    }
    if (!webcam_devices || webcam_devices.length === 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_WEBCAMERA}`, null)
    }

    if ((!getActiveMic || !getActiveMic.deviceId) && mic_devices && mic_devices.length > 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_MICROPHONE}`, mic_devices[0]);
    }
    if (!mic_devices || mic_devices.length === 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_MICROPHONE}`, null)
    }

    if (!getActiveSpeakers && speakers_devices && speakers_devices.length > 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_SPEAKERS}`, speakers_devices[0]);
    }
    if (!speakers_devices || speakers_devices.length === 0) {
        await store.dispatch(`${USERDATA}/${ACT_ACTIVE_SPEAKERS}`, null)
    }

    function getConnectedDevicesType(devices, type) {
        let nameIndex = 1
        return devices.filter(device => {
            if (device.kind !== type) return false
            if (device.label === '') {
                let nameType = ''
                switch (device.kind) {
                    case MEDIA_DEVICES.VIDEO_INPUT:
                        nameType = locale['camera']
                        break
                    case MEDIA_DEVICES.AUDIO_INPUT:
                        nameType = locale['microphone']
                        break
                    case  MEDIA_DEVICES.AUDIO_OUTPUT:
                        nameType = locale['speaker']
                        break
                }
                if (nameType) device.label = `${nameType} ${nameIndex++}`
            }
            return true
        })
    }
}