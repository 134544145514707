//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import {BOTS, CHATS, USERDATA} from "../../store/modulesNames"
import {GET_BOT_BY_ID, CAN_WRITE_TO_CHAT, GET_UID, GET_CHATS} from '../../store/gettersTypes'

import ContactsListItem from '../listItems/ContactsListItem.vue'
import SelectedItemsLine from "./SelectedItemsLine.vue"
import CustomSearch from "../custom/CustomSearch.vue"
import ModalBtn from "./ModalBtn.vue"
import SelectContactBody from "./SelectContactBody.vue"
import modal_mixin from "./modal-mixin"

import {CHAT_TYPES, CONTACTS_SEARCH_TYPES} from '../../constants'

export default {
    name: "SelectChat",
    components: {ModalBtn, CustomSearch, ContactsListItem, SelectedItemsLine, SelectContactBody},
    mixins: [modal_mixin],
    data: () => {
        return {
            compModalProps: {
                delay: 250,
                pivotY: 0.05,
                width: 400,
                height: 'auto',
                clickToClose: false
            },
            selected: [],
            search: '',
            contactSearch: false,
            unselectedContact: 0,
            searchType: CONTACTS_SEARCH_TYPES.SEND_MESSAGE,
        }
    },
    computed: {
        cancelText() { return this.props.cancelText || this.$t('cancel')},
        okText() { return this.props.okText || this.$t('loginPage.ok') },
        multiple() { return !!this.props.multiple },
        title() { return this.multiple ? this.$t('chats-selection') : this.$t('chat.chat-selection') },
        selectedItemsLine() {
            return this.selected.map(({photo, name, fio}) => ({iconPhoto: photo, iconText: name || fio, text: name || fio}))
        },
        chats() {
            let ownCid = this[GET_UID]
            let chats = this[GET_CHATS]
            let arr = []
            for(let i = 0; i < chats.length; i++) {
                let { cid, cidType, name, photo, lastMessage, settings } = chats[i] || {}
                if (this.isExcludedChat({ cid, cidType })) continue
                if (cidType === CHAT_TYPES.USER) {
                    if (cid === ownCid) {
                        continue
                    } else {
                        let bot = this.$store.getters[`${BOTS}/${GET_BOT_BY_ID}`](cid)
                        let user = bot || this.$store.getters['contacts/getMergedContactById'](cid)
                        photo = user.photo
                        name = user.title || user.fio
                    }
                } else if (cidType === CHAT_TYPES.GROUP) {
                    if (!this[CAN_WRITE_TO_CHAT]({ cid, cidType })) continue
                }
                let obj = {
                    cid,
                    cidType,
                    photo,
                    name,
                    time: date_helper.secondsLeftToUnix(lastMessage.time),
                    settings,
                }
                if (obj.cid !== 0) arr.push(obj)
            }
            let noteChat = { photo: this.noteImg, cid: ownCid, cidType: CHAT_TYPES.USER, name: this.$t('notes-chat-name') }
            arr = arr.sort(utils.chatAndChannelSortFn)
            if (!this.isExcludedChat(noteChat)) arr.unshift(noteChat)
            return arr
        },
        groups() {
            let groups = this.chats.filter(chat => chat.cidType === CHAT_TYPES.GROUP)
            for (let index = 0; index < groups.length; index++) {
                groups[index].fio = groups[index].name
            }
            return groups
        },
        contactsSelected() {
            return this.selected.reduce((prev, {cid, cidType}) => cidType === CHAT_TYPES.USER ? prev.push(cid) && prev : prev, [])
        },
        noteImg() {
            const isDarkTheme = this.$store.getters['clientdata/getTheme'] === 'dark'
            return isDarkTheme ? 'img/notes_dark.png' : 'img/note.png'
        },
        ...mapGetters(USERDATA, [GET_UID]),
        ...mapGetters(CHATS, [CAN_WRITE_TO_CHAT, GET_CHATS])
    },
    methods: {
        isExcludedChat() {
          return false
        },
        selectChat(chat) {
            if (!this.multiple) return this.onOk(chat)
            let add = true
            for(let i = 0; i < this.selected.length; i++) {
                if(this.selected[i].cid === chat.cid && this.selected[i].cidType === chat.cidType) {
                    add = false
                    break
                }
            }
            if(add) this.selected.push(chat)
        },
        unselectChat(index) {
            let selectedItem = this.selected[index]
            if (selectedItem.cidType === CHAT_TYPES.USER) this.unselectedContact = selectedItem.cid
            this.selected.splice(index, 1)
        },
        onContactSelected(contact) {
            this.selectChat({ cidType: CHAT_TYPES.USER, ...contact })
        },
        onContactUnselected(contact) {
            let index = this.selected.findIndex(({cid, cidType}) => cid === contact.cid && cidType === CHAT_TYPES.USER)
            if (index > -1) this.unselectChat(index)
        },
        onOk(selected) {
            this.props.okCb && this.props.okCb(selected)
        },
    },
    watch: {
        search(val) {
            this.contactSearch = Boolean(val)
        }
    }
}
