import {
    GET_ROSCHAT_SERVER,
    GET_ROSCHAT_LAST_SERVER,
    GET_ROSCHAT_REDIRECTED_SERVER,
    GET_UPDATE_SERVER_URL,
    GET_UID,
    GET_LOGIN,
    GET_PHOTO_URL,
    GET_PHOTO_URL_BY_CID,
    GET_LOCAL_PHOTO_URL,
    GET_CURRENT_PANE,
    GET_SEND_BY_ENTER,
    // GET_PRIVILEGED_RIGHTS,
    GET_BIRTHDAY_NOTIFY,
    GET_USER_PARAMS,
    GET_SEARCHED_CONTACTS,
    // GET_TYPE_ASISTANT,
    GET_RING_SOUND,
    GET_VOLUME_RING,
    GET_NOTIFICATION_SOUND,
    GET_VOLUME_NOTIFICATION,
    GET_VOLUME_RADIO,
    GET_VOLUME_AUDIO,
    GET_VOLUME_MICROPHONE,
    GET_WEBCAMERA_ENABLE,
    GET_MICROPHONE_ENABLE,
    GET_ACTIVE_SPEAKERS,
    GET_ACTIVE_MICROPHONE,
    GET_ACTIVE_WEBCAMERA,
    GET_MEDIA_DEVICES,
    GET_MEDIA_DEVICES_BY_KIND,
    GET_ACTIVE_MEDIA_DEVICES,
    GET_WS_PORT,
    GET_OAUTH2_SETTINGS,
    GET_MEDIA_DEVICE_LAST_OK,
    IS_MICROPHONE_ENABLED,
    GET_SERVER_API,
    GET_MERGED_CONTACT_BY_ID,
    GET_ACS_NAME,
    GET_INTEGRATION_SETTINGS,
} from '../gettersTypes'

import {
    ACT_SET_ROSCHAT_SERVER,
    ACT_SET_ROSCHAT_SERVER_CONFIG,
    ACT_SET_UID,
    ACT_SET_LOGIN,
    ACT_SET_CURRENT_PANE,
    ACT_SET_CONTACT_STATUS,
    ACT_SET_BIRTHDAY_NOTIFY,
    ACT_UPDATE_USER_PARAMS,
    ACT_SET_SEARCHED_CONTACTS,
    ACT_CLEAR_SEARCHED_CONTACTS,
    // ACT_GET_CALLS_INFO,
    // ACT_GET_TA,
    ACT_VOLUME_NOTIFICATION,
    ACT_VOLUME_RING,
    ACT_VOLUME_RADIO,
    ACT_VOLUME_AUDIO,
    ACT_VOLUME_MICROPHONE,
    // ACT_CHANGE_VOLUME_EVENT,
    // ACT_CHANGE_PERMISSION_DEVICE_EVENT,
    ACT_WEBCAMERA_ENABLE,
    ACT_MICROPHONE_ENABLE,
    ACT_ACTIVE_SPEAKERS,
    ACT_ACTIVE_MICROPHONE,
    ACT_ACTIVE_WEBCAMERA,
    ACT_MEDIA_DEVICES,
    ACT_GET_SERVER_CONFIG,
    ACT_CHECK_MEDIA_DEVICE,
    ACT_SET_MEDIA_DEVICE_LAST_OK,
    ACT_SET_SERVER_API,
    ACT_SAVE_INTEGRATION_SETTINGS,
    ACT_CLEAR_INTEGRATION_SETTINGS,
} from '../actionsTypes'

import {
    MUT_SET_UID,
    MUT_SET_LOGIN,
    //MUT_SET_PARAMS,
    MUT_SET_CURRENT_PANE,
    MUT_SEND_BY_ENTER,
    //MUT_PRIVILEGED_RIGHTS,
    MUT_SET_CONTACT_STATUS,
    MUT_SET_BIRTHDAY_NOTIFY,
    MUT_SET_USER_PARAMS,
    MUT_SET_SEARCHED_CONTACTS,
    MUT_CLEAR_SEARCHED_CONTACTS,
    //MUT_SAVE_ASSISTANTS,
    MUT_VOLUME_NOTIFICATION,
    MUT_VOLUME_AUDIO,
    MUT_VOLUME_RING,
    MUT_VOLUME_RADIO,
    MUT_VOLUME_MICROPHONE,
    MUT_WEBCAMERA_ENABLE,
    MUT_MICROPHONE_ENABLE,
    MUT_ACTIVE_SPEAKERS,
    MUT_ACTIVE_MICROPHONE,
    MUT_ACTIVE_WEBCAMERA,
    MUT_MEDIA_DEVICES,
    MUT_SET_MEDIA_DEVICE_LAST_OK,
    MUT_SAVE_INTEGRATION_SETTINGS,
    MUT_CLEAR_INTEGRATION_SETTINGS,
} from '../mutationsTypes'

import axios from 'axios'
import ipc from '../../../electron/ipc'
import storage from '../../storage'
import {MEDIA_DEVICES, ACS_TYPES, USER_PARAMS_PROPS} from '../../constants'
import { CONTACTS, LOGIN } from '../modulesNames'
window.axios = axios

const DEFAULT_UPDATE_SERVER_URL = 'https://firelink.me'

const DEFAULT_NOTIFICATION_SOUND = 'audio/message_received2.wav'
const DEFAULT_RING_SOUND = 'audio/ringtone.mp3'

import {i18n} from '../../../ext/i18n'

const state = {
    uid: null,
    selectedServer: storage.getServerHost(),
    lastServer: storage.getLastServer(),
    host: '',
    http_port: window.location.port,
    serverConfig: null,
    ws_port: null,
    params: null,
    current_pane: 'contacts',
    searchedContacts: [],
    searchedContactsNew: [],
    login: '',
    userInfo: {},
    userParams: {},
    appversion: '1.0.1 (2320)',
    totalEvents: 0,
    isFocused: true,
    active_button: {},
    sendMsgByEnter: false, // не используется
    sendByEnter1: false,
    contactStatus: 'online',
    birthdayNotify: 3,
    ringSound: DEFAULT_RING_SOUND,
    volumeRing: 100,
    notificationSound: DEFAULT_NOTIFICATION_SOUND,
    volumeNotification: 100,
    volumeRadio: 100,
    volumeAudio: 100,
    volumeMicrophone: 100,
    webcameraEnable: true,
    microphoneEnable: true,
    activeSpeakers: null,
    activeMicrophone: null,
    activeWebcamera: null,
    mediaDevices: null,
    mediaDevicesLastOk: {},
    integrations: {}
}
const getters = {
    [GET_ROSCHAT_SERVER]: state => state.selectedServer.replace('https://', '').replace('http://', '').replace('file://', '') || '',
    [GET_ROSCHAT_LAST_SERVER]: state => state.lastServer,
    [GET_ROSCHAT_REDIRECTED_SERVER]: state => state.serverConfig && state.serverConfig.redirectedServer || '',
    [GET_WS_PORT]: (state) => state.serverConfig && state.serverConfig.webSocketsPortVer4,
    [GET_OAUTH2_SETTINGS]: (state) => state.serverConfig && state.serverConfig.oauth2,

    [GET_UPDATE_SERVER_URL]: state => state.userParams && state.userParams.desktopUpdateServerUrl || DEFAULT_UPDATE_SERVER_URL,
    appversion: state => {
        let appversion = ipc.sendSync('get-client-info')
        return appversion ? version : state.appversion
    },
    infoHistoryLength: (state, getters, rootState, rootGetters) => rootGetters['info/getInfoHistoryLength'],
    openInfo: (state, getters, rootState, rootGetters) => rootGetters['info/getInfoOpen'],
    focusedP: state => state.isFocused,
    getHostPort: state => (state.http_port ? `${state.host}:${state.http_port}` : state.host).replace('https://', '').replace('http://', '').replace('file://', '') || '', //
    getHost (state) {
        return state.host
    },
    getHttpPort (state) {
        return state.http_port
    },
    userInfo: state => state.userInfo,
    [GET_USER_PARAMS]: state => state.userParams,
    [GET_UID] (state) {
        return state.uid
    },
    [GET_LOGIN] (state) {
        return state.login
    },
    getUid (state) {
        return state.uid
    },
    [GET_PHOTO_URL] (state) {
        return `${state.host}/photos/0/${state.uid}.jpeg?t=${new Date().getTime()}`
    },
    [GET_PHOTO_URL_BY_CID] (state) {
        return cid => `${state.host}/photos/0/${cid}.jpeg?t=${new Date().getTime()}`
    },
    [GET_LOCAL_PHOTO_URL] (state) {
        return cid => `${state.host}/photos/${state.uid}/${cid}.jpeg?t=${new Date().getTime()}`
    },
    [GET_CURRENT_PANE] (state) {
        return state.current_pane
    },
    [GET_SEND_BY_ENTER] (state) {
        return state.sendByEnter1
    },
    [GET_BIRTHDAY_NOTIFY] (state) {
        return state.birthdayNotify
    },
    [GET_SEARCHED_CONTACTS] (state, getters, rootState, rootGetters) {
        if (rootGetters[`${LOGIN}/${GET_SERVER_API}`] >= 12) {
            return state.searchedContactsNew.map(contact => {
                return { photo: getters[GET_PHOTO_URL_BY_CID](contact.cid), ...contact }
            })
        } else {
            return state.searchedContacts.map(cid => rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](cid))
        }
    },
    [GET_RING_SOUND](state) {
        return state.ringSound
    },
    [GET_VOLUME_RING](state) {
        return state.volumeRing / 100;
    },
    [GET_NOTIFICATION_SOUND](state) {
        return state.notificationSound
    },
    [GET_VOLUME_NOTIFICATION](state) {
        return state.volumeNotification / 100;
    },
    [GET_VOLUME_RADIO](state) {
        return state.volumeRadio / 100;
    },
    [GET_VOLUME_AUDIO](state) {
        return state.volumeAudio / 100;
    },
    [GET_VOLUME_MICROPHONE](state) {
        return state.volumeMicrophone;
    },
    [GET_WEBCAMERA_ENABLE](state) {
        return state.webcameraEnable;
    },
    [GET_MICROPHONE_ENABLE](state) {
        return state.microphoneEnable;
    },
    [GET_ACTIVE_SPEAKERS](state) {
        return state.activeSpeakers;
    },
    [GET_ACTIVE_MICROPHONE](state) {
        return state.activeMicrophone;
    },
    [IS_MICROPHONE_ENABLED](state, getters) {
        return Boolean(getters[GET_ACTIVE_MICROPHONE] && getters[GET_ACTIVE_MICROPHONE].deviceId)
    },
    [GET_ACTIVE_WEBCAMERA](state) {
        return state.activeWebcamera;
    },
    [GET_MEDIA_DEVICES](state) {
        return state.mediaDevices;
    },
    [GET_MEDIA_DEVICES_BY_KIND]: (state) => (kind) => {
        return state.mediaDevices.filter(device => device.kind === kind)
    },
    [GET_ACTIVE_MEDIA_DEVICES]: (state, getters) => {
        return {
            [MEDIA_DEVICES.AUDIO_INPUT]: (getters[GET_ACTIVE_MICROPHONE] || {}).deviceId,
            [MEDIA_DEVICES.AUDIO_OUTPUT]: (getters[GET_ACTIVE_SPEAKERS] || {}).deviceId,
            [MEDIA_DEVICES.VIDEO_INPUT]: (getters[GET_ACTIVE_WEBCAMERA] || {}).deviceId,
        }
    },
    [GET_MEDIA_DEVICE_LAST_OK]: (state) => (deviceType) => {
        return state.mediaDevicesLastOk[deviceType] || 0
    },
    [GET_ACS_NAME]: (state, getters) => {
        const info = getters[`userInfo`]
        const acsType = info && info.extParams && info.extParams.acsType

        let acs_name

        switch (acsType) {
            case ACS_TYPES.OM:
                acs_name = i18n.t('settings.om')
                break
            case ACS_TYPES.ORION:
                acs_name = i18n.t('settings.orion')
                break
            case ACS_TYPES.PERCO:
                acs_name = i18n.t('settings.perco')
                break
            default:
                acs_name = acsType ?? ''
        }

        return acs_name
    },
    [GET_INTEGRATION_SETTINGS]: (state) => (integrationName) => {
        const types = state.userParams[USER_PARAMS_PROPS.SCHEDULE_TYPES]
        return types && types.length && types.includes(integrationName) && state.integrations[integrationName] || 0
    },
}
const actions = {
    // ------------------ Abstract actions --------------------------
    async [ACT_UPDATE_USER_PARAMS]({ commit, rootGetters }) {},
    [ACT_SET_CONTACT_STATUS] (obj, value) {},
    [ACT_WEBCAMERA_ENABLE]({commit}, data) {},
    [ACT_CHECK_MEDIA_DEVICE](obj) {},
    // --------------------------------------------------------------
    [ACT_GET_SERVER_CONFIG]: ({dispatch}, {httpServer, repeat, attempt = 0}) => new Promise(async (resolve, reject) => {
        if (repeat) attempt = 5
        try {
            const response = await axios.get(httpServer + '/ajax/config.json')
            const respOrigin = (new URL(response.request.responseURL)).origin
            const config = (response || {}).data
            config.redirectedServer = respOrigin
            resolve(config)
        } catch (e) {
            if (--attempt > 0) {
                setTimeout(async () => {
                    try {
                        resolve(await dispatch(ACT_GET_SERVER_CONFIG, {httpServer, attempt}))
                    } catch (e) {
                        reject(e)
                    }
                }, 5000)
            } else {
                reject(e)
            }
        }
    }),
    [ACT_SET_ROSCHAT_SERVER]({state}, server) {
        storage.setServerHost(server)
        storage.setLastServer(server)
        state.selectedServer = server
    },
    async [ACT_SET_ROSCHAT_SERVER_CONFIG]({state, dispatch}, config) {
        if (config.APILevel) await dispatch(`${LOGIN}/${ACT_SET_SERVER_API}`, config.APILevel, { root: true })
        state.serverConfig = config
    },
    totalEvents (obj, count) {
        ipc.send('set-total-events', count)
    },
    isFocused (obj, isFocused) {
        if (isFocused) {
            ipc.send('focus')
        } else {
            ipc.send('blur')
        }
        obj.commit('isFocused', isFocused)
    },
    userInfo (obj, userInfo) {
        obj.commit('userInfo', userInfo)
    },
    setHost (obj, {host}) {
        obj.commit('setHost', host)
    },
    [ACT_SET_UID] (obj, uid) {
        obj.commit(MUT_SET_UID, uid)
    },
    [ACT_SET_LOGIN] (obj, login) {
        obj.commit(MUT_SET_LOGIN, login)
    },
    setHttpPort (obj, port) {
        obj.commit('setHttpPort', port)
    },
    setWsPort (obj, port) {
        obj.commit('setWsPort', port)
    },
    [ACT_SET_CURRENT_PANE] (obj, pane) {
        obj.commit(MUT_SET_CURRENT_PANE, pane)
    },
    [ACT_SET_BIRTHDAY_NOTIFY] ({commit, dispatch}, value) {
        commit(MUT_SET_BIRTHDAY_NOTIFY, value)
        if (value !== 10) commit('contacts/setBirthdaysUnwatched', value, {root: true})
    },
    [ACT_SET_SEARCHED_CONTACTS] ({state, commit, rootGetters}, contact) {
        const cid = contact.cid
        if (rootGetters[`${LOGIN}/${GET_SERVER_API}`] >= 12) {
            let payload = { cid, fio: contact.fio }
            commit(MUT_SET_SEARCHED_CONTACTS, payload)
        } else {
            commit(MUT_SET_SEARCHED_CONTACTS, { cid })
        }
    },
    [ACT_CLEAR_SEARCHED_CONTACTS] ({commit}) {
        commit(MUT_CLEAR_SEARCHED_CONTACTS)
    },
    [ACT_VOLUME_NOTIFICATION]({commit}, data) {
        commit(MUT_VOLUME_NOTIFICATION, data);
    },
    [ACT_VOLUME_RING]({commit}, data) {
        commit(MUT_VOLUME_RING, data);
    },
    [ACT_VOLUME_RADIO]({commit}, data) {
        commit(MUT_VOLUME_RADIO, data);
    },    
    [ACT_VOLUME_AUDIO]({commit, dispatch}, data) {
        commit(MUT_VOLUME_AUDIO, data);
    },
    [ACT_VOLUME_MICROPHONE]({commit, dispatch}, data) {
        commit(MUT_VOLUME_MICROPHONE, data);
    },
    [ACT_MICROPHONE_ENABLE]({commit}, data) {
        commit(MUT_MICROPHONE_ENABLE, data);
    },
    [ACT_ACTIVE_SPEAKERS]({commit}, data) {
        data = JSON.parse(JSON.stringify(data))
        commit(MUT_ACTIVE_SPEAKERS, data);
    },
    [ACT_ACTIVE_MICROPHONE]({commit}, data) {
        commit(MUT_ACTIVE_MICROPHONE, data);
    },
    [ACT_ACTIVE_WEBCAMERA]({commit}, data) {
        commit(MUT_ACTIVE_WEBCAMERA, data);
    },
    [ACT_MEDIA_DEVICES]({commit}, data) {
        commit(MUT_MEDIA_DEVICES, data);
    },
    [ACT_SET_MEDIA_DEVICE_LAST_OK]({commit}, data) {
        commit(MUT_SET_MEDIA_DEVICE_LAST_OK, data);
    },
    [ACT_SAVE_INTEGRATION_SETTINGS]({commit}, data) {
        commit(MUT_SAVE_INTEGRATION_SETTINGS, data);
    },
    [ACT_CLEAR_INTEGRATION_SETTINGS]({commit}, data) {
        commit(MUT_CLEAR_INTEGRATION_SETTINGS, data);
    },    
}
const mutations = {
    isFocused (state, isFocused) {
        console.log('isFocused: ', isFocused)
        state.isFocused = isFocused
    },
    userInfo (state, userInfo) {
        state.userInfo = userInfo
    },
    [MUT_SET_USER_PARAMS] (state, userParams) {
        state.userParams = userParams
    },
    [MUT_SET_UID] (state, uid) {
        state.uid = uid
    },
    [MUT_SET_LOGIN] (state, login) {
        state.login = login
    },
    [MUT_SET_CONTACT_STATUS] (state, value) {
        state.contactStatus = value
    },
    [MUT_SET_CURRENT_PANE] (state, pane) {
        state.current_pane = pane
    },
    [MUT_SEND_BY_ENTER] (state, value) {
        state.sendByEnter1 = value
    },
    [MUT_SET_BIRTHDAY_NOTIFY] (state, value) {
        state.birthdayNotify = value
    },
    [MUT_SET_SEARCHED_CONTACTS] (state, payload) {
        if (payload.fio) {
            let foundIndex = state.searchedContactsNew.findIndex((contact) => contact.cid === payload.cid)
            if (foundIndex >= 0) {
                state.searchedContactsNew.splice(foundIndex, 1, payload)
            } else {
                state.searchedContactsNew.unshift(payload)
                state.searchedContactsNew.splice(20, 1)
            }
        } else {
            let cid = payload.cid
            if (!state.searchedContacts.includes(cid)) {
                state.searchedContacts.unshift(cid)
                state.searchedContacts.splice(20, 1)
            }
        }
    },
    [MUT_CLEAR_SEARCHED_CONTACTS] (state) {
        state.searchedContacts.splice(0, state.searchedContacts.length)
        state.searchedContactsNew.splice(0, state.searchedContactsNew.length)
    },
    setHost (state, value) {
        state.host = value
    },
    setHttpPort (state, value) {
        state.http_port = value
    },
    setWsPort (state, value) {
        state.ws_port = value
    },
    [MUT_VOLUME_NOTIFICATION](state, data) {
        state.volumeNotification = data
    },
    [MUT_VOLUME_RING](state, data) {
        state.volumeRing = data
    },
    [MUT_VOLUME_RADIO](state, data) {
        state.volumeRadio = data
    },    
    [MUT_VOLUME_AUDIO](state, data) {
        state.volumeAudio = data
    },
    [MUT_VOLUME_MICROPHONE](state, data) {
        state.volumeMicrophone = data
    },
    [MUT_WEBCAMERA_ENABLE](state, data) {
        state.webcameraEnable = data;
    },
    [MUT_MICROPHONE_ENABLE](state, data) {
        state.microphoneEnable = data;
    },
    [MUT_ACTIVE_SPEAKERS](state, data) {
        state.activeSpeakers = data
    },
    [MUT_ACTIVE_MICROPHONE](state, data) {
        state.activeMicrophone = data
    },
    [MUT_ACTIVE_WEBCAMERA](state, data) {
        state.activeWebcamera = data
    },
    [MUT_MEDIA_DEVICES](state, data) {
        state.mediaDevices = data
    },
    [MUT_SET_MEDIA_DEVICE_LAST_OK](state, {deviceType, time = 0}) {
        state.mediaDevicesLastOk[deviceType] = time
    },
    [MUT_SAVE_INTEGRATION_SETTINGS](state, data) {
        let { integrationName, settings } = data
        state.integrations =  {
            ...state.integrations,
            [integrationName]: settings
        }
    },
    [MUT_CLEAR_INTEGRATION_SETTINGS](state, data) {
        let { integrationName } = data
        state.integrations[integrationName] = {}
        state.integrations[integrationName].settings = {}
    }    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
