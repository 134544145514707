var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: { name: _vm.modalName, "modal-props": _vm.sumModalProps }
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "call-details" }, [
          _c(
            "div",
            { staticClass: "call-details-header" },
            [
              _c("icon-image", {
                attrs: { src: _vm.user.photo, text: _vm.user.fio }
              }),
              _c("span", { staticClass: "fio" }, [_vm._v(_vm._s(_vm.fio))]),
              _c("span", { staticClass: "type" }, [_vm._v(_vm._s(_vm.type))])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "call-details-buttons" },
            [
              _c(
                "call-details-button",
                {
                  attrs: {
                    title: _vm.$t("callback"),
                    disabled: !_vm.isCallBtnEnabled || !_vm.isCallAllowed
                  },
                  on: {
                    click: function($event) {
                      return _vm.doClickCall(_vm.user.cid, _vm.user.class)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-phone",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              ),
              _c(
                "call-details-button",
                {
                  attrs: {
                    title: _vm.$t("mainPage.profile"),
                    disabled:
                      !_vm.permission(_vm.user.cid) || !_vm.isViewProfileAllowed
                  },
                  on: {
                    click: function($event) {
                      return _vm.doClickPlus(_vm.user.cid)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-user",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              ),
              _c(
                "call-details-button",
                {
                  attrs: {
                    title: _vm.$t("msg"),
                    disabled:
                      !_vm.permission(_vm.user.cid) || !_vm.isChatAllowed
                  },
                  on: {
                    click: function($event) {
                      return _vm.doClickChat(_vm.user.cid)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-comment",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "call-details-body" },
            [
              _c("div", { staticClass: "call-details-body-title" }, [
                _vm._v(_vm._s(_vm.$t("call-log")))
              ]),
              _vm._l(_vm.calls, function(call, index) {
                return _c("div", { staticClass: "call" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            index === 0 ||
                            call.dateDetails.data !==
                              _vm.calls[index - 1].dateDetails.data,
                          expression:
                            "index === 0 || (call.dateDetails.data !== calls[index-1].dateDetails.data)"
                        }
                      ],
                      staticClass: "call-details-datagroup"
                    },
                    [_vm._v(_vm._s(call.dateDetails.data))]
                  ),
                  _c("div", { staticClass: "call-item" }, [
                    _c("div", { staticClass: "date" }, [
                      _vm._v(_vm._s(call.dateDetails.time))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "inout",
                        class: call.inout === _vm.$t("missed") ? "red-font" : ""
                      },
                      [_vm._v(_vm._s(call.inout))]
                    ),
                    call.timeFormat
                      ? _c("div", { staticClass: "time" }, [
                          _vm._v(_vm._s(call.timeFormat))
                        ])
                      : _c(
                          "div",
                          {
                            staticClass: "time",
                            staticStyle: { "margin-right": "10px" }
                          },
                          [_vm._v("-")]
                        )
                  ])
                ])
              })
            ],
            2
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }