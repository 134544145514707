var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.component
    ? _c("div", { staticClass: "main-header" }, [
        _c(
          "div",
          { staticClass: "section-header", attrs: { id: "main-header" } },
          [
            _vm.canBack
              ? _c(
                  "div",
                  {
                    staticClass: "header-back circle-animation",
                    on: { click: _vm.goBack }
                  },
                  [_c("i", { staticClass: "far fa-arrow-left" })]
                )
              : _vm._e(),
            _c(
              _vm.component,
              _vm._b(
                { tag: "component" },
                "component",
                _vm.componentProps,
                false
              )
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }