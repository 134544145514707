//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InfoMainBlock from "./InfoMainBlock.vue"
import IconImage from "../IconImage.vue"
import ListItem from "../custom/ListItem.vue"
import { mapGetters, mapActions } from "vuex"
import { USERDATA, CHAT, INFO } from "../../store/modulesNames"
import { GET_UID, GET_CHAT_SEARCH_STRING, GET_IS_COMPACT_MODE } from "../../store/gettersTypes"
import { ACT_INFO_CLEAR } from "../../store/actionsTypes"
import moment from "moment"
import ScrollToMessageMixin from "../chat/ScrollToMessageMixin"
import ContentLoader from "../main/body/MainContentLoader.vue"

const defaultMessagesCount = 150
const excerptLength = 30

const checkIsScrollVisible = () => {
    let isVisible = false
    return new Promise((resolve) => {
        setTimeout(() => {
            const elem = document.getElementById("chat-search-area")
            if (elem) {
                isVisible = elem.scrollHeight > elem.clientHeight
                resolve(isVisible)
            } else resolve(false)
        }, 100)
    })
}

export default {
    name: "SearchInChat",
    mixins: [ScrollToMessageMixin],
    components: {
        IconImage,
        ListItem,
        InfoMainBlock,
        ContentLoader
    },
    props: ["params", "canBack"],
    mounted() {
        this.$nextTick(() => {
            const input = document
                .getElementsByClassName("search-input-wrapper show")[0]
                .getElementsByTagName("input")[0]
            input && input.focus()
        })
    },
    data() {
        return {
            chats: [],
            foundChats: [],
            searchString: "",
            chunkStartId: 0,
            firstSearch: true,
            searchCountMult: 1,
            noRecords: this.$t("media-search.messages-not-found"),
            isLookingFor: false,
            itemSize: 71,
            isSearchMore: true,
            isScrollVisible: false,
            isNewSearch: false,
            mediaTypes: { 
                image: this.$t("media-search.image"),
                key: this.$t("media-search.video"),
                audio: this.$t("media-search.audio"),
                file: this.$t("file"),
                link: this.$t("media-search.link") 
            },
        }
    },
    computed: {
        cid() {
            return this[GET_UID]
        },
        bufferSize() {
            return this.itemSize * 10
        },
        scrollerStyle() {
            return this.foundChats.length ? "height: 100%;" : ""
        },
        isLoader() {
            return (
                this.firstSearch && this.searchString && !this.foundChats.length && this.isLookingFor
            )
        },
        getSearchString() {
            return this[GET_CHAT_SEARCH_STRING]
        },
        isNoResults() {
            return (
                !this.firstSearch &&
                !this.isLookingFor &&
                !this.foundChats.length &&
                this.searchString.length >= 2
            )
        },
        showSearchMore() {
            return this.isScrollVisible && this.isSearchMore
        },
        ...mapGetters(USERDATA, [GET_UID]), 
        ...mapGetters(CHAT, [GET_CHAT_SEARCH_STRING]),
        ...mapGetters(INFO, [GET_IS_COMPACT_MODE]),
    },
    methods: {
        getPhoto(cid) {
            return this.$store.getters["contacts/getMergedContactById"](cid).photo || ""
        },
        getFio(cid) {
            return this.$store.getters["contacts/getMergedContactById"](cid).fio || ""
        },
        getMessageItem(message) {
            let out =
                message.type === "out" || app.getUid() === message.senderId
            return {
                id: message.id,
                cid: message.cid,
                cidType: message.cidType,
                senderId: message.senderId,
                type: message.dataType,
                sub_type: message.data && message.data.type,
                date: this.formatTime(message.time),
                time: date_helper.secondsLeftToTimeFormat(message.time),
                msg: this.getItemText(message),
                ownMsg: out
            }
        },
        getItemText(message) {
            let text
            switch (message.dataType) {
                case declarations.msgDataTypes.MSG_DATA_TYPE_TEXT:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_DATA:
                    text = getDataText(message.data, this.searchString, this.mediaTypes)
                    //console.log("🚀 ~ file: SearchChatMessages.vue ~ line 162 ~ getItemText ~ message", message)
                    //console.log("🚀 ~ file: SearchChatMessages.vue ~ line 162 ~ getItemText ~ text", text)
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_UNSTORED:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM:
                    text = ""
                    break
                default:
                    text = ""
            }
            return text
        },
        formatTime(timeCall) {
            let result
            let timeCallFormat = moment().subtract(timeCall, "seconds")
            let today = moment().startOf("day")
            let yesterday = moment()
                .subtract(1, "days")
                .startOf("day")
            let twoDays = moment()
                .subtract(2, "days")
                .startOf("day")
            if (timeCallFormat.isAfter(today)) {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("HH:mm")
            } else if (
                timeCallFormat.isBefore(today) &&
                timeCallFormat.isAfter(yesterday)
            ) {
                result = this.$t("channel-comp.yesterday")
            } else if (
                timeCallFormat.isBefore(yesterday) &&
                timeCallFormat.isAfter(twoDays)
            ) {
                result = this.$t("channel-comp.before-yest")
            } else {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("DD MMM YYYY")
            }
            return result
        },
        async addMessages(isAfterNotFound = false) {
            this.isLookingFor = true
            let oldestMessage = this.chats.pop()
            this.chunkStartId = oldestMessage ? oldestMessage.id : 0
            if (isAfterNotFound && !this.isScrollVisible) {
                this.searchCountMult++
                if (this.searchCountMult > 4) {
                    this.searchCountMult = 0
                    this.isSearchMore = false
                }
            }
            let params = {
                count: this.searchCountMult * this.searchCountMult * defaultMessagesCount,
                cid: this.params.cid,
                cidType: this.params.cidType,
                startId: this.chunkStartId
            }
            const chatsChunk = await this.$store.dispatch(
                "chat/getMessages",
                params
            )

            let concatArr = []
            if (chatsChunk.length) { 
                concatArr = this.chats.concat(chatsChunk)
                oldestMessage = concatArr[concatArr.length - 1]
            }
            if (oldestMessage && this.chunkStartId === oldestMessage.id) {
                this.isSearchMore = false
                this.isLookingFor = false
                return
            } else {
                if (!chatsChunk.length) {
                    if (!this.searchCountMult) await this.addMessages(true)
                }
                this.chunkStartId = oldestMessage ? oldestMessage.id : 0
                if (concatArr.length) this.chats = concatArr  
                await this.searchMessages()
            }
            this.isLookingFor = false
        },
        async searchMessages(val = this.searchString) {
            this.isLookingFor = true
            this.foundChats.splice(0, this.foundChats.length)
            if (!this.chats.length || this.isNewSearch) {
                let params = {
                    count: defaultMessagesCount,
                    cid: this.params.cid,
                    cidType: this.params.cidType
                }
                this.chats = await this.$store.dispatch(
                    "chat/getMessages",
                    params
                )
                this.isNewSearch = false
            }
            this.chats.forEach(chMsg => {
                this.firstSearch = false
                let msgItem = this.getMessageItem(chMsg)
                let index = -1, msg, excerptDelta = 7
                if (msgItem && msgItem.msg) {
                    msg = msgItem.msg
                    if (typeof msg === "string")
                        index = msg.toLowerCase().indexOf(val.toLowerCase())
                }
                if (val.length >= 2 && index > -1) {
                    const isStart = (index - excerptDelta <= 0) 
                    const newIndex = isStart ? 0 : index - excerptDelta
                    let excerptStart = isStart ? msg.slice(0, index) : "..." + msg.slice(newIndex - 1, index)
                    const foundInFile = Object.values(this.mediaTypes).some(v => msg.slice(0, index - 1).indexOf(v) > -1)
                    if (foundInFile) excerptStart = msg.slice(0, index)
                    let excerpt = excerptStart + msg.slice(index, index + excerptLength) //excerptStart + excerptEnd
                    let highlightString = msg.slice(index, index + val.length)
                    msgItem.excerpt = excerpt
                    msgItem.highlights = highlightString
                    this.foundChats.push(msgItem)
                }
            })
            if (!this.foundChats.length && this.isSearchMore) {
                await this.addMessages(true)
                return
            }
            this.isScrollVisible = await checkIsScrollVisible()
            if (this.foundChats.length && this.isSearchMore && !this.isScrollVisible) {
                await this.addMessages()
            } 
            console.log("🚀 ~ file: SearchChatMessages.vue:285 ~ searchMessages ~ this.foundChats:", this.foundChats)
            this.isLookingFor = false
        },
        onContextMenu({ e, message }) {
            let handlers = []
            if (message.id) {
                handlers.push({
                    item_name: this.$t('media-search.goto-chat'),
                    handler: this.scrollToMessage,
                    data: message.id
                })
            }
            handlers.push({
                item_name: this.$t('media-search.forward'),
                handler: this.forwardMessage,
                data: message
            })
            if (message.id) {
                handlers.push({
                    item_name: this.$t('media-search.delete'),
                    handler: this.removeMsg,
                    data: message
                })
            }
            this.cmOpen(e, handlers, "right-bottom")
        },
        scrollToMessage(id) {
            const isCompactMode = this[GET_IS_COMPACT_MODE]
            if (isCompactMode) {
                this.$nextTick(() => { this[ACT_INFO_CLEAR]() })
                if (id) setTimeout(() => this.scrollingToElement(id), 300) 
            }
            else if (id) this.scrollingToElement(id)
        },
        ...mapActions(INFO, [ACT_INFO_CLEAR]),
    },
    watch: {
        getSearchString(newVal, oldVal) {
            if (!newVal) newVal = ''
            if (!oldVal) oldVal = ''
            const lcNewVal = newVal.toLowerCase(), lcOldVal = oldVal.toLowerCase()
            if (!newVal || newVal.length <= 2) this.isNewSearch = false
            else if (newVal.length >= oldVal.length && lcNewVal.indexOf(lcOldVal) === 0)
                this.isNewSearch = false
            else this.isNewSearch = true
            this.searchString = newVal
        },
        searchString(val) {
            if (val && val.length >= 2) {
                if (!this.isLookingFor) this.searchMessages(val)
            } 
        }
    }
}

function getDataText(data, searchString, mediaTypes) {
    let text = ''
    let fileName = data && data.hasOwnProperty("name") ? data.name + '.' + data.extension + ' ' : data.extension || ''
    const foundFile = fileName.toLowerCase().indexOf(searchString.toLowerCase()) > -1
    switch (data.type) {
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
            const fields = Array.isArray(data.fields) && data.fields
            const phones =
                fields &&
                fields.filter(f => f.type === "phone" || f.type === "workphone")
            const phone = phones && phones.length && phones[0].value
            const mails = fields && fields.filter(f => f.type === "mail")
            const mail = mails && mails.length && mails[0].value
            const fio = (fields && fields.fio) || phone || mail
            text = fio
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
            text = data.text
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
            text = data.text
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
            text = data.longitude + " " + data.latitude
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
            if (foundFile) text = `${mediaTypes[data.type]}: ` + fileName + '\n'
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
            text = data.topic
            break
        // case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
        //     text = data
        //     break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
            text = data.text
            break
        default:
            text = ""
    }
    // if (text && text.indexOf('www') > -1) {
    //     console.log("🚀 ~ file: SearchChatMessages.vue ~ line 384 ~ getDataText ~ data", data)
    //     console.log("🚀 ~ file: SearchChatMessages.vue ~ line 384 ~ getDataText ~ text", text)
    // }
    return text
}
