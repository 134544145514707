//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {GET_SELECTED_CHAT, GET_INFO_OPEN, GET_CHAT_TYPINGS, GET_LOCALE} from "../../../store/gettersTypes";
import {
    ACT_SET_CHAT_MUTED,
    ACT_CHATS_ADD_MEMBERS_DIALOG,
    ACT_INFO_PUSH,
    ACT_INFO_TOGGLE,
    ACT_INFO_CLEAR,
    ACT_LEAVE_FROM_CHAT,
} from '../../../store/actionsTypes'
import {CHATS, INFO, CLIENTDATA} from "../../../store/modulesNames";

import IconImage from '../../IconImage.vue';
import TypingStatus from "../../listItems/TypingStatus.vue"

import { i18n } from '../../../../ext/i18n'

export default {
    name: "MainHeaderGroup",
    components: {IconImage, TypingStatus},
    data() {
        return {
            showTitle: false,
        }
    },
    computed: {
        ...mapGetters(CHATS, [GET_SELECTED_CHAT, GET_CHAT_TYPINGS]),
        ...mapGetters(INFO, [GET_INFO_OPEN]),
        ...mapGetters(CLIENTDATA, [GET_LOCALE]),
        cid() { return this[GET_SELECTED_CHAT].cid },
        cidType: () => declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP,
        getTitle() {
            return this.showTitle ? this.chat.name : ''
        },
        typing() {
            let { cid, cidType } = this
            return Boolean(this[GET_CHAT_TYPINGS]({cid, cidType}).length)
        },
        chat() {
            let chat = this.$store.getters['chats/getChat']({
                cid: this.cid,
                cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP
            });
            if (!chat) return;
            return chat;
        },
        subText() {
            let count = (this.chat && this.chat.contacts || []).length
            let text = ''
            if (this[GET_LOCALE] === 'ru') {
                text = utils.declOfNum(count, [ this.$t('one-member'), this.$t('several-members'), this.$t('many-members')])
            } else {
                text = (count === 1) ? this.$t('one-member') : this.$t('many-members')
            }
            return `${count} ${text}`
        },
        group_chat_data() {
            return this.$store.getters['chats/getChat']({
                cid: this.cid,
                cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP
            });
        },
        in_chat() {
            if (!this.group_chat_data) return null;
            return !!this.group_chat_data.contacts.find(function (member) {
                return member.cid === app.getUid();
            });
        },
        privilege() {
            if (!this.group_chat_data) return null;
            return (this.group_chat_data.contacts.find(function (member) {
                return member.cid === app.getUid();
            }) || {}).privilege;
        }
    },
    methods: {
        headerMouseOver(e) {
            if (e.target.className === 'group-name') {
                const childEl = e.target
                const childW = childEl.offsetWidth
                const parentW = e.relatedTarget.offsetWidth
                childW > parentW ? this.showTitle = true : this.showTitle = false
            }
        },
        doMute() {
            this.modalOpen({
                name: 'chat-mute',
                props: {
                    cid: this.cid,
                    cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP
                }
            })
        },
        doUnMute() {
            this[ACT_SET_CHAT_MUTED]({ cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP, cid: this.cid})
        },
        addMembers() {
            this[ACT_CHATS_ADD_MEMBERS_DIALOG]({
                cid: this.cid,
                privilege: 'user',
            })
        },
        removeChat() {
            this.modalOpen({
                name: 'remove-chat',
                props: {
                    cid: this.cid,
                    cidType: this.cidType
                }
            })
        },
        doMenu(event, position) {
            let mainPage = i18n.messages[i18n.locale].mainPage
            let handlers = [],
                open = this.cmOpen;
            if (!this[GET_INFO_OPEN]) handlers.push({item_name: mainPage['chat-info'], handler: this.doToggleOpenInfo});
            //if (this.in_chat) {
            if (this.$store.getters['chats/isMuted']({
                cid: this.cid,
                cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP
            })) {
                handlers.push({item_name: mainPage.unmute, handler: this.doUnMute});
            } else {
                handlers.push({item_name: mainPage.mute, handler: this.doMute});
            }
            //}
            if (this.privilege === 'admin') handlers.push({item_name: mainPage['add-member'], handler: this.addMembers});
            if (this.in_chat) handlers.push({ item_name: mainPage['leave-chat'], handler: () => this[ACT_LEAVE_FROM_CHAT]({cid: this.cid}) })
            handlers.push({item_name: mainPage['delete-chat'], handler: this.removeChat});
            open(event, handlers, position);
        },
        doOpenInfo() {
            this[ACT_INFO_PUSH]({type: 'chat-info', params: {cid: this.cid}})
        },
        doToggleOpenInfo() {
            const openedInfo = this[GET_INFO_OPEN]
            if (openedInfo && openedInfo.type === 'search-in-chat') this[ACT_INFO_CLEAR]()
            this[ACT_INFO_TOGGLE]({type: 'chat-info', params: {cid: this.cid}})
        },
        openSearchChat() {
            this[ACT_INFO_PUSH]({type: 'search-in-chat', params: {cid: this.cid, cidType: this.cidType}})
        },
        ...mapActions(CHATS, [ACT_SET_CHAT_MUTED, ACT_CHATS_ADD_MEMBERS_DIALOG, ACT_LEAVE_FROM_CHAT]),
        ...mapActions(INFO, [ACT_INFO_PUSH, ACT_INFO_TOGGLE, ACT_INFO_CLEAR]),
    }
}
