import { render, staticRenderFns } from "./ChatMessage.vue?vue&type=template&id=31a578e6&scoped=true&lang=pug&"
import script from "./ChatMessage.vue?vue&type=script&lang=js&"
export * from "./ChatMessage.vue?vue&type=script&lang=js&"
import style0 from "./ChatMessage.vue?vue&type=style&index=0&id=31a578e6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31a578e6",
  null
  
)

export default component.exports